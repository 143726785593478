import { CuCustomer } from '@devschap/order-point-types';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PhoneNumberInput, { IPhoneNumber } from '../../../components/PhoneNumberInput';
import SectionHeader from '../../../components/SectionHeader';
import Input from '../../../components/form/Input';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Badge from '../../../components/ui/Badge';
import Button, { IButtonProps } from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import useAddressForm from '../../../hooks/useAddressForm';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import useUserStore from '../../../hooks/useUserStore';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import { TColors } from '../../../types/colors.type';
import { TIcons } from '../../../types/icons.type';
import { formatPhoneNumber, parsePhoneNumber, validPhoneNumber } from '../../../utils/ui.util';
import CustomerDeliveryAddresses from '../components/CustomerDeliveryAddresses';

type TTab = {
	text: 'Profil' | 'Adresses de livraison';
	icon: TIcons;
	color?: TColors;
};
type TTabs = {
	[key in 'PROFILE' | 'DeliveryAddress']: TTab;
};
const TAB: TTabs = {
	PROFILE: {
		text: 'Profil',
		icon: 'HeroUser',
	},
	DeliveryAddress: {
		text: 'Adresses de livraison',
		icon: 'HeroBuildingOffice2',
	},
};

const defaultProps: IButtonProps = {
	color: 'zinc',
};
const activeProps: IButtonProps = {
	...defaultProps,
	isActive: true,
	color: 'blue',
	colorIntensity: '500',
};

export type TValues = {
	firstName: string;
	lastName: string;
};

const CustomerProfilePage = () => {
	const { state: userState, setCustomerData } = useUserStore();
	const { axiosFetch } = useAxiosFunction();
	const dispatch = useDispatch();

	const customToast = useCustomToast();
	const { AddressForm, validateAddressForm, country, addressFormik } = useAddressForm({
		address: userState.address,
	});

	const [activeTab, setActiveTab] = useState<TTab>(TAB.PROFILE);

	const [telephone1, setTelephone1] = useState<IPhoneNumber>(
		parsePhoneNumber(userState.telephone1),
	);
	const [telephone2, setTelephone2] = useState<IPhoneNumber>(
		parsePhoneNumber(userState.telephone2),
	);

	// useEffect(() => {
	// 	setActiveTab(TAB.PROFILE);
	// }, []);

	const formik = useFormik({
		initialValues: {
			firstName: userState.customer?.firstName ?? '',
			lastName: userState.customer?.lastName ?? '',
		},
		onSubmit: async (values: TValues) => {
			try {
				if (!validateAddressForm()) return;

				if (!validPhoneNumber(telephone1)) {
					customToast.error('Numero de telephone incorrect');
					return;
				}
				const response = await axiosFetch<{ customer: CuCustomer }>({
					method: 'PATCH',
					url: '/api/customer',
					requestBody: {
						firstName: values.firstName,
						lastName: values.lastName,
						addressId: addressFormik.values.id,
						telephone1: formatPhoneNumber(telephone1),
						telephone2: formatPhoneNumber(telephone2),
						address: {
							countryIso: country,
							city: addressFormik.values.city,
							district: addressFormik.values.district,
							postalCode: addressFormik.values.postalCode,
						},
					},
				});

				if (response === undefined) return;
				const { customer } = response;

				dispatch(
					setCustomerData({
						address: customer.address,
						customer,
						telephone1: customer.telephone1,
						telephone2: customer.telephone1,
					}),
				);
			} catch (e: any) {
				customToast.error(
					'Erreur lors de la mise a jour sauvegarde des données du client.',
				);
			}
		},
	});

	const nav = Object.values(TAB).map((tab) => (
		<div key={tab.text} className='flex-shrink-0'>
			<Button
				icon={tab.icon}
				color={
					activeTab.text === tab.text
						? tab.color ?? activeProps.color
						: tab.color ?? defaultProps.color
				}
				isActive={activeTab.text === tab.text}
				onClick={() => {
					setActiveTab(tab);
				}}>
				{tab.text}
			</Button>
		</div>
	));

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Profil' currentPage='Votre Profil' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			{userState.customer ? (
				<PageWrapper name={userState.customer.firstName}>
					<Subheader>
						<SubheaderLeft>
							{`${userState.customer.firstName} ${userState.customer.lastName}`}

							<Badge
								color='blue'
								variant='outline'
								rounded='rounded-full'
								className='border-transparent'>
								{userState.email}
							</Badge>
						</SubheaderLeft>
					</Subheader>
					<Container className='h-full'>
						<div className='flex flex-nowrap overflow-x-scroll nav-active md:hidden'>
							{nav}
						</div>

						<Card className='h-full mt-4' bodyClassName='flex'>
							<div className='hidden md:flex md:flex-col md:flex-nowrap flex-shrink-0 gap-4'>
								{nav}
							</div>
							<div className='flex flex-col gap-4 w-full'>
								{activeTab === TAB.PROFILE ? (
									<>
										<SectionHeader
											title='Profil'
											action={
												<Button
													icon='HeroServer'
													variant='solid'
													isLoading={formik.isSubmitting}
													onClick={() => formik.handleSubmit()}>
													Modifier
												</Button>
											}
										/>

										<div className='w-full md:w-3/6'>
											<div className='col-span-12 mb-4 lg:col-span-6'>
												<Input
													id='firstName'
													name='firstName'
													onChange={formik.handleChange}
													value={formik.values.firstName}
													autoComplete='given-name'
													autoCapitalize='words'
												/>
											</div>
											<div className='mb-4'>
												<Input
													id='lastName'
													name='lastName'
													onChange={formik.handleChange}
													value={formik.values.lastName}
													autoComplete='family-name'
													autoCapitalize='words'
												/>
											</div>

											<div className='flex flex-col gap-4'>
												<PhoneNumberInput
													phoneNumber={telephone1}
													setPhoneNumber={setTelephone1}
												/>

												<PhoneNumberInput
													placeholder='Téléphone 2'
													phoneNumber={telephone2}
													setPhoneNumber={setTelephone2}
												/>
												<div>{AddressForm}</div>
											</div>
										</div>
									</>
								) : null}

								<div className='flex flex-col gap-6 w-full md:w-3/5'>
									{activeTab === TAB.DeliveryAddress ? (
										<div>
											<CustomerDeliveryAddresses withSelection={false} />
										</div>
									) : null}
								</div>
							</div>

							{/* <CardFooter className='flex-col-reverse md:flex-row'>
								<CardFooterChild>
									{userState.customer.updatedAt && (
										<div className='flex flex-col md:flex-row gap-2'>
											<div className='flex gap-1'>
												<Icon icon='HeroDocumentCheck' size='text-2xl' />
												<span className='text-zinc-500'>
													Dernière modification:
												</span>
											</div>
											<b>{formatDate(userState.customer.updatedAt)}</b>
										</div>
									)}
								</CardFooterChild>
								<CardFooterChild className='w-full md:w-auto'>
									<Button
										icon='HeroServer'
										variant='solid'
										className='w-full md:w-auto'
										// isDisable={saveBtnDisable}
										isLoading={formik.isSubmitting}
										onClick={() => formik.handleSubmit()}>
										Modifier
									</Button>
								</CardFooterChild>
							</CardFooter> */}
						</Card>
					</Container>
				</PageWrapper>
			) : null}
		</>
	);
};

export default CustomerProfilePage;
