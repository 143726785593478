import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import { FilterOption } from '../../../types/common/filters.type';

import DataGridFilters from '../../../components/ui/DataGrid/DataGridFilters';
import constants from '../../customer/constants';
import AdminOrdersTable from '../components/AdminOrdersTable';

const filters: FilterOption[] = [
	{
		filterKey: 'statuses',
		label: 'Statut',
		options: constants.FORMATTED_STATUS,
		type: 'multiple',
	},
	{
		filterKey: 'date',
		label: 'Date de commandes',
		type: 'dateRange',
	},
];

const AdminOrdersPage = () => {
	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Commandes' currentPage='Commandes' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Commandes'>
				<Container>
					<DataGridFilters filters={filters} />
					<AdminOrdersTable />
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminOrdersPage;
