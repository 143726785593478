import { Link } from 'react-router-dom';
import pagesConfig from '../config/pages.config';

const DataProtection = () => {
	return (
		<div>
			<span className='text-zinc-400'>
				En confirmant votre adresse email, vous acceptez également nos
			</span>
			<Link to={pagesConfig.termsOfUsePage.to}> conditions d'utilisation </Link>
			<span className='text-zinc-400'>et que vous avez lu et compris notre </span>
			<Link to={pagesConfig.privacyPolicyPage.to}> politique de confidentialité.</Link>
		</div>
	);
};

export default DataProtection;
