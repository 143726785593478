import { useMemo, useState } from 'react';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import { AccountRole } from '../../types/shared-enums';
import CustomerOnboarding from './components/CustomerOnboarding';
import DeliveryServiceOnboarding from './components/DeliveryServiceOnboarding';
import UserTypeSelector from './components/UserTypeSelector';

const OnboardingPage = () => {
	const [activeTab, setActiveTab] = useState(AccountRole.customer);

	const content = useMemo(() => {
		switch (activeTab) {
			case AccountRole.customer:
				return <CustomerOnboarding />;
			case AccountRole.deliveryCompany:
				return <DeliveryServiceOnboarding />;

			default:
				return null;
		}
	}, [activeTab]);

	return (
		<PageWrapper
			isProtectedRoute={false}
			className='bg-white dark:bg-inherit px-6 py-6'
			name='Informations de compte'>
			<div className='container mx-auto flex h-full items-center justify-center'>
				<div className='flex max-w-md flex-col gap-8'>
					{/* <LogoTemplate className='h-12' /> */}

					<div className='text-xl md:text-4xl'>
						Bienvenue sur <br />
						<span className='font-bold'>Global OrderPoint</span>
					</div>
					<div>Quel type d'utilisateur êtes-vous ?</div>
					<div className='flex flex-col gap-6'>
						<UserTypeSelector activeTab={activeTab} setActiveTab={setActiveTab} />
						{content}
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default OnboardingPage;
