import { ChangeEvent, FocusEvent } from 'react';
import FieldWrap from '../../../components/form/FieldWrap';
import Input from '../../../components/form/Input';
import Validation from '../../../components/form/Validation';
import Icon from '../../../components/icon/Icon';

interface IInputData {
	value: string;
	isTouched?: boolean;
	invalidFeedback?: string;
	onChange(e: ChangeEvent<HTMLInputElement>): void;
	onBlur(e: FocusEvent<any, Element>): void;
}
interface Props {
	isValid: boolean;
	city: IInputData;
	district: IInputData;
	postalCode: IInputData;
}

const AddressForm = ({ isValid, city, district, postalCode }: Props) => {
	return (
		<>
			<div>
				<Validation
					isValid={isValid}
					isTouched={city.isTouched}
					invalidFeedback={city.invalidFeedback}>
					<FieldWrap firstSuffix={<Icon icon='HeroBuildingOffice2' className='mx-2' />}>
						<Input
							id='city'
							autoComplete='city'
							name='city'
							placeholder='Ville'
							value={city.value}
							onChange={(e) => city.onChange(e)}
							onBlur={(e) => city.onBlur(e)}
						/>
					</FieldWrap>
				</Validation>
			</div>

			<div>
				<Validation
					isValid={isValid}
					isTouched={district.isTouched}
					invalidFeedback={district.invalidFeedback}>
					<FieldWrap firstSuffix={<Icon icon='HeroBuildingOffice2' className='mx-2' />}>
						<Input
							id='district'
							autoComplete='district'
							name='district'
							placeholder='Quartier'
							value={district.value}
							onChange={(e) => district.onChange(e)}
							onBlur={(e) => district.onBlur(e)}
						/>
					</FieldWrap>
				</Validation>
			</div>

			<div>
				<Validation
					isValid={isValid}
					isTouched={postalCode.isTouched}
					invalidFeedback={postalCode.invalidFeedback}>
					<FieldWrap firstSuffix={<Icon icon='HeroHashtag' className='mx-2' />}>
						<Input
							id='postalCode'
							autoComplete='postalCode'
							name='postalCode'
							placeholder='Code Postal'
							value={postalCode.value}
							onChange={(e) => postalCode.onChange(e)}
							onBlur={(e) => postalCode.onBlur(e)}
						/>
					</FieldWrap>
				</Validation>
			</div>
		</>
	);
};

export default AddressForm;
