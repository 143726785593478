import { OpSystemData } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import SectionHeader from '../../../../components/SectionHeader';
import Button from '../../../../components/ui/Button';
import RichTextEditor from '../../../../components/ui/RichTextEditor/RichTextEditor';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import useCustomToast from '../../../../hooks/useToast';
import OpLoader from '../../../../templates/layouts/Loader/OpLoader.template';

const AdminGCUSettings = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [value, setValue] = useState<string | null>(null);
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();

	useEffect(() => {
		const load = async () => {
			try {
				setLoading(true);
				const response = await axiosFetch<{ data: OpSystemData | null }>({
					method: 'GET',
					url: '/api/admin/gcu',
				});
				setLoading(false);

				if (!response?.data) return;

				setValue(response.data.content);
			} catch (error) {
				customToast.error('Une erreur est survenue');
				setLoading(false);
			}
		};

		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const save = useCallback(async () => {
		try {
			setSaving(true);
			const response = await axiosFetch<{ succeeded: boolean }>({
				method: 'PUT',
				url: '/api/admin/gcu',
				requestBody: {
					content: value,
				},
			});
			setSaving(false);

			if (response?.succeeded) {
				customToast.success('Contenu mis à jour');
			}
		} catch (error) {
			customToast.error('Une erreur est survenue');
			setSaving(false);
		}
	}, [value, customToast, axiosFetch, setSaving]);

	if (loading) {
		return <OpLoader />;
	}

	return (
		<div className='flex flex-col h-full space-y-4'>
			<SectionHeader
				title="Conditions générales d'utilisation"
				action={
					<Button onClick={save} variant='solid' isLoading={saving} isDisable={saving}>
						Mettre à jour
					</Button>
				}
			/>

			<RichTextEditor value={value} onChange={setValue} />
		</div>
	);
};

export default AdminGCUSettings;
