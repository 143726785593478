import { useFormik } from 'formik';
import * as Yup from 'yup';
import FieldWrap from '../../form/FieldWrap';
import Input from '../../form/Input';
import Icon from '../../icon/Icon';

const filterSchema = Yup.object().shape({
	search: Yup.string(),
});

const FilterSearchInput = () => {
	const formik = useFormik({
		initialValues: {
			search: '',
		},
		validationSchema: filterSchema,
		onSubmit: () => {},
	});

	return (
		<FieldWrap
			className='flex-shrink-0'
			firstSuffix={<Icon icon='HeroMagnifyingGlass' className='mx-2' />}>
			<Input
				id='search'
				name='search'
				placeholder='Rechercher...'
				value={formik.values.search}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
		</FieldWrap>
	);
};

export default FilterSearchInput;
