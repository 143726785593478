import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
	apiKey: 'AIzaSyCmGUKo6DCofYi5VrOftcDEipmwrjLWJOM',
	authDomain: 'orderpoint-2023.firebaseapp.com',
	// authDomain: 'dev.globalorderpoint.com', // FIXME before deploiement
	projectId: 'orderpoint-2023',
	storageBucket: 'orderpoint-2023.appspot.com',
	messagingSenderId: '935497748569',
	appId: '1:935497748569:web:9f37cdd96fd5a933d56148',
	measurementId: 'G-7F5V4F0WFB',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// sconst provider = new GoogleAuthProvider();
export const auth = getAuth(app);
