import { useMemo } from 'react';
import Aside, { AsideBody, AsideFooter, AsideHead } from '../../../components/layouts/Aside/Aside';
import useUserStore from '../../../hooks/useUserStore';
import UserTemplate from '../User/User.template';
import LogoAndAsideTogglePart from './_parts/LogoAndAsideToggle.part';
import AdminSidebar from './navs/AdminSidebar';
import CustomerSidebar from './navs/CustomerSidebar';
import DeliveryCompanySidebar from './navs/DeliveryCompanySidebar';

const DefaultAsideTemplate = () => {
	const { isCustomer, isAdmin, isDeliveryCompany } = useUserStore();

	const sidebar = useMemo(() => {
		if (isCustomer) return <CustomerSidebar />;
		if (isAdmin) return <AdminSidebar />;
		if (isDeliveryCompany) return <DeliveryCompanySidebar />;

		return null;
	}, [isAdmin, isCustomer, isDeliveryCompany]);

	return (
		<Aside>
			<AsideHead>
				<LogoAndAsideTogglePart />
			</AsideHead>
			<AsideBody className='mt-2'>{sidebar}</AsideBody>
			<AsideFooter>
				<UserTemplate />
			</AsideFooter>
		</Aside>
	);
};

export default DefaultAsideTemplate;
