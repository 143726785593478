import { CuCustomerMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import Card from '../../../components/ui/Card';
import ListTile from '../../../components/ui/ListTile';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../../utils/ui.util';

type Props = {
	customerId: string;
};

const DeliveryCompanyCustomerDetails = ({ customerId }: Props) => {
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();
	const [customer, setCustomer] = useState<CuCustomerMinimal | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const getCustomer = useCallback(async () => {
		try {
			setLoading(true);

			const response = await axiosFetch<{ customer: CuCustomerMinimal }>({
				method: 'GET',
				url: `/api/delivery-company/customers/${customerId}/details`,
			});
			setLoading(false);

			if (response?.customer) {
				setCustomer(response.customer);
			}
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la récupération du client");
			setLoading(false);
		}
	}, [axiosFetch, customerId, customToast]);

	useEffect(() => {
		getCustomer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card header='Détails'>
			{loading ? (
				<OpLoader />
			) : (
				<div className='flex flex-col gap-3'>
					{customer ? (
						<>
							<ListTile label='ID' value={customer.id} />
							<ListTile label='Nom' value={customer.firstName} />
							<ListTile label='Prénom' value={customer.lastName} />
							<ListTile label='Téléphone 1' value={customer.telephone1} />
							<ListTile label='Téléphone 2' value={customer.telephone2} />
							<ListTile label='Inscrit le' value={formatDate(customer.createdAt)} />
						</>
					) : (
						<p>Client non trouvé</p>
					)}
				</div>
			)}
		</Card>
	);
};

export default DeliveryCompanyCustomerDetails;
