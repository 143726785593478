import { CuDeliveryAddress } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';

import DeliveryAddress from '../../customer/components/DeliveryAddress';

type Props = {
	customerId: string;
};

const AdminCustomerDeliveryAddresses = ({ customerId }: Props) => {
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();
	const [deliveryAddresses, setDeliveryAddresses] = useState<CuDeliveryAddress[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getDeliveryAddresses = useCallback(async () => {
		try {
			setLoading(true);

			const response = await axiosFetch<{ deliveryAddresses: CuDeliveryAddress[] }>({
				method: 'GET',
				url: `/api/admin/customers/${customerId}/delivery-addresses`,
			});
			setLoading(false);

			if (response?.deliveryAddresses) {
				setDeliveryAddresses(response.deliveryAddresses);
			}
		} catch (error) {
			customToast.error(
				"Une erreur s'est produite lors de la récupération des addresses de livraison",
			);
			setLoading(false);
		}
	}, [axiosFetch, customerId, customToast]);

	useEffect(() => {
		getDeliveryAddresses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card header='Addresses de livraison'>
			{loading ? (
				<OpLoader />
			) : (
				<div>
					{deliveryAddresses.length === 0 ? (
						<EmptyContent message="Ce client n'a pas d'addresse de livraison" />
					) : (
						<>
							{deliveryAddresses.map((d) => (
								<DeliveryAddress deliveryAddress={d} key={d.id} />
							))}
						</>
					)}
				</div>
			)}
		</Card>
	);
};

export default AdminCustomerDeliveryAddresses;
