import { CuCustomer } from '@devschap/order-point-types';
import { getCountryName } from '../../utils/ui.util';
import Card from '../ui/Card';
import ListTile from '../ui/ListTile';

type Props = {
	customer: CuCustomer;
	hideID?: boolean;
	hideReferralCode?: boolean;
};

const CustomerDetails = ({ customer, hideID, hideReferralCode }: Props) => {
	return (
		<Card header='Client'>
			{hideID ? null : <ListTile label='ID' value={customer.id} />}
			<ListTile label='Nom & Prénom' value={`${customer.lastName} ${customer.firstName}`} />
			<ListTile
				label='Email'
				value={<a href={`mailto:${customer.account.email}`}>{customer.account.email}</a>}
			/>
			<ListTile
				label='Téléphone 1'
				value={<a href={`tel:${customer.telephone1}`}>{customer.telephone1}</a>}
			/>
			{customer.telephone2 ? (
				<ListTile
					label='Téléphone 2'
					value={<a href={`tel:${customer.telephone2}`}>{customer.telephone2}</a>}
				/>
			) : null}

			{hideReferralCode ? null : (
				<ListTile
					label="Code d'inscription"
					value={customer.dcReferralCode ?? 'N/A'}
					description='Le code utilisé par le client lors de son inscription'
				/>
			)}
			<ListTile
				label='Pays de résidence'
				value={getCountryName(customer.address.countryIso)}
			/>
			<ListTile
				label='Ville / Quartier'
				value={`${customer.address.city} / ${customer.address.district}`}
			/>
		</Card>
	);
};

CustomerDetails.defaultProps = {
	hideID: undefined,
	hideReferralCode: undefined,
};

export default CustomerDetails;
