import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PhoneNumberInput, { IPhoneNumber } from '../../../components/PhoneNumberInput';
import SectionHeader from '../../../components/SectionHeader';
import FieldWrap from '../../../components/form/FieldWrap';
import Input from '../../../components/form/Input';
import Validation from '../../../components/form/Validation';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/ui/Button';
import useAddressForm from '../../../hooks/useAddressForm';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import useUserStore from '../../../hooks/useUserStore';
import {
	formatOptionalString,
	formatPhoneNumber,
	parsePhoneNumber,
	validPhoneNumber,
} from '../../../utils/ui.util';

type TValues = {
	pageFacebook: string;
	pageTiktok: string;
	pageInstagram: string;
	webSiteUrl: string;
};

const identitySchema = Yup.object().shape({
	pageFacebook: Yup.string().url('Veuillez entrer une URL valide pour Facebook').optional(),
	pageTiktok: Yup.string().url('Veuillez entrer une URL valide pour Tiktok').optional(),
	pageInstagram: Yup.string().url('Veuillez entrer une URL valide pour Instagram').optional(),
	webSiteUrl: Yup.string().url('Veuillez entrer une URL valide pour le site internet').optional(),
});

const DeliveryCompanyIdentity = () => {
	const { state: user, setDeliveryServiceData } = useUserStore();
	const customToast = useCustomToast();
	const { axiosFetch } = useAxiosFunction();
	const { addressFormik, AddressForm, validateAddressForm, country } = useAddressForm({
		address: user.address,
	});

	const [telephone1, setTelephone1] = useState<IPhoneNumber>(parsePhoneNumber(user.telephone1));
	const [telephone2, setTelephone2] = useState<IPhoneNumber>(parsePhoneNumber(user.telephone2));

	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			pageFacebook: user.deliveryService?.pageFacebook ?? '',
			pageTiktok: user.deliveryService?.pageTiktok ?? '',
			pageInstagram: user.deliveryService?.pageInstagram ?? '',
			webSiteUrl: user.deliveryService?.webSiteUrl ?? '',
		},
		validationSchema: identitySchema,
		onSubmit: async (values: TValues) => {
			try {
				if (!user.address?.id || !user.deliveryService) return;

				if (!validateAddressForm()) {
					return;
				}

				if (!validPhoneNumber(telephone1)) {
					customToast.error('Numéro de téléphone 1 incorrect');
					return;
				}

				const dcData = {
					pageFacebook: formatOptionalString(values.pageFacebook),
					pageTiktok: formatOptionalString(values.pageTiktok),
					pageInstagram: formatOptionalString(values.pageInstagram),
					webSiteUrl: formatOptionalString(values.webSiteUrl),
					telephone1: formatPhoneNumber(telephone1),
					telephone2: formatPhoneNumber(telephone2),
				};

				const response = await axiosFetch<{
					succeeded: boolean;
				}>({
					method: 'PATCH',
					url: '/api/delivery-company',
					requestBody: {
						...dcData,
						address: {
							id: user.address?.id,
							countryIso: country,
							city: addressFormik.values.city,
							district: addressFormik.values.district,
							postalCode: addressFormik.values.postalCode,
						},
					},
				});

				if (!response?.succeeded) {
					customToast.error('La sauvegarde a échouée. Veuillez réessayer');
					return;
				}

				dispatch(
					setDeliveryServiceData({
						address: {
							...addressFormik.values,
							countryIso: country,
							id: user.address.id,
						},
						deliveryService: {
							...user.deliveryService,
							pageFacebook: dcData.pageFacebook ?? null,
							pageInstagram: dcData.pageInstagram ?? null,
							pageTiktok: dcData.pageTiktok ?? null,
							webSiteUrl: dcData.webSiteUrl ?? null,
						},
						telephone1: dcData.telephone1 ?? null,
						telephone2: dcData.telephone2 ?? null,
					}),
				);
				addressFormik.resetForm();

				customToast.success('Données sauvegardées avec succès');
			} catch (e: any) {
				customToast.error('Une erreur est survenue.');
			} finally {
				formik.setSubmitting(false);
			}
		},
	});

	if (!user.deliveryService) return null;

	return (
		<div className='container lg:max-w-md'>
			<SectionHeader
				title='Identité'
				action={
					<Button
						isLoading={formik.isSubmitting}
						isDisable={formik.isSubmitting}
						variant='solid'
						onClick={() => formik.handleSubmit()}>
						Mettre à jour
					</Button>
				}
			/>

			<form className='flex flex-col mt-3 gap-4' noValidate>
				<PhoneNumberInput phoneNumber={telephone1} setPhoneNumber={setTelephone1} />

				<PhoneNumberInput
					placeholder='Téléphone 2'
					phoneNumber={telephone2}
					setPhoneNumber={setTelephone2}
				/>

				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.pageFacebook}
						invalidFeedback={formik.errors.pageFacebook}>
						<FieldWrap firstSuffix={<Icon icon='HeroLink' className='mx-2' />}>
							<Input
								id='pageFacebook'
								autoComplete='pageFacebook'
								name='pageFacebook'
								placeholder='Page Facebook'
								value={formik.values.pageFacebook ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.pageTiktok}
						invalidFeedback={formik.errors.pageTiktok}>
						<FieldWrap firstSuffix={<Icon icon='HeroLink' className='mx-2' />}>
							<Input
								id='pageFaceTiktok'
								autoComplete='pageTiktok'
								name='pageTiktok'
								placeholder='Page Tiktok'
								value={formik.values.pageTiktok ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.pageInstagram}
						invalidFeedback={formik.errors.pageInstagram}>
						<FieldWrap firstSuffix={<Icon icon='HeroLink' className='mx-2' />}>
							<Input
								id='pageInstagram'
								autoComplete='pageInstagram'
								name='pageInstagram'
								placeholder='Page Instagram'
								value={formik.values.pageInstagram ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.webSiteUrl}
						invalidFeedback={formik.errors.webSiteUrl}>
						<FieldWrap
							firstSuffix={<Icon icon='HeroGlobeEuropeAfrica' className='mx-2' />}>
							<Input
								id='webSiteUrl'
								autoComplete='webSiteUrl'
								name='webSiteUrl'
								placeholder='Site internet'
								value={formik.values.webSiteUrl ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>

				<div className='mt-3'>{AddressForm}</div>
			</form>
		</div>
	);
};

export default DeliveryCompanyIdentity;
