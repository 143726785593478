import { FC, PropsWithChildren, useState } from 'react';

import Icon from '../icon/Icon';
import Collapse from '../utils/Collapse';
import Card from './Card';

type AcType = {
	title: string;
};

const Accordion: FC<AcType & PropsWithChildren> = ({ title, children }) => {
	const [state, setState] = useState<boolean>(false);

	return (
		<Card className='bg-zinc-100'>
			<div
				className='flex items-center justify-between gap-28 hover:text-blue-500'
				onClick={() => setState(!state)}>
				<span className={state ? 'font-bold' : ''}>{title}</span>
				<div className='text-xl'>
					<Icon icon={state ? 'HeroChevronUp' : 'HeroChevronDown'} />
				</div>
			</div>
			<Collapse isOpen={state}>
				<div className='pt-4 text-justify'>{children}</div>
			</Collapse>
		</Card>
	);
};

export default Accordion;
