import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, {
	SubheaderLeft,
	SubheaderRight,
} from '../../../components/layouts/Subheader/Subheader';
import Button from '../../../components/ui/Button';
import ButtonLink from '../../../components/ui/ButtonLink';
import { adminPages } from '../../../config/pages.config';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';

import CustomerDetails from '../../../components/customer/CustomerDetails';
import OrderDetails from '../../../components/order/OrderDetails';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import { OrderStatus } from '../../../types/shared-enums';
import AdminManageOrderItems from '../components/AdminManageOrderItems';
import AdminOrderBilling from '../components/AdminOrderBilling';
import useFetchOrder from '../hooks/useFetchOrder';

const AdminOrderPage = () => {
	const { loading, order: data } = useFetchOrder();
	const customToast = useCustomToast();
	const { axiosFetch } = useAxiosFunction();
	const location = useLocation();

	const returnUrl = location.state?.returnUrl;

	const validateOrder = useCallback(async () => {
		if (!data?.order) return;
		try {
			const response = await axiosFetch<{ succeeded: string }>({
				method: 'POST',
				url: `/api/admin/order/${data.order.id}/validate`,
			});
			if (response?.succeeded) {
				customToast.success('Commande validée avec succès');
				window.location.reload();
			}
		} catch (error) {
			customToast.error('Une erreur est survenue. Veuillez rafraichir la page');
		}
	}, [customToast, axiosFetch, data?.order]);

	const canBeUpdated = data?.order.status === OrderStatus.processing;

	const displayContent = useMemo(() => {
		if (!data) return null;

		return (
			<div className='grid grid-cols-12 gap-4'>
				<div className='col-span-12 sm:col-span-12 lg:col-span-4 space-y-4'>
					<OrderDetails
						code={data.order.code}
						status={data.order.status}
						createdAt={data.order.createdAt}
						deliveryDate={data.order.deliveryDate}
					/>

					<CustomerDetails customer={data.customer} />
					<AdminOrderBilling orderId={data.order.id} canBeUpdated={canBeUpdated} />
				</div>

				<div className='col-span-12 sm:col-span-12 lg:col-span-8'>
					<AdminManageOrderItems orderId={data.order.id} canBeUpdated={canBeUpdated} />
				</div>
			</div>
		);
	}, [data, canBeUpdated]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Commandes / Détails' currentPage='Commande' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<Subheader>
				<SubheaderLeft>
					<ButtonLink path={returnUrl || adminPages.orders.to} text='Commandes' />
				</SubheaderLeft>
				{canBeUpdated ? (
					<SubheaderRight>
						<Button icon='HeroServer' variant='solid' onClick={validateOrder}>
							Valider
						</Button>
					</SubheaderRight>
				) : null}
			</Subheader>

			<PageWrapper name='Commande'>
				<Container>{loading ? <OpLoader /> : displayContent}</Container>
			</PageWrapper>
		</>
	);
};

export default AdminOrderPage;
