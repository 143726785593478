import { ReactNode } from 'react';
import { DeliveryMan1 } from '../../assets/images';
import Container from '../layouts/Container/Container';
import ButtonLink from './ButtonLink';

type Props = {
	message: ReactNode;
	redirectTo?: string;
	redirectToText?: string;
};

const ErrorContent = ({ message, redirectTo, redirectToText }: Props) => {
	return (
		<Container className='flex flex-col space-y-8 items-center justify-center'>
			<img src={DeliveryMan1 as string} alt='contenu-vide' className='max-h-[16rem]' />
			<span className='text-xl text-zinc-500'>{message}</span>

			{redirectTo && redirectToText ? (
				<ButtonLink path={redirectTo} text={redirectToText} showBorder />
			) : null}
		</Container>
	);
};

ErrorContent.defaultProps = {
	redirectTo: undefined,
	redirectToText: undefined,
};

export default ErrorContent;
