import { PublicOpSystemData } from '@devschap/order-point-types';
import { useEffect, useState } from 'react';
import useAxiosFunction from '../../hooks/useAxiosFunction';
import OpLoader from '../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../utils/ui.util';
import HtmlViewer from '../HtmlViewer';
import EmptyContent from '../ui/EmptyContent';

type Props = {
	className?: string;
	apiUrl: string;
	emptyStateMessage: string;
};

const PublicDataLayout = ({ className, apiUrl, emptyStateMessage }: Props) => {
	const [data, setData] = useState<PublicOpSystemData | null>(null);
	const { loading, axiosFetch } = useAxiosFunction();

	useEffect(() => {
		const load = async () => {
			const result = await axiosFetch<{ data: PublicOpSystemData | null }>({
				method: 'get',
				url: apiUrl,
			});

			setData(result?.data ?? null);
		};
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) return <OpLoader />;

	return data ? (
		<div className='flex flex-col gap-4'>
			<p className='date-info'>
				Dernière mise à jour :
				<span className='ml-2 font-semibold'>{formatDate(data.updatedAt)}</span>
			</p>

			<div className={className}>
				<HtmlViewer html={data.content} />
			</div>
		</div>
	) : (
		<EmptyContent message={emptyStateMessage} />
	);
};

PublicDataLayout.defaultProps = {
	className: undefined,
};

export default PublicDataLayout;
