import { PropsWithChildren } from 'react';

type Props = {
	legend: string;
};

const Fieldset = ({ legend, children }: Props & PropsWithChildren) => {
	return (
		<fieldset className='border p-2 rounded'>
			<legend>{legend}</legend>
			{children}
		</fieldset>
	);
};

export default Fieldset;
