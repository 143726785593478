import classNames from 'classnames';
import { DeliveryMan1 } from '../assets/images';

type Props = {
	pictureUrl: string | null;
	productName: string | null;
	className?: string;
};

const ProductImg = ({ pictureUrl, productName, className }: Props) => {
	return (
		<img
			className={classNames(
				'aspect-[9/12] w-14 rounded object-contain 2xl:w-20 mr-4',
				className,
			)}
			src={pictureUrl ?? DeliveryMan1}
			alt={productName ?? 'image du produit'}
		/>
	);
};

ProductImg.defaultProps = {
	className: undefined,
};

export default ProductImg;
