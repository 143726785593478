import { useMemo } from 'react';
import Breadcrumb from '../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../components/layouts/Header/Header';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import useUserStore from '../hooks/useUserStore';
import DefaultHeaderRightCommon from '../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import AdminDashboard from './admin/components/AdminDashboard';
import CustomerDashboard from './customer/components/CustomerDashboard';
import DeliveryCompanyDashboard from './deliveryCompany/components/DeliveryCompanyDashboard';

const DashboardPage = () => {
	const { isCustomer, isAdmin, isDeliveryCompany } = useUserStore();

	const dashboard = useMemo(() => {
		if (isCustomer) return <CustomerDashboard />;
		if (isAdmin) return <AdminDashboard />;
		if (isDeliveryCompany) return <DeliveryCompanyDashboard />;

		return <div>Bienvenue</div>;
	}, [isAdmin, isCustomer, isDeliveryCompany]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Tableau de board' currentPage='Bilan' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<PageWrapper name='Tableau de board'>
				<Container>{dashboard}</Container>
			</PageWrapper>
		</>
	);
};

export default DashboardPage;
