import { ReactElement, useState } from 'react';
import DeliveryCompanyStatus from '../../../components/delivery-company/DeliveryCompanyStatus';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Badge from '../../../components/ui/Badge';
import Button, { IButtonProps } from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import useUserStore from '../../../hooks/useUserStore';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import { TColors } from '../../../types/colors.type';
import { TIcons } from '../../../types/icons.type';
import DeliverCompanyPrincingRules from '../components/DeliverCompanyPrincingRules';
import DeliveryCompanyIdentity from '../components/DeliveryCompanyIdentity';

type TABKEY = 'IDENTITE' | 'GRILLE_TARIFAIRE';
type TTab = {
	text: 'Identité' | 'Grille tarifaire';
	icon: TIcons;
	color?: TColors;
	tab: TABKEY;
};
type TTabs = {
	[key in TABKEY]: TTab;
};

const TAB: TTabs = {
	IDENTITE: {
		text: 'Identité',
		icon: 'HeroUser',
		tab: 'IDENTITE',
	},
	GRILLE_TARIFAIRE: {
		text: 'Grille tarifaire',
		icon: 'HeroBanknotes',
		tab: 'GRILLE_TARIFAIRE',
	},
};

const DeliveryCompanySettingsPage = () => {
	const { state: userState } = useUserStore();
	const [activeTab, setActiveTab] = useState<TTab>(TAB.IDENTITE);
	const company = userState.deliveryService;

	const defaultProps: IButtonProps = {
		color: 'zinc',
	};
	const activeProps: IButtonProps = {
		...defaultProps,
		isActive: true,
		color: 'blue',
		colorIntensity: '500',
	};
	const tabComponentsMap: Record<TABKEY, ReactElement | null> = {
		IDENTITE: <DeliveryCompanyIdentity />,
		GRILLE_TARIFAIRE: company?.actived ? (
			<DeliverCompanyPrincingRules />
		) : (
			<EmptyContent message='Disponible une fois votre serive actif' />
		),
	};

	const nav = Object.values(TAB).map((i) => (
		<div key={i.text}>
			<Button
				icon={i.icon}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(activeTab.text === i.text ? { ...activeProps } : { ...defaultProps })}
				onClick={() => {
					setActiveTab(i);
				}}>
				{i.text}
			</Button>
		</div>
	));

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Paramètres' currentPage='Paramètres' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			{company ? (
				<PageWrapper name={company.label}>
					<Subheader>
						<SubheaderLeft>
							{company.label}
							<Badge
								color='blue'
								variant='outline'
								rounded='rounded-full'
								className='border-transparent'>
								{company.ifu}
							</Badge>
							<DeliveryCompanyStatus actived={company.actived} />
						</SubheaderLeft>
					</Subheader>
					<Container className='min-h-[calc(100vh-146px)] pb-4'>
						{/* mobile nav */}
						<div className='flex flex-nowrap overflow-x-scroll nav-active md:hidden'>
							{nav}
						</div>

						<Card className='min-h-full mt-4' bodyClassName='flex space-y-0'>
							<div className='hidden md:flex md:flex-col md:flex-nowrap flex-shrink-0 space-y-2 border-r'>
								{nav}
							</div>
							<div className='px-4 w-full'>
								{activeTab ? tabComponentsMap[activeTab.tab] : null}
							</div>
						</Card>
					</Container>
				</PageWrapper>
			) : null}
		</>
	);
};

export default DeliveryCompanySettingsPage;
