import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import { deliveryCompanyPages } from '../../../config/pages.config';

type Props = {
	orderId: number;
	returnUrl?: string;
};

const DeliveryCompanyOrderAction = ({ orderId, returnUrl }: Props) => {
	const navigate = useNavigate();

	const openOrderDetailsPage = useCallback(() => {
		const path = generatePath(deliveryCompanyPages.order.to, { orderId });
		navigate(path, { state: { returnUrl } });
	}, [orderId, navigate, returnUrl]);

	return (
		<div>
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
				</DropdownToggle>
				<DropdownMenu placement='bottom-end'>
					<DropdownItem icon='HeroEye' onClick={openOrderDetailsPage}>
						Voir
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

DeliveryCompanyOrderAction.defaultProps = {
	returnUrl: undefined,
};

export default DeliveryCompanyOrderAction;
