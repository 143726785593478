import { OrOrderMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LIMIT_PER_PAGE } from '../../../constants/datagrid.constants';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';

const useFetchOrders = () => {
	const [orders, setOrders] = useState<OrOrderMinimal[]>([]);
	const [offset, setOffset] = useState(0);
	const [showLoadMore, setShowLoadMore] = useState(true);

	const customToast = useCustomToast();
	const { axiosFetch, loading } = useAxiosFunction();

	const [filters, setFilters] = useState<Record<string, any>>({});
	const [searchParams, _] = useSearchParams();

	const loadMore = useCallback(() => {
		setOffset((prev) => prev + LIMIT_PER_PAGE);
	}, [setOffset]);

	const getOrders = async () => {
		if (loading) return;
		try {
			const response = await axiosFetch<{ orders: OrOrderMinimal[] }>({
				method: 'GET',
				url: '/api/admin/orders',
				queryParams: {
					offset,
					limit: LIMIT_PER_PAGE,
					...filters,
				},
			});
			const newData = response?.orders ?? [];
			if (offset === 0) {
				setOrders(newData);
			} else {
				setOrders((prev) => [...prev, ...newData]);
			}
			setShowLoadMore(Boolean(newData.length));
		} catch (error) {
			customToast.error("Vous n'avez aucune commande");
		}
	};

	useEffect(() => {
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset, filters]);

	useEffect(() => {
		setFilters({
			statuses: searchParams.getAll('statuses'),
			customer_ids: searchParams.getAll('customer_ids'),
			date: searchParams.getAll('date'),
			delivery_service_ids: searchParams.getAll('delivery_service_ids'),
		});
		setOffset(0);
	}, [searchParams]);

	return {
		loading,
		orders,
		showLoadMore,
		loadMore: showLoadMore ? loadMore : undefined,
		isLoadingMore: offset > 0 && loading,
	};
};

export default useFetchOrders;
