import { CuCartItem } from '@devschap/order-point-types';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import * as Yup from 'yup';
import { DeliveryMan1 } from '../../../assets/images';
import FieldWrap from '../../../components/form/FieldWrap';
import Textarea from '../../../components/form/Textarea';
import Validation from '../../../components/form/Validation';
import Button from '../../../components/ui/Button';
import ExternalLink from '../../../components/ui/ExternalLink';
import Modal from '../../../components/ui/Modal';
import useCustomToast from '../../../hooks/useToast';
import { limitText } from '../../../utils/ui.util';

interface Props {
	item: CuCartItem;
	onUpdateQt(link: string, quantity: number): void;
	onUpdateInstructions(link: string, instructions: string | null): void;
}

type TValues = {
	instructions: string | null;
};

const instructionSchema = Yup.object().shape({
	instructions: Yup.string(),
});

const EditableCartItemCard = ({ item, onUpdateQt, onUpdateInstructions }: Props) => {
	const customToast = useCustomToast();
	const [localQuantity, setLocalQuantity] = useState(item.quantity);
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [instructionModal, setInstructionModal] = useState<boolean>(false);

	const { configuration, imageUrl, link, name } = item;

	const formik = useFormik({
		initialValues: {
			instructions: item.instructions ?? '',
		},
		validationSchema: instructionSchema,
		onSubmit: (values: TValues) => {
			try {
				onUpdateInstructions(link, values.instructions ?? null);
				setInstructionModal(false);
			} catch (e: any) {
				customToast.error('Un problème est survenu, veuillez réessayer plus tard.');
			}
		},
	});

	const debounceCaller = useDebouncedCallback(() => {
		if (!onUpdateQt || localQuantity === item.quantity) return;

		onUpdateQt(link, localQuantity);
	}, 1000);

	const handleQuantity = useCallback(
		(op: 'add' | 'remove') => {
			if (op === 'remove' && localQuantity === 1) {
				setModalStatus(true);
			} else {
				setLocalQuantity((prev) => (op === 'add' ? prev + 1 : prev - 1));
			}
		},
		[setLocalQuantity, localQuantity],
	);

	useEffect(() => {
		debounceCaller();
	}, [debounceCaller, localQuantity]);

	const hasInstrucions =
		formik.values.instructions !== null && formik.values.instructions.trim() !== '';

	const handleConfirmeDelete = useCallback(() => {
		setModalStatus(true);
		onUpdateQt(link, 0);
	}, [link, onUpdateQt]);

	return (
		<div className='border-t border-gray-300 py-4'>
			<div className='flex gap-4 mb-2 md:gap-8 '>
				{/* eslint-disable-next-line react/self-closing-comp */}
				<div
					className='w-20 h-20 md:w-32 md:h-32 flex-shrink-0 relative bg-contain bg-center bg-no-repeat'
					style={{ backgroundImage: `url(${imageUrl ?? DeliveryMan1})` }}></div>

				<div className='w-full flex flex-col space-y-2'>
					<div className='text-sm md:text-base font-normal break-all flex flex-col space-y-2'>
						<span>{limitText(name ?? link, 80)}</span>
						<ExternalLink url={link} />
					</div>
					<div className='text-sm md:text-base'>
						{configuration.map((c, index) => (
							<span key={c.label} className=''>
								<span className='text-sm text-gray-400'>{c.label}</span>
								<span className='ml-1'>{c.value}</span>
								{index !== configuration.length - 1 ? ' - ' : null}
							</span>
						))}
					</div>
					<div className='flex justify-between items-center'>
						<div className='flex items-center'>
							<Button
								icon={localQuantity === 1 ? 'HeroTrash' : 'HeroMinusCircle'}
								style={{ color: localQuantity === 1 ? 'red' : '' }}
								onClick={() => handleQuantity('remove')}
								size='xl'
							/>
							<span className='text-lg text-center w-4 mx-2'>{localQuantity}</span>
							<Button
								icon='HeroPlusCircle'
								onClick={() => handleQuantity('add')}
								size='xl'
							/>
						</div>

						<div className='flex space-x-4 md:space-x-0 items-center'>
							<Button
								className='!text-sky-500'
								size='sm'
								color='sky'
								onClick={() => setInstructionModal(true)}
								icon={hasInstrucions ? 'HeroPencil' : 'HeroPlus'}>
								{hasInstrucions
									? 'Modifier les instructions'
									: 'Ajouter des instructions'}
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={modalStatus}
				size='sm'
				setIsOpen={setModalStatus}
				title='Confirmation'
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={() => setModalStatus(false)}>
							Non
						</Button>
						<Button
							variant='solid'
							className='w-full'
							color='red'
							onClick={handleConfirmeDelete}>
							oui
						</Button>
					</>
				}>
				Voulez-vous vraiment retirer le produit
				{name ? <span className='mx-1 font-bold'>{limitText(name, 20)}</span> : null}
				du panier ?
			</Modal>

			<Modal
				isOpen={instructionModal}
				size='sm'
				setIsOpen={setInstructionModal}
				title='Instructions'
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={() => setInstructionModal(false)}>
							Fermer
						</Button>
						<Button
							variant='solid'
							className='w-full'
							onClick={() => formik.handleSubmit()}>
							Valider
						</Button>
					</>
				}>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.instructions}
						invalidFeedback={formik.errors.instructions}>
						<FieldWrap>
							<Textarea
								id='instructions'
								autoComplete='instructions'
								className='bg-zinc-500/10 p-6 text-base'
								name='instructions'
								placeholder='Ajoutez des instructions telles que la couleur, la taille, le poids etc…'
								value={formik.values.instructions ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								rows={6}
							/>
						</FieldWrap>
					</Validation>
				</div>
			</Modal>
		</div>
	);
};

export default EditableCartItemCard;
