import Badge from './ui/Badge';

type Props = {
	quantity: number;
};

const ProductQuantitybadge = ({ quantity }: Props) => {
	return (
		<Badge variant='solid' className='border-transparent'>
			x{quantity}
		</Badge>
	);
};

export default ProductQuantitybadge;
