type Props = {
	html: string;
};

const HtmlViewer = ({ html }: Props) => {
	return (
		<>
			{/* eslint-disable-next-line react/self-closing-comp */}
			<div
				className='op-rich-text-editor-view'
				dangerouslySetInnerHTML={{ __html: html }}></div>
		</>
	);
};

export default HtmlViewer;
