import { ContactUsMessage } from '@devschap/order-point-types';
import { useEffect, useMemo, useState } from 'react';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Badge from '../../../components/ui/Badge';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import ReadMore from '../../../components/ui/ReadMore';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../../utils/ui.util';

const cols: DatagridColumnDef[] = [
	{
		field: 'createdAt',
		label: 'Envoyé le',
	},
	{
		field: 'senderFirstName',
		label: 'Prénom',
		children: ['senderEmail', 'message'],
	},
	{
		field: 'senderEmail',
		label: 'E-mail',
	},
	{
		field: 'subject',
		label: 'Objet',
	},
	{
		field: 'message',
		label: 'Contenu',
		// hideOnMobile: true,
	},
	// {
	// 	field: 'actions',
	// 	label: 'Actions',
	// },
];

const AdminContactUsMessagesPage = () => {
	const [messages, setMessages] = useState<ContactUsMessage[]>([]);
	const { axiosFetch, loading } = useAxiosFunction();

	const loadMessages = async () => {
		if (loading) return;
		const response = await axiosFetch<{ data: ContactUsMessage[] }>({
			method: 'GET',
			url: '/api/admin/contact-us-messages',
		});
		setMessages(response?.data ?? []);
	};

	useEffect(() => {
		loadMessages();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const rows = useMemo(() => {
		return messages.map((message) => ({
			createdAt: formatDate(message.createdAt),
			senderFirstName: message.senderFirstName,
			senderEmail: (
				<a href={`mailto:${message.senderEmail}`} className='underline'>
					{message.senderEmail}
				</a>
			),
			subject: message.subject,
			message: (
				<div className='max-w-[220px] lg:max-w-xs'>
					<ReadMore text={message.message} />
				</div>
			),
			actions: null,
		}));
	}, [messages]);

	const displayContent = useMemo(() => {
		return rows.length === 0 ? (
			<EmptyContent message='Pas de message' />
		) : (
			<DataGrid rows={rows} columns={cols} keyPrefix='message' />
		);
	}, [rows]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb
						path='Pages / Messages de contact'
						currentPage='Messages de contact'
					/>
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Messages de contact'>
				<Container>
					<Card
						className='h-full'
						header={
							<>
								Messages
								{messages.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{messages ? messages.length : null}
									</Badge>
								) : null}
							</>
						}>
						{loading ? <OpLoader /> : displayContent}
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminContactUsMessagesPage;
