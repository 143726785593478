import { CoAddress } from '@devschap/order-point-types';
import { getCountryName } from '../utils/ui.util';
import Card from './ui/Card';
import ListTile from './ui/ListTile';

type Props = {
	address: CoAddress;
};

const AddressDetails = ({ address }: Props) => {
	return (
		<Card header='Adresse'>
			<div className='flex flex-col space-y-2'>
				<ListTile label='Pays' value={getCountryName(address.countryIso)} />
				<ListTile label='Ville' value={address.city} />
				<ListTile label='Quartier' value={address.district} />
				<ListTile label='Code Postal' value={address.postalCode} />
			</div>
		</Card>
	);
};

export default AddressDetails;
