import { useState } from 'react';
import Button from './ui/Button';
import Modal, { ModalBody } from './ui/Modal';

type Props = {
	instructions: string | null;
};

const OpenInstruction = ({ instructions }: Props) => {
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	return (
		<>
			<Modal
				isOpen={modalStatus}
				size='sm'
				setIsOpen={setModalStatus}
				title='Instructions'
				footer={
					<Button color='zinc' className='w-full' onClick={() => setModalStatus(false)}>
						Fermer
					</Button>
				}>
				<ModalBody className=''>{instructions}</ModalBody>
			</Modal>
			<Button
				onClick={() => setModalStatus(true)}
				className='!text-sky-500'
				color='sky'
				size='sm'>
				Instructions
			</Button>
		</>
	);
};

export default OpenInstruction;
