import * as Yup from 'yup';

import { DcDeliveryCompany } from '@devschap/order-point-types';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneNumberInput, { IPhoneNumber } from '../../../components/PhoneNumberInput';
import FieldWrap from '../../../components/form/FieldWrap';
import Input from '../../../components/form/Input';
import Validation from '../../../components/form/Validation';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/ui/Button';
import { appPages } from '../../../config/pages.config';
import useAddressForm from '../../../hooks/useAddressForm';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import useUserStore from '../../../hooks/useUserStore';
import { AccountRole } from '../../../types/shared-enums';
import { formatOptionalString, formatPhoneNumber, validPhoneNumber } from '../../../utils/ui.util';
import OnboardingSubtitle from './Subtitle';

type TValues = {
	companyName: string;
	ifu: string;
	pageFacebook: string;
	pageTiktok: string;
	pageInstagram: string;
	webSiteUrl: string;
};

const deliveryCompanySchema = Yup.object().shape({
	companyName: Yup.string().required('Le nom de la société est obligatoire'),
	ifu: Yup.string().required('Veuillez rentrer un numero ifu'),
	pageFacebook: Yup.string().url('Veuillez entrer une URL valide pour Facebook').optional(),
	pageTiktok: Yup.string().url('Veuillez entrer une URL valide pour Tiktok').optional(),
	pageInstagram: Yup.string().url('Veuillez entrer une URL valide pour Instagram').optional(),
	webSiteUrl: Yup.string().url('Veuillez entrer une URL valide pour le site internet').optional(),
});

const DeliveryServiceOnboarding = () => {
	const { state: user, setDeliveryServiceData } = useUserStore();
	const customToast = useCustomToast();
	const { axiosFetch } = useAxiosFunction();
	const { addressFormik, AddressForm, validateAddressForm, country } = useAddressForm({
		address: user.address,
	});

	const [telephone1, setTelephone1] = useState<IPhoneNumber>({
		indicatif: '',
		number: '',
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			companyName: '',
			ifu: '',
			pageFacebook: '',
			pageTiktok: '',
			pageInstagram: '',
			webSiteUrl: '',
		},
		validationSchema: deliveryCompanySchema,
		onSubmit: async (values: TValues) => {
			try {
				if (!validateAddressForm()) return;

				if (!validPhoneNumber(telephone1)) {
					customToast.error('Numéro de téléphone incorrect');
					return;
				}

				const response = await axiosFetch<{
					deliveryCompany: DcDeliveryCompany;
					jwt: string;
				}>({
					method: 'POST',
					url: '/api/user',
					requestBody: {
						userType: AccountRole.deliveryCompany,
						ifu: values.ifu,
						label: values.companyName,
						pageFacebook: formatOptionalString(values.pageFacebook),
						pageTiktok: formatOptionalString(values.pageTiktok),
						pageInstagram: formatOptionalString(values.pageInstagram),
						webSiteUrl: formatOptionalString(values.webSiteUrl),
						telephone1: formatPhoneNumber(telephone1),
						address: {
							countryIso: country,
							city: addressFormik.values.city,
							district: addressFormik.values.district,
							postalCode: addressFormik.values.postalCode,
						},
					},
				});

				if (response === undefined) return;
				const { deliveryCompany, jwt } = response;
				localStorage.setItem('token', jwt);

				dispatch(
					setDeliveryServiceData({
						address: deliveryCompany.address,
						deliveryService: {
							id: deliveryCompany.id,
							label: deliveryCompany.label,
							ifu: deliveryCompany.ifu,
							pageFacebook: deliveryCompany.pageFacebook,
							pageTiktok: deliveryCompany.pageTiktok,
							pageInstagram: deliveryCompany.pageInstagram,
							webSiteUrl: deliveryCompany.webSiteUrl,
							actived: deliveryCompany.actived,
							referralCode: deliveryCompany.referralCode,
						},
						telephone1: deliveryCompany.telephone1,
						telephone2: deliveryCompany.telephone1,
					}),
				);
				addressFormik.resetForm();
				navigate(appPages.dashboard.to);
			} catch (e: any) {
				customToast.error('Une erreur est survenue.');
			} finally {
				formik.setSubmitting(false);
			}
		},
	});

	return (
		<div className='container'>
			<OnboardingSubtitle text='Veuillez remplir les informations ci-dessous relatives a votre société' />

			<form className='flex flex-col gap-4' noValidate>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.companyName}
						invalidFeedback={formik.errors.companyName}>
						<FieldWrap
							firstSuffix={<Icon icon='HeroBuildingOffice' className='mx-2' />}>
							<Input
								id='companyName'
								autoComplete='companyName'
								name='companyName'
								placeholder='Nom de la sociéte'
								value={formik.values.companyName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.ifu}
						invalidFeedback={formik.errors.ifu}>
						<FieldWrap firstSuffix={<Icon icon='HeroHashtag' className='mx-2' />}>
							<Input
								id='ifu'
								autoComplete='ifu'
								name='ifu'
								placeholder='IFU'
								value={formik.values.ifu}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>

				<PhoneNumberInput
					placeholder='Téléphone 1'
					phoneNumber={telephone1}
					setPhoneNumber={setTelephone1}
				/>

				{AddressForm}

				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.pageFacebook}
						invalidFeedback={formik.errors.pageFacebook}>
						<FieldWrap firstSuffix={<Icon icon='HeroLink' className='mx-2' />}>
							<Input
								id='pageFacebook'
								autoComplete='pageFacebook'
								name='pageFacebook'
								placeholder='Page Facebook'
								value={formik.values.pageFacebook}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.pageTiktok}
						invalidFeedback={formik.errors.pageTiktok}>
						<FieldWrap firstSuffix={<Icon icon='HeroLink' className='mx-2' />}>
							<Input
								id='pageFaceTiktok'
								autoComplete='pageTiktok'
								name='pageTiktok'
								placeholder='Page Tiktok'
								value={formik.values.pageTiktok}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.pageInstagram}
						invalidFeedback={formik.errors.pageInstagram}>
						<FieldWrap firstSuffix={<Icon icon='HeroLink' className='mx-2' />}>
							<Input
								id='pageInstagram'
								autoComplete='pageInstagram'
								name='pageInstagram'
								placeholder='Page Instagram'
								value={formik.values.pageInstagram}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.webSiteUrl}
						invalidFeedback={formik.errors.webSiteUrl}>
						<FieldWrap
							firstSuffix={<Icon icon='HeroGlobeEuropeAfrica' className='mx-2' />}>
							<Input
								id='webSiteUrl'
								autoComplete='webSiteUrl'
								name='webSiteUrl'
								placeholder='Site internet'
								value={formik.values.webSiteUrl}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>

				<Button
					size='lg'
					isLoading={formik.isSubmitting}
					isDisable={formik.isSubmitting}
					variant='solid'
					className='w-full font-semibold mt-4'
					onClick={() => formik.handleSubmit()}>
					Envoyer
				</Button>
			</form>
		</div>
	);
};

export default DeliveryServiceOnboarding;
