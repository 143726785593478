import { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { OrOrder } from '@devschap/order-point-types';
import { useKKiaPay } from 'kkiapay-react';
import TestimonialFeedback from '../../../components/common/TestimonialFeedback';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, {
	SubheaderLeft,
	SubheaderRight,
} from '../../../components/layouts/Subheader/Subheader';
import OrderBilling from '../../../components/order/OrderBilling';
import OrderDetails from '../../../components/order/OrderDetails';
import Alert from '../../../components/ui/Alert';
import Button from '../../../components/ui/Button';
import ButtonLink from '../../../components/ui/ButtonLink';
import ErrorContent from '../../../components/ui/ErrorContent';
import { customerPages } from '../../../config/pages.config';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import useUserStore from '../../../hooks/useUserStore';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { OrderStatus } from '../../../types/shared-enums';
import OrderAction from '../components/OrderAction';
import OrderItemsTable from '../components/OrderItemsTable';
import OrderProgression from '../components/OrderProgression';

const CustomerOrderDetailsPage = () => {
	const [order, setOrder] = useState<OrOrder | null>(null);
	const [isPaying, setIsPaying] = useState(false);
	const [isSavingModalOpen, setIsSavingModalOpen] = useState(false);
	const [transactionError, setTransactionError] = useState<string | null>(null);
	const [feedbackModalStatus, setFeedbackModalStatus] = useState<boolean>(false);

	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();
	const { orderId } = useParams();
	const { state: userState, displayName } = useUserStore();

	const {
		openKkiapayWidget,
		addKkiapayListener,
		removeKkiapayListener,
		addKkiapayCloseListener,
	} = useKKiaPay();

	const getOrder = useCallback(async () => {
		if (!orderId) return;

		try {
			const response = await axiosFetch<{ order: OrOrder }>({
				method: 'GET',
				url: `/api/customer/orders/${orderId}`,
			});

			setOrder(response?.order ?? null);
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la récupération de la commande");
		}
	}, [axiosFetch, orderId, customToast]);
	useEffect(() => {
		getOrder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveOrderPaymentTransaction = useCallback(
		async (transactionId: string) => {
			if (!orderId) return;

			setIsSavingModalOpen(true);

			try {
				const response = await axiosFetch<{ succeeded: boolean }>({
					method: 'POST',
					url: `/api/customer/orders/${orderId}/transaction`,
					requestBody: {
						transactionId,
					},
					suppressToast: true,
				});
				if (response?.succeeded) {
					setTransactionError(null);
					await getOrder();
				} else {
					setTransactionError(
						"Une erreur s'est produite lors de la récupération de la commande.",
					);
				}
			} catch (error: any) {
				setTransactionError(
					(error?.message as string | undefined) ?? 'Une erreur est survenue',
				);
			} finally {
				setIsSavingModalOpen(false);
			}
		},
		[orderId, getOrder, axiosFetch],
	);

	function successHandler(response: { transactionId: string }) {
		setIsPaying(false);
		if (response.transactionId) {
			saveOrderPaymentTransaction(response.transactionId);
			setFeedbackModalStatus(true);
		} else {
			setTransactionError('Une erreur est survenue.');
		}
	}

	function failureHandler(error: any) {
		setIsPaying(false);
		setTransactionError((error.message as string | undefined) ?? 'Une erreur est survenue.');
	}

	function onKKiapayClose() {
		setIsPaying(false);
	}

	useEffect(() => {
		addKkiapayListener('success', successHandler);
		addKkiapayListener('failed', failureHandler);
		addKkiapayCloseListener(onKKiapayClose);

		return () => {
			removeKkiapayListener('success');
			removeKkiapayListener('failed');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addKkiapayListener, removeKkiapayListener, addKkiapayCloseListener]);

	const pay = useCallback(() => {
		if (!order?.fees?.total) return;

		const sandbox = process.env.REACT_APP_KKIAPAY_SANDBOX === 'true';
		const phone = sandbox ? process.env.REACT_APP_KKIAPAY_PHONE : userState.telephone1;
		setIsPaying(true);
		openKkiapayWidget({
			amount: order.fees.total,
			api_key: process.env.REACT_APP_KKIAPAY_KEY,
			sandbox,
			email: userState.email ?? undefined,
			phone: phone ?? undefined,
			fullname: displayName ?? undefined,
		});
	}, [openKkiapayWidget, displayName, userState.email, userState.telephone1, order?.fees]);

	const isPayable = order?.status === OrderStatus.waitingForPayment;

	const someItemsAreRejectedOrOutOfStock =
		isPayable && (order?.items || []).some((i) => !i.accepted || !i.inStock);

	const paymentButton =
		isPayable && order.fees ? (
			<Button variant='solid' onClick={pay} isLoading={isPaying} isDisable={isPaying}>
				PAYER
			</Button>
		) : null;

	const content = useMemo(() => {
		if (loading) {
			return <OpLoader />;
		}
		if (order) {
			return (
				<div className='grid grid-cols-12 gap-4 mt-6'>
					<div className='col-span-12 sm:col-span-12 lg:col-span-4 space-y-4 '>
						<OrderDetails
							code={order.code}
							status={order.status}
							createdAt={order.createdAt}
							deliveryDate={order.deliveryDate}
						/>

						<OrderBilling fees={order.fees} loading={loading} />
						<OrderProgression orderId={order.id} />
					</div>

					<div className='col-span-12 sm:col-span-12 lg:col-span-8'>
						<OrderItemsTable products={order.items} orderStatus={order.status} />
					</div>
				</div>
			);
		}
		return (
			<ErrorContent
				message="Oups! nous n'avons pas pu récuperer les informations de la commande"
				redirectTo={customerPages.orders.to}
				redirectToText='Revenir sur mes commandes'
			/>
		);
	}, [loading, order]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb
						path='Pages / Details de commande'
						currentPage='Details de commande'
					/>
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<Subheader>
				<SubheaderLeft>
					<ButtonLink path={customerPages.orders.to} text='Mes commandes' />
				</SubheaderLeft>
				{order ? (
					<SubheaderRight>
						{isSavingModalOpen ? 'saving..' : null}
						{paymentButton}
						{order.status === OrderStatus.inprogress ? (
							<OrderAction
								orderId={order.id}
								orderStatus={order.status}
								showView={false}
							/>
						) : null}
					</SubheaderRight>
				) : null}
			</Subheader>
			<PageWrapper name='Details de commande'>
				<Container>
					{transactionError ? (
						<Alert
							className='border-transparent'
							color='red'
							icon='HeroXCircle'
							title='Transaction non sauvegardée'
							variant='solid'>
							{`${transactionError} | Veuillez contacter le serivce client pour en savoir plus.`}

							<Button
								variant='default'
								className='!text-white underline pl-0 md:pl-2'
								onClick={() => {}}>
								Contacter
							</Button>
						</Alert>
					) : null}

					{someItemsAreRejectedOrOutOfStock ? (
						<Alert
							className='border-transparent'
							color='amber'
							icon='HeroInformationCircle'
							title='Informations!'
							variant='solid'>
							Les produits non accepté ou en rupture de stock seront retirés de la
							commande après paiement
							{paymentButton}
						</Alert>
					) : null}

					{order?.status === OrderStatus.rejected ? (
						<Alert
							className='border-transparent'
							color='red'
							icon='HeroXCircle'
							title='Commande rejetée'
							variant='solid'>
							Tous les produits de votre commande ont été rejetés.
						</Alert>
					) : null}

					{content}
				</Container>
				<TestimonialFeedback
					isOpen={feedbackModalStatus}
					setIsOpen={setFeedbackModalStatus}
					message='Nous aimerions avoir votre avis sur la facturation et le procecessus de paiement'
				/>
			</PageWrapper>
		</>
	);
};

export default CustomerOrderDetailsPage;
