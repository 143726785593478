import { ReactNode } from 'react';

type Props = {
	title: ReactNode;
	action: ReactNode;
};

const SectionHeader = ({ title, action }: Props) => {
	return (
		<div className='flex justify-between items-center flex-wrap w-full space-y-2 md:space-y-0'>
			<span className='text-xl md:text-2xl font-semibold flex items-center'>{title}</span>
			{action}
		</div>
	);
};

export default SectionHeader;
