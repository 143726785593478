interface Props {
	text: string;
}

const OnboardingSubtitle = ({ text }: Props) => {
	return (
		<div className='mb-4'>
			<span className='text-xs md:text-base text-gray-400'>{text}</span>
		</div>
	);
};

export default OnboardingSubtitle;
