import { CuCustomer, DcDeliveryCompany, OpStaff } from '@devschap/order-point-types';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { appPages, authPages } from '../config/pages.config';
import { AuthResponse } from '../types/api';
import { AccountRole } from '../types/shared-enums';

import useAxiosFunction from './useAxiosFunction';
import useIsIndeApp from './useIsIndeApp';
import useUserStore from './useUserStore';

const useFetchUser = () => {
	const { loading, axiosFetch } = useAxiosFunction();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { isInsideApp } = useIsIndeApp();

	const currentLocation = location.pathname;

	const {
		setAccountData,
		setCustomerData,
		setAdminData,
		setDeliveryServiceData,
		state,
		loggedIn,
	} = useUserStore();

	const fetchUser = async (): Promise<void> => {
		let route = appPages.dashboard.to;
		try {
			const result = await axiosFetch<AuthResponse>({
				method: 'get',
				url: '/api/user/me',
			});

			if (result === undefined) {
				throw new Error('Unable to retrieve user data');
			}

			const { id: accountId, email, provider, verified, actived, role } = result.account;
			dispatch(
				setAccountData({
					accountId,
					email,
					provider,
					verified,
					actived,
					userType: role,
				}),
			);

			if (role === AccountRole.anonymous) {
				route = authPages.onboardingPage.to;
				navigate(route, { replace: true });
				return;
			}

			if (isInsideApp) {
				route = currentLocation;
			}

			const redirectTo = localStorage.getItem('redirectTo');

			if (redirectTo) {
				route = redirectTo;
				localStorage.removeItem('redirectTo');
			}

			switch (role) {
				case AccountRole.customer: {
					const {
						address,
						firstName,
						lastName,
						telephone1,
						dcReferralCode,
						telephone2,
						id,
					} = result.user as CuCustomer;
					dispatch(
						setCustomerData({
							address,
							telephone1,
							telephone2,
							customer: {
								id,
								dcReferralCode,
								firstName,
								lastName,
							},
						}),
					);

					break;
				}

				case AccountRole.admin: {
					const { address, firstName, lastName, telephone1, telephone2, id } =
						result.user as OpStaff;
					dispatch(
						setAdminData({
							address,
							telephone1,
							telephone2,
							admin: {
								id,
								firstName,
								lastName,
							},
						}),
					);
					break;
				}

				case AccountRole.deliveryCompany: {
					const {
						address,
						label,
						telephone1,
						telephone2,
						id,
						ifu,
						pageFacebook,
						pageTiktok,
						pageInstagram,
						webSiteUrl,
						referralCode,
						actived: dcActived,
					} = result.user as DcDeliveryCompany;
					dispatch(
						setDeliveryServiceData({
							address,
							telephone1,
							telephone2,
							deliveryService: {
								id,
								ifu,
								label,
								pageFacebook,
								pageTiktok,
								pageInstagram,
								webSiteUrl,
								referralCode,
								actived: dcActived,
							},
						}),
					);
					break;
				}
				default:
					route = authPages.loginPage.to;
					break;
			}
		} catch (error) {
			localStorage.removeItem('token');
			localStorage.setItem('redirectTo', location.pathname);
			route = authPages.loginPage.to;
		} finally {
			navigate(route, { replace: true });
		}
	};

	return {
		loading,
		fetchUser,
		state,
		loggedIn,
	};
};

export default useFetchUser;
