import { useCallback, useState } from 'react';
import { SingleValue } from 'react-select';
import constants from '../pages/customer/constants';
import SelectReact, { TSelectOption } from './form/SelectReact';

interface Props {
	placeholder?: string;
	countryIso: string;
	onChanged(countryCode: string): void;
}

const CountrySelector = ({ placeholder, onChanged, countryIso }: Props) => {
	const [selectedCountry, setSelectedCountry] = useState<TSelectOption | null>(
		constants.COUNTRY_OPTIONS.find((c) => c?.value === countryIso) ?? null,
	);

	const handleChange = useCallback(
		(value: SingleValue<TSelectOption>) => {
			if (!value) return;

			onChanged(value?.value);
			setSelectedCountry(value);
		},
		[onChanged],
	);

	return (
		<div>
			<SelectReact
				options={constants.COUNTRY_OPTIONS}
				name='options'
				placeholder={placeholder ?? 'Selectionnez un pays'}
				value={selectedCountry}
				onChange={(value) => {
					handleChange(value as SingleValue<TSelectOption>);
				}}
			/>
		</div>
	);
};
CountrySelector.defaultProps = {
	placeholder: undefined,
};
export default CountrySelector;
