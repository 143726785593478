import { useCallback, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, {
	SubheaderLeft,
	SubheaderRight,
} from '../../../components/layouts/Subheader/Subheader';
import Button from '../../../components/ui/Button';
import ButtonLink from '../../../components/ui/ButtonLink';
import { deliveryCompanyPages } from '../../../config/pages.config';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';

import TestimonialFeedback from '../../../components/common/TestimonialFeedback';
import CustomerDetails from '../../../components/customer/CustomerDetails';
import ManageOrderItems from '../../../components/order/ManageOrderItems';
import OrderBilling from '../../../components/order/OrderBilling';
import OrderDetails from '../../../components/order/OrderDetails';
import Modal from '../../../components/ui/Modal';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import { OrderStatus } from '../../../types/shared-enums';
import DeliveryCompanyOrderTimeline from '../components/DeliveryCompanyOrderTimeline';
import useDeliveryCompanyFetchOrder from '../hooks/useDeliveryCompanyFetchOrder';
import useDeliveryCompanyFetchOrderFees from '../hooks/useDeliveryCompanyFetchOrderFees';
import useDeliveryCompanyFetchOrderItems from '../hooks/useDeliveryCompanyFetchOrderItems';

type ModalKey = 'sendQuotation' | 'markAsShipped';

export type ModalData = Record<
	ModalKey,
	{
		title: string;
		content: string;
		actionBtnLabel: string;
		actionBtnHandler(): Promise<void>;
	}
>;

const DeliveryCompanyManageOrderPage = () => {
	const { orderId } = useParams();

	const location = useLocation();

	const [feedbackModalStatus, setFeedbackModalStatus] = useState<boolean>(false);
	const [currentModalkey, setCurrentModalKey] = useState<ModalKey | null>(null);

	const { loading, order: data, refreshOrder } = useDeliveryCompanyFetchOrder({ orderId });
	const {
		loading: loadingItems,
		orderItems,
		refreshItems,
	} = useDeliveryCompanyFetchOrderItems({ orderId });
	const {
		loading: loadingFees,
		refreshFees,
		fees,
	} = useDeliveryCompanyFetchOrderFees({ orderId });
	const customToast = useCustomToast();
	const { axiosFetch, loading: isExecutingModalAction } = useAxiosFunction();

	const { state: { returnUrl } = {} } = location;

	const sendQuotation = useCallback(async () => {
		if (!orderId) return;
		try {
			const response = await axiosFetch<{ succeeded: string }>({
				method: 'POST',
				url: `/api/delivery-company/order/${orderId}/send-quotation`,
			});
			if (response?.succeeded) {
				customToast.success('Commande validée avec succès');
				await refreshOrder();
				setCurrentModalKey(null);
				setFeedbackModalStatus(true);
			}
		} catch (error) {
			customToast.error('Une erreur est survenue. Veuillez rafraichir la page');
		}
	}, [customToast, axiosFetch, refreshOrder, setCurrentModalKey, orderId]);

	const markAsShipped = useCallback(async () => {
		if (!orderId) return;

		try {
			const response = await axiosFetch<{ succeeded: boolean }>({
				method: 'PATCH',
				url: `/api/delivery-company/orders/${orderId}`,
				requestBody: {
					status: OrderStatus.shipped,
				},
			});
			if (response?.succeeded) {
				customToast.success('Commande marquée comme expédiée avec succès');
				await refreshOrder();
				setCurrentModalKey(null);
			}
		} catch (error) {
			customToast.error('Une erreur est survenue. Veuillez rafraichir la page');
		}
	}, [customToast, refreshOrder, orderId, axiosFetch]);

	const refreshData = useCallback(async () => {
		await refreshItems();
		await refreshFees();
	}, [refreshItems, refreshFees]);

	const isProcessing = data?.order.status === OrderStatus.processing;

	const displayContent = useMemo(() => {
		if (!data) return null;

		return (
			<div className='grid grid-cols-12 gap-4'>
				<div className='col-span-12 sm:col-span-12 lg:col-span-4 space-y-4'>
					<OrderDetails
						code={data.order.code}
						status={data.order.status}
						createdAt={data.order.createdAt}
						deliveryDate={data.order.deliveryDate}
					/>

					<CustomerDetails customer={data.customer} hideID hideReferralCode />

					<OrderBilling fees={fees} loading={loadingFees} />
					<DeliveryCompanyOrderTimeline orderId={data.order.id} />
				</div>

				<div className='col-span-12 sm:col-span-12 lg:col-span-8'>
					<ManageOrderItems
						canBeUpdated={isProcessing}
						items={orderItems}
						loading={loadingItems}
						refreshData={refreshData}
					/>
				</div>
			</div>
		);
	}, [data, isProcessing, fees, loadingFees, loadingItems, orderItems, refreshData]);

	const MODAL_DATA: ModalData = useMemo(() => {
		return {
			sendQuotation: {
				title: 'Envoi du devis',
				actionBtnLabel: 'Envoyer le devis',
				content:
					"Avant d'envoyer le devis, assurez-vous d'avoir traité tous les produits et que la facturation soit juste.",
				actionBtnHandler: sendQuotation,
			},
			markAsShipped: {
				title: 'Confirmation',
				actionBtnLabel: 'Oui',
				content:
					'Voulez-vous vraiment marquer cette commande comme expédiée ? Cela signifie que la commande est bien arrivée et disponible la livraison.',
				actionBtnHandler: markAsShipped,
			},
		};
	}, [sendQuotation, markAsShipped]);

	const currentModal = useMemo(() => {
		if (!currentModalkey) return null;

		return MODAL_DATA[currentModalkey];
	}, [MODAL_DATA, currentModalkey]);

	const cta: { label: string; modalKey: ModalKey } | null = useMemo(() => {
		switch (data?.order.status) {
			case OrderStatus.processing:
				return {
					label: 'Envoyer le devis',
					modalKey: 'sendQuotation',
				};
			case OrderStatus.inprogress:
				return {
					label: 'Marquer comme expédiée',
					modalKey: 'markAsShipped',
				};

			default:
				return null;
		}
	}, [data?.order.status]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Commandes / Détails' currentPage='Commande' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<Subheader>
				<SubheaderLeft>
					<ButtonLink
						path={returnUrl || deliveryCompanyPages.orders.to}
						text='Commandes'
					/>
				</SubheaderLeft>
				<SubheaderRight>
					{cta ? (
						<Button
							icon='HeroServer'
							variant='solid'
							onClick={() => setCurrentModalKey(cta.modalKey)}>
							{cta.label}
						</Button>
					) : null}
				</SubheaderRight>
			</Subheader>

			<PageWrapper name='Commande'>
				<Container>{loading ? <OpLoader /> : displayContent}</Container>
			</PageWrapper>

			{currentModal ? (
				<Modal
					isOpen={!!currentModalkey}
					size='sm'
					setIsOpen={() => setCurrentModalKey(null)}
					title={currentModal.title}
					footer={
						<>
							<Button
								variant='outline'
								color='zinc'
								className='w-full'
								onClick={() => setCurrentModalKey(null)}>
								Fermer
							</Button>

							<Button
								variant='solid'
								className='w-full'
								isLoading={isExecutingModalAction}
								onClick={() => currentModal.actionBtnHandler()}>
								{currentModal.actionBtnLabel}
							</Button>
						</>
					}>
					{currentModal.content}
				</Modal>
			) : null}
			<TestimonialFeedback
				isOpen={feedbackModalStatus}
				setIsOpen={setFeedbackModalStatus}
				message='Nous aimerions avoir votre avis sur le traitement des commandes'
			/>
		</>
	);
};

export default DeliveryCompanyManageOrderPage;
