import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterOption } from '../../../types/common/filters.type';
import Checkbox from '../../form/Checkbox';
import Alert from '../Alert';
import Badge from '../Badge';
import Button from '../Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../Dropdown';
import FilterActionButtons from './FilterActionButtons';

type Props = {
	filter: FilterOption;
};

const SelectableOptionsFilter = ({ filter }: Props) => {
	const { label, options, filterKey } = filter;
	const [routeParams] = useSearchParams();

	const activeValues = routeParams.getAll(filterKey);
	const [selected, setSelected] = useState<string[]>(activeValues);

	const [openDropdown, setOpenDropdown] = useState<boolean>(false);

	const toggleCheckbox = (value: string) => {
		setSelected((prevSelected) =>
			prevSelected.includes(value)
				? prevSelected.filter((item) => item !== value)
				: [...prevSelected, value],
		);
	};

	const toggleDropdown = () => setOpenDropdown((prev) => !prev);
	const onCancel = useCallback(() => {
		setSelected([]);
		setOpenDropdown(false);
	}, [setSelected, setOpenDropdown]);

	return (
		<Dropdown setIsOpen={setOpenDropdown} isOpen={openDropdown}>
			<DropdownToggle>
				<Button className='text-base py-3 text-nowrap' variant='outline' color='zinc'>
					{label}
					{activeValues.length ? (
						<Badge className='ml-2 h-5 w-5' variant='solid'>
							{activeValues.length}
						</Badge>
					) : null}
				</Button>
			</DropdownToggle>
			<DropdownMenu className='p-5' isCloseAfterLeave={false}>
				<div className='flex justify-end'>
					<Button className='pr-0' icon='HeroXMark' onClick={toggleDropdown} />
				</div>
				{options?.length ? (
					<>
						<div className='overflow-y-auto min-w-80 space-y-3 max-h-96'>
							{options.map((i) => (
								<Checkbox
									key={i.value}
									label={i.label}
									checked={selected.includes(i.value)}
									onChange={() => toggleCheckbox(i.value)}
								/>
							))}
						</div>
						<FilterActionButtons
							filterKey={filterKey}
							value={selected}
							onFilter={() => setOpenDropdown(false)}
							onCancel={onCancel}
						/>
					</>
				) : (
					<Alert
						className='border-transparent'
						color='amber'
						icon='HeroInformationCircle'
						title={label}
						variant='outline'>
						Pas de donées disponible pour ce filtre
					</Alert>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

export default SelectableOptionsFilter;
