import { useState } from 'react';
import { useDispatch } from 'react-redux';
import TestimonialFeedback from '../../../components/common/TestimonialFeedback';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { customerPages } from '../../../config/pages.config';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useOrderStore from '../../../hooks/useOrderStore';
import useCustomToast from '../../../hooks/useToast';
import CartItemCard from './CartItemCard';

interface IOrderResponse {
	succeeded: boolean;
}

const Summary = () => {
	const [feedbackModalStatus, setFeedbackModalStatus] = useState<boolean>(false);

	const { cartItems, cartLength } = useOrderStore();
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();
	const dispatch = useDispatch();
	const { resetCart } = useOrderStore();
	const [loading, setLoading] = useState(false);

	const submitCart = async () => {
		try {
			setLoading(true);
			const response = await axiosFetch<IOrderResponse>({
				method: 'POST',
				url: '/api/customer/order',
			});

			if (response?.succeeded) {
				customToast.success('Votre commande a été ajoutée avec succès');
				dispatch(resetCart());
				setFeedbackModalStatus(true);
			}
		} catch (error) {
			customToast.error('une erreur est survenue');
		} finally {
			setLoading(false);
		}
	};

	const cartHeader = (
		<div className='flex justify-between items-center text-sm font-bold p-3 '>
			<span>Nombre de produits: {cartLength}</span>
			<Button
				className='text-sm'
				variant='solid'
				onClick={submitCart}
				isLoading={loading}
				isDisable={loading}>
				Soumettre mon panier
			</Button>
		</div>
	);

	return (
		<>
			<Card>
				{cartHeader}
				<div>
					{cartItems.map((p) => (
						<CartItemCard item={p} key={p.link} />
					))}
				</div>
				<div className='border-t border-gray-300 '> </div>
				{cartHeader}
			</Card>
			<TestimonialFeedback
				redirectionRoute={customerPages.orders.to}
				isOpen={feedbackModalStatus}
				setIsOpen={setFeedbackModalStatus}
				message='Votre commande a été ajoutée avec succès.
				Nous aimerions avoir votre avis sur l’ajout de  commande.'
			/>
		</>
	);
};

export default Summary;
