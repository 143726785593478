import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import Modal from '../../../components/ui/Modal';
import { customerPages } from '../../../config/pages.config';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useOrderStore from '../../../hooks/useOrderStore';
import useCustomToast from '../../../hooks/useToast';
import { OrderStatus } from '../../../types/shared-enums';

type Props = {
	orderId: number;
	orderStatus: OrderStatus;
	showView?: boolean;
};

const OrderAction = ({ orderId, orderStatus, showView }: Props) => {
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { deteteCustomerOrder, cancelCustomerOrder } = useOrderStore();

	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [cancelModalStatus, setCancelModalStatus] = useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

	const cancelOrder = async () => {
		try {
			const response = await axiosFetch<{ succeeded: boolean }>({
				method: 'PUT',
				url: `/api/customer/orders/${orderId}/cancel`,
			});
			if (response?.succeeded) {
				customToast.success('Commande annulée avec succès');
				dispatch(cancelCustomerOrder(orderId));
				setCancelModalStatus(false);
			}
			setCancelModalStatus(false);
		} catch (error) {
			customToast.error('une erreur est survenue');
		}
	};

	const openDetailsPage = useCallback(() => {
		navigate(generatePath(customerPages.order.to, { orderId }));
	}, [orderId, navigate]);

	const handleDeleteOrder = useCallback(async () => {
		try {
			const response = await axiosFetch<{ succeeded: boolean }>({
				method: 'DELETE',
				url: `/api/customer/orders/${orderId}`,
			});
			if (response?.succeeded) {
				customToast.success('Commande supprimée avec succès');
				dispatch(deteteCustomerOrder(orderId));

				setModalStatus(false);
				navigate(customerPages.orders.to);
			} else {
				customToast.error('Une erreur est survenue. Veuillez réessayer');
			}
		} catch (error) {
			customToast.error('une erreur est survenue');
		}
	}, [
		axiosFetch,
		setModalStatus,
		dropdownOpen,
		navigate,
		deteteCustomerOrder,
		dispatch,
		orderId,
		customToast,
	]);

	return (
		<>
			<Dropdown isOpen={dropdownOpen} setIsOpen={setDropdownOpen}>
				<DropdownToggle hasIcon={false}>
					<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
				</DropdownToggle>
				<DropdownMenu placement='bottom-end'>
					{showView ? (
						<DropdownItem icon='HeroEye' onClick={openDetailsPage}>
							Voir
						</DropdownItem>
					) : null}

					{orderStatus === OrderStatus.waitingForPayment ? (
						<DropdownItem
							icon='HeroXMark'
							onClick={() => {
								setCancelModalStatus(true);
								setDropdownOpen(!dropdownOpen);
							}}>
							Annuler
						</DropdownItem>
					) : null}

					{orderStatus === OrderStatus.cancelled ? (
						<DropdownItem
							icon='HeroTrash'
							color='red'
							colorIntensity='500'
							className='border-t-2 hover:text-red-500'
							onClick={() => {
								setModalStatus(true);
								setDropdownOpen(!dropdownOpen);
							}}>
							Supprimer
						</DropdownItem>
					) : null}
				</DropdownMenu>
			</Dropdown>

			<Modal
				isOpen={modalStatus}
				setIsOpen={setModalStatus}
				title='Suppression de commande'
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={() => setModalStatus(false)}>
							Non
						</Button>
						<Button
							className='w-full'
							color='red'
							variant='solid'
							onClick={handleDeleteOrder}>
							oui
						</Button>
					</>
				}>
				Voulez-vous vraiment supprimer cette commande ?
			</Modal>

			<Modal
				isOpen={cancelModalStatus}
				setIsOpen={setCancelModalStatus}
				title='Annuler une commande'
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={() => setCancelModalStatus(false)}>
							Non
						</Button>
						<Button className='w-full' variant='solid' onClick={cancelOrder}>
							oui
						</Button>
					</>
				}>
				<div className='flex flex-col items-center'>
					Voulez-vous vraiment annuler cette commande ?
				</div>
			</Modal>
		</>
	);
};

OrderAction.defaultProps = {
	showView: true,
};

export default OrderAction;
