import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useAxiosFunction from '../../hooks/useAxiosFunction';
import useCustomToast from '../../hooks/useToast';
import FieldWrap from '../form/FieldWrap';
import Textarea from '../form/Textarea';
import Validation from '../form/Validation';
import Button from '../ui/Button';
import Modal from '../ui/Modal';
import Rating from './Rating';

type TValues = {
	description: string | null;
};

type Props = {
	message?: string;
	redirectionRoute?: string;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const TestimonialFeedback = ({ isOpen, setIsOpen, redirectionRoute, message }: Props) => {
	const [value, setValue] = useState<number | null>(null);

	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();
	const navigate = useNavigate();

	const feedbackSchema = Yup.object().shape({
		description: Yup.string(),
	});

	const formik = useFormik({
		initialValues: {
			description: '',
		},
		validationSchema: feedbackSchema,
		onSubmit: async (values: TValues, { setSubmitting }) => {
			try {
				const response = await axiosFetch<{ succeeded: boolean }>({
					method: 'POST',
					url: '/api/user/feedback',
					requestBody: {
						rating: value,
						content: values.description,
					},
				});

				if (response?.succeeded) {
					customToast.success('Votre avis a été bien envoyé. Merci!');
					formik.resetForm();
					if (redirectionRoute) {
						navigate(redirectionRoute);
					}
					setIsOpen(false);
				}
			} catch (e: any) {
				customToast.error('Une erreur est survenue');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const closeModal = () => {
		setIsOpen(false);
		if (redirectionRoute) {
			navigate(redirectionRoute);
		}
	};

	return (
		<div>
			<Modal
				isOpen={isOpen}
				size='sm'
				setIsOpen={setIsOpen}
				title='Votre avis'
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={closeModal}>
							Fermer
						</Button>

						<Button
							variant='solid'
							className='w-full'
							onClick={() => formik.handleSubmit()}>
							Soumettre
						</Button>
					</>
				}>
				<div className='flex flex-col space-y-3'>
					{message ? <span className='text-center'> {message} </span> : null}
					<Rating value={value} setValue={setValue} />

					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.description}
						invalidFeedback={formik.errors.description}>
						<FieldWrap>
							<Textarea
								id='description'
								autoComplete='description'
								className='bg-zinc-500/10 p-6 text-base'
								name='description'
								placeholder='Laissez un commentaire'
								value={formik.values.description ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								rows={6}
							/>
						</FieldWrap>
					</Validation>
				</div>
			</Modal>
		</div>
	);
};

TestimonialFeedback.defaultProps = {
	redirectionRoute: undefined,
	message: undefined,
};

export default TestimonialFeedback;
