import { configureStore } from '@reduxjs/toolkit';
// import customerOrderReducer from './customerOrderSlice';
import orderReducer from './orderSlice';
import userReducer from './userSlice';

const store = configureStore({
	reducer: {
		user: userReducer,
		order: orderReducer,
		// customerOrder: customerOrderReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
