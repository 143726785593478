import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

type ValueType = string | Date | undefined;
type Props = {
	filterKey: string;
	value: ValueType | ValueType[];
	onFilter: () => void;
	onCancel: () => void;
};

const FilterActionButtons = ({ filterKey, value, onFilter, onCancel }: Props) => {
	const navigate = useNavigate();

	const { search, pathname } = window.location;

	const filter = useCallback(() => {
		const updatedSearchParams = new URLSearchParams(search);

		if (typeof value === 'string' || value instanceof Date) {
			updatedSearchParams.set(filterKey, String(value));
		} else if (value === undefined || !value.length) {
			updatedSearchParams.delete(filterKey);
		} else {
			updatedSearchParams.delete(filterKey);
			value.filter(Boolean).forEach((v) => updatedSearchParams.append(filterKey, String(v)));
		}

		navigate(`${pathname}?${updatedSearchParams.toString()}`, { replace: true });

		onFilter();
	}, [filterKey, value, onFilter, navigate, search, pathname]);

	const reset = useCallback(() => {
		const updatedSearchParams = new URLSearchParams(search);

		updatedSearchParams.delete(filterKey);
		navigate(`${pathname}?${updatedSearchParams.toString()}`, { replace: true });

		onCancel();
	}, [filterKey, onCancel, pathname, navigate, search]);

	return (
		<div className='flex space-x-6 mt-7 justify-between'>
			<Button variant='outline' className='w-full whitespace-nowrap' onClick={reset}>
				Annuler
			</Button>
			<Button variant='solid' className='w-full' onClick={filter}>
				Filtrer
			</Button>
		</div>
	);
};

export default FilterActionButtons;
