import { OrOrderItem } from '@devschap/order-point-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import OpenInstruction from '../../../components/OpenInstruction';
import ProductImg from '../../../components/ProductImg';
import Badge from '../../../components/ui/Badge';
import BadgeCell from '../../../components/ui/BadgeCell';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import CurrencyCell from '../../../components/ui/CurrencyCell';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/ui/OffCanvas';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { limitText } from '../../../utils/ui.util';
import AdminManageOrderItemForm from './AdminManageOrderItemForm';

const cols: DatagridColumnDef[] = [
	{
		field: 'image',
		label: 'Image',
		hideOnMobile: true,
	},
	{
		field: 'name',
		label: 'Nom',
		children: ['status'],
	},

	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'quantity',
		label: 'Qt',
		children: ['unitPrice', 'amount'],
	},
	{
		field: 'unitPrice',
		label: 'PU',
	},
	{
		field: 'amount',
		label: 'Montant',
	},
];
type Props = {
	orderId: number;
	canBeUpdated: boolean;
};
const AdminManageOrderItems = ({ orderId, canBeUpdated }: Props) => {
	const [products, setProducts] = useState<OrOrderItem[]>([]);
	const [currentItem, setCurrentItem] = useState<OrOrderItem | null>(null);
	const [open, setOpen] = useState<boolean>(false);
	const customToast = useCustomToast();

	const { axiosFetch, loading } = useAxiosFunction();

	const getOrderItems = useCallback(async () => {
		if (!orderId) return;

		try {
			const response = await axiosFetch<{ products: OrOrderItem[] }>({
				method: 'GET',
				url: `/api/admin/orders/${orderId}/products`,
			});
			setProducts(response?.products ?? []);
		} catch (error) {
			customToast.error("Vous n'avez aucune commande");
		}
	}, [orderId, setProducts, customToast, axiosFetch]);

	useEffect(() => {
		getOrderItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openDrawer = useCallback(
		(itemId: number) => {
			setCurrentItem(products.find((x) => x.id === itemId) ?? null);
			setOpen(true);
		},
		[setCurrentItem, setOpen, products],
	);

	const refreshProducts = useCallback(
		(refresh: boolean) => {
			if (!refresh) return;

			setCurrentItem(null);
			setOpen(false);
			getOrderItems();
		},
		[setOpen, getOrderItems, setCurrentItem],
	);

	const columns = canBeUpdated
		? [
				...cols,
				{
					field: 'actions',
					label: 'Actions',
				},
		  ]
		: cols;

	const rows = useMemo(() => {
		return products.map((p) => ({
			image: (
				<ProductImg pictureUrl={p.pictureUrl} productName={p.productName ?? p.pictureUrl} />
			),

			name: (
				<div>
					{limitText(p.productName ?? p.productUrl, 100)}
					{p.instructions ? <OpenInstruction instructions={p.instructions} /> : null}
				</div>
			),
			status: p.processed ? (
				<span className='flex space-x-2'>
					<BadgeCell value={p.accepted} label={p.accepted ? 'Accepté' : 'Rejeté'} />

					{p.accepted ? (
						<BadgeCell
							value={p.inStock}
							label={p.inStock ? 'En stock' : 'Rupture de stock'}
						/>
					) : null}
				</span>
			) : null,
			quantity: <span>x{p.quantity}</span>,
			unitPrice: <CurrencyCell value={p.originalPrice} currency={p.originalCurrency} />,
			amount: (
				<div className='border-t pt-1 lg:border-0 lg:pt-0 font-semibold'>
					<CurrencyCell value={p.convertedPrice} />
				</div>
			),

			actions: canBeUpdated ? (
				<Button
					variant='outline'
					color='zinc'
					icon='HeroPencil'
					onClick={() => openDrawer(p.id)}
				/>
			) : null,
		}));
	}, [canBeUpdated, products, openDrawer]);

	return (
		<>
			<Card
				header={
					<>
						Produits
						{products.length ? (
							<Badge className='ml-2 border-transparent text-sm' variant='outline'>
								{products.length}
							</Badge>
						) : null}
					</>
				}
				bodyClassName='overflow-x-scroll'>
				{loading ? (
					<OpLoader />
				) : (
					<DataGrid keyPrefix='product' columns={columns} rows={rows} />
				)}
			</Card>

			{currentItem ? (
				<OffCanvas isOpen={open} setIsOpen={setOpen}>
					<OffCanvasHeader className='cursor-pointer'>
						Modifier le produit
					</OffCanvasHeader>
					<OffCanvasBody>
						<AdminManageOrderItemForm item={currentItem} onClose={refreshProducts} />
					</OffCanvasBody>
				</OffCanvas>
			) : null}
		</>
	);
};

export default AdminManageOrderItems;
