import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { userActions } from '../store/userSlice';
import { AccountRole } from '../types/shared-enums';

const useUserStore = () => {
	const state = useSelector((st: RootState) => st.user);

	const displayName = useMemo(() => {
		switch (state.userType) {
			case AccountRole.customer:
				if (!state.customer) throw new Error(`Missing customer data in the store`);

				return `${state.customer.firstName} ${state.customer.lastName}`;

			case AccountRole.deliveryCompany:
				if (!state.deliveryService)
					throw new Error(`Missing delivery service data in the store`);

				return state.deliveryService.label;

			case AccountRole.merchant:
				if (!state.merchant) throw new Error(`Missing merchant data in the store`);

				return state.merchant.name;

			case AccountRole.admin:
				if (!state.admin) throw new Error(`Missing admin data in the store`);

				return `${state.admin.firstName} ${state.admin.lastName}`;

			default:
				return null;
		}
	}, [state.customer, state.deliveryService, state.merchant, state.admin, state.userType]);

	return {
		state,
		deliveryAddresses: state.customer?.deliveryAddresses ?? [],
		displayName,
		isAnonymous: state.userType === AccountRole.anonymous,
		isCustomer: state.userType === AccountRole.customer,
		isAdmin: state.userType === AccountRole.admin,
		isDeliveryCompany: state.userType === AccountRole.deliveryCompany,
		loggedIn: !!state.email,
		...userActions,
	};
};

export default useUserStore;
