import constants from '../pages/customer/constants';
import { FeedbackType } from '../types/shared-enums';
import Badge from './ui/Badge';

type Props = {
	feedback: FeedbackType;
};

const FeedbackTypeBadge = ({ feedback }: Props) => {
	const { label, color } = constants.FEEDBACKTYPE_MAPS[feedback] ?? {
		label: feedback,
		color: 'blue',
	};

	return (
		<Badge className='whitespace-nowrap border-transparent' variant='solid' color={color}>
			{label}
		</Badge>
	);
};

export default FeedbackTypeBadge;
