import Badge from './Badge';

type Props = {
	value: boolean;
	label: string;
};

const BadgeCell = ({ value, label }: Props) => {
	return (
		<Badge
			className='whitespace-nowrap border-transparent text-sm'
			variant='solid'
			color={value ? 'emerald' : 'red'}>
			{label}
		</Badge>
	);
};

export default BadgeCell;
