import { ReactNode } from 'react';
import { DeliveryMan5WithDog } from '../../assets/images';
import Container from '../layouts/Container/Container';

type Props = {
	message: ReactNode;
};

const EmptyContent = ({ message }: Props) => {
	return (
		<Container className='flex flex-col space-y-8 items-center justify-center'>
			<img src={DeliveryMan5WithDog as string} alt='contenu-vide' className='max-h-[16rem]' />
			<span className=' text-base lg:text-xl text-zinc-500 text-center'>{message}</span>
		</Container>
	);
};

export default EmptyContent;
