import { useState } from 'react';
import { FilterOption } from '../../../types/common/filters.type';
import Button from '../Button';
import Card from '../Card';
import Drawer from '../Drawer';
import DateFilter from './DateFilter';
import FilterSearchInput from './FilterSearchInput';
import SelectableOptionsFilter from './SelectableOptionsFilter';

type DataGridFilterProps = {
	filter: FilterOption;
};

type Props = {
	filters: FilterOption[];
};

const DataGridFilter = ({ filter }: DataGridFilterProps) => {
	switch (filter.type) {
		case 'dateRange':
			return <DateFilter filter={filter} />;
		case 'multiple':
		case 'single':
			return <SelectableOptionsFilter filter={filter} />;

		default:
			return undefined;
	}
};

const DataGridFilters = ({ filters }: Props) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	return (
		<Card className='my-5'>
			<div className='flex space-x-8 items-center'>
				<FilterSearchInput />
				{/* Desktop filters */}
				<div className='hidden lg:flex items-center space-x-4'>
					{filters.map((filter) => (
						<DataGridFilter key={filter.filterKey} filter={filter} />
					))}
				</div>
				{/* Desktop filters end */}
				{/* Mobile filters */}
				<div className='block lg:hidden flex-shrink-0'>
					<Button
						icon='HeroFunnel'
						variant='outline'
						color='zinc'
						size='sm'
						className='py-3 text-nowrap'
						onClick={() => setIsDrawerOpen(true)}>
						Filtres
					</Button>
					<Drawer title='Filtres' isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
						<div className='flex flex-col space-y-2'>
							{filters.map((filter) => (
								<DataGridFilter key={filter.filterKey} filter={filter} />
							))}
						</div>
					</Drawer>
				</div>
				{/* Mobile filters end */}
			</div>
		</Card>
	);
};

export default DataGridFilters;
