import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CoAddress, CuDeliveryAddress } from '@devschap/order-point-types';
import { AccountProvider, AccountRole } from '../types/shared-enums';

export interface IUserState {
	[x: string]: any;
	accountId: number | null;
	email: string | null;
	userType: AccountRole;
	profileImgUrl: string | null;
	provider: AccountProvider;
	verified: boolean;
	actived: boolean;
	telephone1: string | null;
	telephone2: string | null;
	address: CoAddress | null;
	customer: {
		id: number;
		firstName: string;
		lastName: string;
		dcReferralCode: string | null;
		updatedAt?: string;
		deliveryAddresses?: CuDeliveryAddress[];
	} | null;
	deliveryService: {
		id: number;
		label: string;
		ifu: string;
		pageFacebook: string | null;
		pageTiktok: string | null;
		pageInstagram: string | null;
		webSiteUrl: string | null;
		referralCode: string | null;
		actived: boolean;
	} | null;
	merchant: {
		id: number;
		name: string;
		categories: string[];
	} | null;
	admin: {
		id: number;
		firstName: string;
		lastName: string;
		updatedAt?: string;
	} | null;
}

export type AccountData = Pick<
	IUserState,
	'accountId' | 'email' | 'verified' | 'provider' | 'actived' | 'userType'
>;

export type CustomerData = Pick<IUserState, 'customer' | 'address' | 'telephone1' | 'telephone2'>;

export type DeliveryServiceData = Pick<
	IUserState,
	'deliveryService' | 'address' | 'telephone1' | 'telephone2'
>;
export type MerchantData = Pick<IUserState, 'merchant' | 'address' | 'telephone1' | 'telephone2'>;
export type AdminData = Pick<IUserState, 'admin' | 'address' | 'telephone1' | 'telephone2'>;

const initialState: IUserState = {
	accountId: null,
	email: null,
	profileImgUrl: null,
	provider: AccountProvider.local,
	verified: false,
	actived: false,
	telephone1: null,
	telephone2: null,
	address: null,
	userType: AccountRole.anonymous,
	customer: null,
	merchant: null,
	deliveryService: null,
	admin: null,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		// setUser: (state, action: PayloadAction<IUserState>) => {
		// 	return { ...state, ...action.payload };
		// },
		setAccountData: (state, action: PayloadAction<AccountData>) => {
			return { ...state, ...action.payload, userType: action.payload.userType };
		},
		setEmail: (state, action: PayloadAction<string>) => {
			return { ...state, email: action.payload };
		},
		setCustomerData: (state, action: PayloadAction<CustomerData>) => {
			return { ...state, ...action.payload, userType: AccountRole.customer };
		},
		setDeliveryServiceData: (state, action: PayloadAction<DeliveryServiceData>) => {
			return { ...state, ...action.payload, userType: AccountRole.deliveryCompany };
		},
		setMerchantData: (state, action: PayloadAction<MerchantData>) => {
			return { ...state, ...action.payload, userType: AccountRole.merchant };
		},
		setAdminData: (state, action: PayloadAction<AdminData>) => {
			return { ...state, ...action.payload, userType: AccountRole.admin };
		},
		setDeliveryAddresses: (state, action: PayloadAction<CuDeliveryAddress[]>) => {
			if (state.customer === null) {
				throw new Error('Missing customer in the store');
			}
			return { ...state, customer: { ...state.customer, deliveryAddresses: action.payload } };
		},

		addDeliveryAddress: (state, action: PayloadAction<CuDeliveryAddress>) => {
			if (state.customer === null) {
				throw new Error('Missing customer in the store');
			}
			const updatedAddresses = state.customer.deliveryAddresses
				? [...state.customer.deliveryAddresses, action.payload]
				: [action.payload];

			return {
				...state,
				customer: { ...state.customer, deliveryAddresses: updatedAddresses },
			};
		},
		editDeliveryAddress: (state, action: PayloadAction<CuDeliveryAddress>) => {
			if (state.customer === null || !state.customer.deliveryAddresses) {
				throw new Error('Missing customer or delivery addresses in the store');
			}
			const updatedAddresses = state.customer.deliveryAddresses.map((address) =>
				address.id === action.payload.id ? action.payload : address,
			);

			return {
				...state,
				customer: { ...state.customer, deliveryAddresses: updatedAddresses },
			};
		},
		deleteDeliveryAddress: (state, action: PayloadAction<CuDeliveryAddress>) => {
			if (state.customer === null || !state.customer.deliveryAddresses) {
				throw new Error('Missing customer or delivery addresses in the store');
			}

			const updatedAddresses = state.customer.deliveryAddresses.filter(
				(address) => address.id !== action.payload.id,
			);
			return {
				...state,
				customer: { ...state.customer, deliveryAddresses: updatedAddresses },
			};
		},
		setPreferred: (state, action: PayloadAction<CuDeliveryAddress>) => {
			if (state.customer === null || !state.customer.deliveryAddresses) {
				throw new Error('Missing customer or delivery addresses in the store');
			}
			// const { id } = action.payload;

			const updatedAddresses = state.customer.deliveryAddresses.map((address) =>
				address.id === action.payload.id
					? { ...address, preferred: true }
					: { ...address, preferred: false },
			);

			return {
				...state,
				customer: { ...state.customer, deliveryAddresses: updatedAddresses },
			};
		},
		resetUser: () => {
			return initialState;
		},
	},
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
