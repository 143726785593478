import { DcDeliveryCompanyMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useCustomToast from '../../../hooks/useToast';

import {
	FETCH_DATA_COMMON_HOOKS_DEFAULT_PROPS,
	LIMIT_PER_PAGE,
} from '../../../constants/datagrid.constants';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import { FetchDataCommonHooksProps } from '../../../types/api';

const useFetchAdminDeliveryCompanies = ({
	useQueryParams,
}: FetchDataCommonHooksProps = FETCH_DATA_COMMON_HOOKS_DEFAULT_PROPS) => {
	const { axiosFetch, loading } = useAxiosFunction();
	const [deliveryCompanies, setDeliveryCompanies] = useState<DcDeliveryCompanyMinimal[]>([]);
	const customToast = useCustomToast();

	const [offset, setOffset] = useState(0);
	const [showLoadMore, setShowLoadMore] = useState(true);

	const [filters, setFilters] = useState<Record<string, any>>({});
	const [searchParams, _] = useSearchParams();

	const loadMore = useCallback(() => {
		setOffset((prev) => prev + LIMIT_PER_PAGE);
	}, [setOffset]);

	const getDeliveryCompanies = async () => {
		if (loading || (!useQueryParams && deliveryCompanies.length)) return;

		try {
			const response = await axiosFetch<{ deliveryCompanies: DcDeliveryCompanyMinimal[] }>({
				method: 'GET',
				url: '/api/admin/delivery-companies',
				queryParams: useQueryParams
					? {
							offset,
							limit: LIMIT_PER_PAGE,
							...filters,
					  }
					: undefined,
			});

			const newData = response?.deliveryCompanies ?? [];
			if (!useQueryParams) {
				setDeliveryCompanies(newData);
				return;
			}

			if (offset === 0) setDeliveryCompanies(newData);
			else setDeliveryCompanies((prev) => [...prev, ...newData]);

			setShowLoadMore(Boolean(newData.length));
		} catch (error) {
			customToast.error(
				"Une erreur s'est produite lors de la récupération des services de livraison",
			);
		}
	};

	useEffect(() => {
		getDeliveryCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset, filters]);

	useEffect(() => {
		setFilters({
			date: searchParams.getAll('date'),
			country_ids: searchParams.getAll('country_ids'),
		});
		setOffset(0);
	}, [searchParams]);

	return {
		loading,
		deliveryCompanies,
		showLoadMore,
		loadMore: showLoadMore ? loadMore : undefined,
		isLoadingMore: offset > 0 && loading,
	};
};

export default useFetchAdminDeliveryCompanies;
