import { CuCustomerMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LIMIT_PER_PAGE } from '../../../constants/datagrid.constants';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';

const useDeliveryCompanyCustomers = () => {
	const [customers, setCustomers] = useState<CuCustomerMinimal[]>([]);
	const [offset, setOffset] = useState(0);
	const [showLoadMore, setShowLoadMore] = useState(true);
	const [filters, setFilters] = useState<Record<string, any>>({});
	const [searchParams, _] = useSearchParams();

	const customToast = useCustomToast();
	const { axiosFetch, loading } = useAxiosFunction();

	const loadMore = useCallback(() => {
		setOffset((prev) => prev + LIMIT_PER_PAGE);
	}, [setOffset]);

	const getCustomers = useCallback(async () => {
		if (loading) return;

		try {
			const response = await axiosFetch<{ customers: CuCustomerMinimal[] }>({
				method: 'GET',
				url: '/api/delivery-company/customers',
				queryParams: {
					offset,
					limit: LIMIT_PER_PAGE,
					...filters,
				},
			});

			const newData = response?.customers ?? [];

			if (offset === 0) {
				setCustomers(newData);
			} else {
				setCustomers((prev) => [...prev, ...newData]);
			}

			setShowLoadMore(Boolean(newData.length));
		} catch (error) {
			customToast.error('Une erreur est survenue. Veuillez rafraichir la page');
		}
	}, [setCustomers, axiosFetch, customToast, loading, offset, filters]);

	useEffect(() => {
		getCustomers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset, filters]);

	useEffect(() => {
		setFilters({
			statuses: searchParams.getAll('statuses'),
			country_ids: searchParams.getAll('country_ids'),
			date: searchParams.getAll('date'),
		});
		setOffset(0);
	}, [searchParams]);

	return {
		loading,
		customers,
		showLoadMore,
		loadMore: showLoadMore ? loadMore : undefined,
		isLoadingMore: offset > 0 && loading,
	};
};

export default useDeliveryCompanyCustomers;
