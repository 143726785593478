import classNames from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

type Props = {
	path?: string;
	text?: string;
	showBorder?: boolean;
	icon?: string;
	rightIcon?: boolean;
	className?: string;
};

const ButtonLink: FC<Props> = ({ path, text, showBorder, icon, rightIcon, className }) => {
	const navigate = useNavigate();

	const handleBtnClick = () => {
		if (path) {
			navigate(path);
		} else {
			navigate(-1);
		}
	};
	return (
		<div
			className={classNames('w-fit', showBorder && 'border py-2 px-4 rounded-xl', className)}>
			<Button
				color='sky'
				icon={rightIcon ? undefined : icon ?? 'HeroArrowLeft'}
				rightIcon={rightIcon ? icon ?? 'HeroArrowRight' : undefined}
				onClick={handleBtnClick}
				className='text-xs w-[inherit] !px-0'>
				{text}
			</Button>
		</div>
	);
};

ButtonLink.defaultProps = {
	path: undefined,
	text: 'Retour',
	showBorder: false,
	icon: undefined,
	rightIcon: false,
	className: undefined,
};

export default ButtonLink;
