import CommonHeaderPartial from '../_partial/CommonHeader.partial';
import NotificationPartial from '../_partial/Notification.partial';

const DefaultHeaderRightCommon = () => {
	return (
		<>
			<NotificationPartial />
			{/* <SettingsPartial /> */}
			<CommonHeaderPartial />
		</>
	);
};

export default DefaultHeaderRightCommon;
