export const customerPages = {
	orders: {
		id: 'orders',
		to: '/c/orders',
		text: 'Mes commandes',
		icon: 'HeroShoppingCart',
	},
	order: {
		id: 'orderDetailsPage',
		to: '/c/orders/:orderId',
		text: 'Détails de commande',
		icon: 'HeroPlusCircle',
	},
	newOrder: {
		id: 'newOrder',
		to: '/c/new-order',
		text: 'Nouvelle commande',
		icon: 'HeroPlusCircle',
	},
	checkout: {
		id: 'checkout',
		to: '/c/checkout',
		text: 'Verification',
		icon: 'HeroShoppingBag',
	},
	profile: {
		id: 'profilePage',
		to: '/c/profile',
		text: 'Profil',
		icon: 'HeroUser',
	},
	feedback: {
		id: 'feedbackPage',
		to: '/c/feedback',
		text: 'feedback',
		icon: 'HeroUser',
	},
};

export const deliveryCompanyPages = {
	orders: {
		id: 'orders',
		to: '/dc/orders',
		text: 'Commandes',
		icon: 'HeroShoppingCart',
	},
	order: {
		id: 'orderDetailsPage',
		to: '/dc/orders/:orderId',
		text: 'Détails de commande',
	},
	customers: {
		id: 'customers',
		to: '/dc/customers',
		text: 'Clients',
		icon: 'HeroUserGroup',
	},
	customer: {
		id: 'customerDetailsPage',
		to: '/dc/customers/:customerId',
		text: 'Client',
	},
	profile: {
		id: 'profilePage',
		to: '/dc/profile',
		text: 'Profil',
		icon: 'HeroUser',
	},
	settings: {
		id: 'settings',
		to: '/dc/settings',
		text: 'Paramètres',
		icon: 'HeroAdjustmentsHorizontal',
	},
};

export const adminPages = {
	orders: {
		id: 'orders',
		to: '/a/orders',
		text: 'Commandes',
		icon: 'HeroShoppingCart',
	},
	order: {
		id: 'orderDetailsPage',
		to: '/a/orders/:orderId',
		text: 'Détails de commande',
	},
	customers: {
		id: 'customers',
		to: '/a/customers',
		text: 'Clients',
		icon: 'HeroUserGroup',
	},
	customer: {
		id: 'customerDetailsPage',
		to: '/a/customers/:customerId',
		text: 'Client',
	},
	deliveryCompanies: {
		id: 'deliveryCompanies',
		to: '/a/delivery-companies',
		text: 'Services de livraison',
		icon: 'HeroTruck',
	},
	deliveryCompany: {
		id: 'deliveryCompany',
		to: '/a/delivery-companies/:companyId',
		text: 'Service de livraison',
	},
	settings: {
		id: 'settings',
		to: '/a/settings',
		text: 'Paramètres',
		icon: 'HeroAdjustmentsHorizontal',
	},
	feedbacks: {
		id: 'feedbacks',
		to: '/a/feedbacks',
		text: 'Commentaires',
		icon: 'HeroWrench',
	},
	profile: {
		id: 'profilePage',
		to: '/a/profile',
		text: 'Profil',
		icon: 'HeroUser',
	},
	mails: {
		id: 'profilePage',
		to: '/a/mails',
		text: 'Mails',
		icon: 'HeroEnvelope',
	},
	contactMessages: {
		id: 'profilePage',
		to: '/a/contact-messages',
		text: 'Messages',
		icon: 'HeroChatBubbleLeftRight',
	},
};

export const appPages = {
	dashboard: {
		id: 'dashboard',
		to: '/',
		text: 'Tableau de board',
		icon: 'HeroRectangleGroup',
	},
};

export const authPages = {
	loginPage: {
		id: 'loginPage',
		to: '/login',
		text: 'Login',
	},
	signupPage: {
		id: 'signupPage',
		to: '/signup',
		text: "S'inscrire",
	},
	forgotPasswordPage: {
		id: 'forgotPasswordPage',
		to: '/forgot-password',
		text: 'Récuperation de compte',
	},
	resetPasswordPage: {
		id: 'resetPasswordPage',
		to: '/reset-password',
		text: 'Mise à jour du mot de passe',
	},
	onboardingPage: {
		id: 'onboardingPage',
		to: '/onboarding',
		text: "Information d'utilisateur",
	},
};

const pagesConfig = {
	...authPages,
	privacyPolicyPage: {
		id: 'privacyPolicyPage',
		to: '/privacy-policy',
		text: 'Politique de confidentialité',
	},
	termsOfUsePage: {
		id: 'termsOfUsePage',
		to: '/terms-of-use',
		text: "Conditions d'utilisation",
	},
};

export default pagesConfig;
