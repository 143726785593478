import { Dispatch, SetStateAction } from 'react';
import Icon from '../icon/Icon';

const ratings = [1, 2, 3, 4, 5];

type Props = {
	value: number | null;
	setValue?: Dispatch<SetStateAction<number | null>>;
};

const Rating = ({ value, setValue }: Props) => {
	return (
		<div className='flex flex-row space-x-3 justify-center items-center'>
			{ratings.map((rating) => {
				return (
					<Icon
						size='text-4xl'
						key={`rating-${rating}`}
						onClick={() => setValue && setValue(rating)}
						icon='HeroStar'
						className={value && rating <= value ? 'text-amber-500' : ''}
					/>
				);
			})}
		</div>
	);
};

export default Rating;

Rating.defaultProps = {
	setValue: undefined,
};
