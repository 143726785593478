import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import ButtonLink from '../../../components/ui/ButtonLink';
import { adminPages } from '../../../config/pages.config';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import DeliveryCompanyDetails from '../components/AdminDeliveryCompanyDetails';
import DeliveryCompanyOrders from '../components/AdminDeliveryCompanyOrders';

const AdminDeliveryCompanyDetailsPage = () => {
	const { companyId } = useParams();

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb
						path='Pages / Informations sur le service  de livraison'
						currentPage='Informations sur le service de livraison'
					/>
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<Subheader>
				<SubheaderLeft>
					<ButtonLink
						path={adminPages.deliveryCompanies.to}
						text='Services de livraison'
					/>
				</SubheaderLeft>
			</Subheader>

			<PageWrapper name='Service de livraison'>
				<Container>
					{companyId ? (
						<div className='grid grid-cols-12 gap-4 mt-6'>
							<div className='col-span-12 sm:col-span-12 lg:col-span-4 space-y-4 '>
								<DeliveryCompanyDetails companyId={companyId} />
							</div>

							<div className='col-span-12 sm:col-span-12 lg:col-span-8'>
								<DeliveryCompanyOrders companyId={companyId} />
							</div>
						</div>
					) : (
						<span>Identifiant du service manquant</span>
					)}
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminDeliveryCompanyDetailsPage;
