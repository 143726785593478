import { OrOrderFees } from '@devschap/order-point-types';
import OpLoader from '../../templates/layouts/Loader/OpLoader.template';
import { priceFormat } from '../../utils/priceFormat.util';
import Card from '../ui/Card';
import ListTile from '../ui/ListTile';

type Props = {
	fees: OrOrderFees | null;
	loading: boolean;
};

const OrderBilling = ({ fees, loading }: Props) => {
	if (!fees) return null;

	return (
		<Card header='Facturation'>
			{loading ? (
				<OpLoader />
			) : (
				<>
					<ListTile
						label='Sous total'
						description='Le total des produits'
						value={priceFormat(fees.subTotal)}
					/>
					{fees.servicePrice ? (
						<ListTile label='Frais de service' value={priceFormat(fees.servicePrice)} />
					) : null}
					<ListTile
						label="Frais d'expédition"
						description="Facturé par le service d'expédition"
						value={priceFormat(fees.deliveryPrice ?? 0)}
					/>
					<ListTile label='Taxe' value={priceFormat(fees.tax ?? 0)} />
					<ListTile
						label='Total'
						// description="Sous total + Frais de service + Frais d'expédition + Frais de livraison"
						description='Le cummul des frais ci-dessous'
						value={priceFormat(fees.total ?? 0)}
					/>
				</>
			)}
		</Card>
	);
};

export default OrderBilling;
