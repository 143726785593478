import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

type Props = Omit<SkeletonProps, 'height' | 'width' | 'circle'> & {
	size?: number;
};

const CircleSkeleton = ({ size, ...other }: Props) => {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Skeleton {...other} height={size} width={size} circle />;
};

CircleSkeleton.defaultProps = {
	size: 60,
};

export default CircleSkeleton;
