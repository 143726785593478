import { CuCartItem } from '@devschap/order-point-types';
import { DeliveryMan1 } from '../../../assets/images';
import ProductQuantitybadge from '../../../components/ProductQuantitybadge';
import ExternalLink from '../../../components/ui/ExternalLink';
import ReadMore from '../../../components/ui/ReadMore';
import { limitText } from '../../../utils/ui.util';

interface Props {
	item: CuCartItem;
}

const CartItemCard = ({ item }: Props) => {
	const { configuration, imageUrl, link, name, quantity, instructions } = item;

	return (
		<div className='border-t border-gray-300 py-4'>
			<div className='flex gap-4 mb-2 md:gap-8 '>
				{/* eslint-disable-next-line react/self-closing-comp */}
				<div
					className='w-20 h-20 md:w-32 md:h-32 flex-shrink-0 relative bg-contain bg-center bg-no-repeat'
					style={{ backgroundImage: `url(${imageUrl ?? DeliveryMan1})` }}></div>

				<div className='w-full flex flex-col space-y-2'>
					<div className='text-sm md:text-base font-normal break-all'>
						{limitText(name ?? link, 80)}
					</div>
					<div className='text-sm md:text-base'>
						{configuration.map((c, index) => (
							<span key={c.label} className=''>
								<span className='text-sm text-gray-400'>{c.label}</span>
								<span className='ml-1'>{c.value}</span>
								{index !== configuration.length - 1 ? ' - ' : null}
							</span>
						))}
					</div>
					<div className='w-full !mb-3 flex justify-between items-center'>
						<ProductQuantitybadge quantity={quantity} />
						<ExternalLink url={link} />
					</div>
					{instructions ? (
						<div className='flex flex-col space-y-2'>
							<span className='underline italic'>Vos instructions:</span>
							<ReadMore text={instructions} limit={100} />
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default CartItemCard;
