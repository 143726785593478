import { useFormik } from 'formik';
import { SingleValue } from 'react-select';
import * as Yup from 'yup';
import SectionHeader from '../../../components/SectionHeader';
import FieldWrap from '../../../components/form/FieldWrap';
import Input from '../../../components/form/Input';
import Label from '../../../components/form/Label';
import SelectReact, { TSelectOption } from '../../../components/form/SelectReact';
import Validation from '../../../components/form/Validation';
import Icon from '../../../components/icon/Icon';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';

type TValues = {
	to: string;
	subject: string;
	template: string;
};

const options = [{ value: 'define-password', label: 'Définir le mot de passe' }];

const formSchema = Yup.object().shape({
	to: Yup.string()
		.email('Veuillez entrer un e-mail valide')
		.required('Ce champs est obligatoire'),
	subject: Yup.string().required('Ce champs est obligatoire'),
	template: Yup.string().required('Ce champs est obligatoire'),
});

const AdminMailManagerPage = () => {
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();

	const formik = useFormik({
		initialValues: {
			to: '',
			subject: '',
			template: '',
		},
		validationSchema: formSchema,
		onSubmit: async (values: TValues, { setSubmitting }) => {
			try {
				const response = await axiosFetch<{ sent: boolean }>({
					method: 'POST',
					url: '/api/admin/send-mail',
					requestBody: {
						to: values.to,
						subject: values.subject,
						template: values.template,
					},
				});
				if (response?.sent) {
					customToast.success('Mail envoyé');
				} else {
					customToast.error('Mail non envoyé');
				}
			} catch (e: any) {
				customToast.error('Une erreur est survenue');
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Mails' currentPage='Mails' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Mails'>
				<Container>
					<Card
						header={
							<SectionHeader
								title='Envoyer un mail'
								action={
									<Button
										onClick={() => formik.handleSubmit()}
										variant='solid'
										isLoading={formik.isSubmitting}
										isDisable={!formik.isValid}>
										Envoyer
									</Button>
								}
							/>
						}>
						<form className='flex flex-col gap-4' noValidate>
							<div className='w-full'>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.to}
									invalidFeedback={formik.errors.to}>
									<Label>
										To
										<FieldWrap
											firstSuffix={
												<Icon icon='HeroEnvelope' className='mx-2' />
											}>
											<Input
												name='to'
												placeholder='À'
												value={formik.values.to}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FieldWrap>
									</Label>
								</Validation>
							</div>
							<div className='w-full'>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.subject}
									invalidFeedback={formik.errors.subject}>
									<Label>
										Objet
										<FieldWrap
											firstSuffix={
												<Icon
													icon='HeroChatBubbleBottomCenterText'
													className='mx-2'
												/>
											}>
											<Input
												name='subject'
												placeholder='Objet'
												value={formik.values.subject}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FieldWrap>
									</Label>
								</Validation>
							</div>
							<div className='w-full'>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.template}
									invalidFeedback={formik.errors.template}>
									<Label>
										Template
										<SelectReact
											options={options}
											name='template'
											placeholder='Sélectionnez'
											value={options.find(
												(opt) => opt?.value === formik.values.template,
											)}
											onChange={(value) => {
												formik.setFieldValue(
													'template',
													(value as SingleValue<TSelectOption>)?.value,
												);
											}}
										/>
									</Label>
								</Validation>
							</div>
						</form>
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminMailManagerPage;
