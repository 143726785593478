import { OrOrderItem } from '@devschap/order-point-types';
import { useCallback, useMemo, useState } from 'react';
import OpLoader from '../../templates/layouts/Loader/OpLoader.template';
import { limitText } from '../../utils/ui.util';
import DataGrid, { DatagridColumnDef } from '../DataGrid';
import OpenInstruction from '../OpenInstruction';
import ProductImg from '../ProductImg';
import Badge from '../ui/Badge';
import BadgeCell from '../ui/BadgeCell';
import Button from '../ui/Button';
import Card from '../ui/Card';
import CurrencyCell from '../ui/CurrencyCell';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../ui/OffCanvas';
import ManageOrderItemForm from './ManageOrderItemForm';

const cols: DatagridColumnDef[] = [
	{
		field: 'image',
		label: 'Image',
		// hideOnMobile: true,
	},
	{
		field: 'name',
		label: 'Nom',
		children: ['status'],
	},

	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'quantity',
		label: 'Qt',
		children: ['unitPrice', 'amount'],
	},
	{
		field: 'unitPrice',
		label: 'PU',
	},
	{
		field: 'amount',
		label: 'Montant',
	},
];
type Props = {
	canBeUpdated: boolean;
	items: OrOrderItem[];
	refreshData(): void;
	loading: boolean;
};
const ManageOrderItems = ({ canBeUpdated, items, loading, refreshData }: Props) => {
	const [currentItem, setCurrentItem] = useState<OrOrderItem | null>(null);
	const [open, setOpen] = useState<boolean>(false);

	const openDrawer = useCallback(
		(itemId: number) => {
			setCurrentItem(items.find((x) => x.id === itemId) ?? null);
			setOpen(true);
		},
		[setCurrentItem, setOpen, items],
	);

	const refreshProducts = useCallback(
		(refresh: boolean) => {
			if (!refresh) return;

			setCurrentItem(null);
			setOpen(false);
			refreshData();
		},
		[setOpen, refreshData, setCurrentItem],
	);

	const columns = canBeUpdated
		? [
				...cols,
				{
					field: 'actions',
					label: 'Actions',
				},
		  ]
		: cols;

	const rows = useMemo(() => {
		return items.map((p) => ({
			image: (
				<ProductImg pictureUrl={p.pictureUrl} productName={p.productName ?? p.pictureUrl} />
			),

			// name: <LinkUrl text={limitText(p.productName, 100)} url={p.productUrl} />,
			name: (
				<div>
					{limitText(p.productName ?? p.productUrl, 100)}
					{p.instructions ? <OpenInstruction instructions={p.instructions} /> : null}
				</div>
			),
			status: p.processed ? (
				<span className='flex lg:flex-col space-x-2 lg:space-y-2 lg:space-x-0'>
					<BadgeCell value={p.accepted} label={p.accepted ? 'Accepté' : 'Rejeté'} />

					{p.accepted ? (
						<BadgeCell
							value={p.inStock}
							label={p.inStock ? 'En stock' : 'Rupture de stock'}
						/>
					) : null}
				</span>
			) : null,
			quantity: <span className='block lg:mx-4'>x{p.quantity}</span>,
			unitPrice: <CurrencyCell value={p.originalPrice} currency={p.originalCurrency} />,
			amount: (
				<div className='border-t pt-1 lg:border-0 lg:pt-0 font-semibold'>
					<CurrencyCell value={p.convertedPrice} />
				</div>
			),

			actions: canBeUpdated ? (
				<Button
					variant='outline'
					color='zinc'
					icon='HeroPencil'
					className='ml-4'
					onClick={() => openDrawer(p.id)}
				/>
			) : null,
		}));
	}, [canBeUpdated, items, openDrawer]);

	return (
		<>
			<Card
				header={
					<>
						Produits
						{items.length ? (
							<Badge className='ml-2 border-transparent text-sm' variant='outline'>
								{items.length}
							</Badge>
						) : null}
					</>
				}
				bodyClassName='overflow-x-scroll'>
				{loading ? (
					<OpLoader />
				) : (
					<DataGrid keyPrefix='product' columns={columns} rows={rows} />
				)}
			</Card>

			{currentItem ? (
				<OffCanvas isOpen={open} setIsOpen={setOpen}>
					<OffCanvasHeader className='cursor-pointer'>
						Modifier le produit
					</OffCanvasHeader>
					<OffCanvasBody>
						<ManageOrderItemForm item={currentItem} onClose={refreshProducts} />
					</OffCanvasBody>
				</OffCanvas>
			) : null}
		</>
	);
};

export default ManageOrderItems;
