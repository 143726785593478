import { CustomerOrderProgression } from '@devschap/order-point-types/types/order';
import { useCallback, useEffect, useState } from 'react';
import Timeline from '../../../components/Timeline';
import Card from '../../../components/ui/Card';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OrderTimelimeItem from './OrderTimelimeItem';

type Props = {
	orderId: number;
};

const OrderProgression = ({ orderId }: Props) => {
	const customToast = useCustomToast();
	const { axiosFetch } = useAxiosFunction();
	const [progression, setProgression] = useState<CustomerOrderProgression[]>([]);

	const getOrdersProgressions = useCallback(async () => {
		try {
			const response = await axiosFetch<{ orderProgression: CustomerOrderProgression[] }>({
				method: 'GET',
				url: `api/order/${orderId}/progression`,
			});
			setProgression(response?.orderProgression ?? []);
		} catch (error) {
			customToast.error('Une erreur est survenue. Veuillez rafraichir la page');
		}
	}, [customToast, axiosFetch, orderId]);

	useEffect(() => {
		getOrdersProgressions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card header='Progression'>
			{progression.map((item) => (
				<Timeline key={item.addedAt}>
					<OrderTimelimeItem item={item} />
				</Timeline>
			))}
		</Card>
	);
};

export default OrderProgression;
