import Card from '../Card';
import CircleSkeleton from './CircleSkeleton';
import LineSkeleton from './LineSkeleton';

const StatsCardSkeleton = () => {
	return (
		<div className='col-span-12 sm:col-span-6 lg:col-span-3'>
			<Card>
				<CircleSkeleton className='mb-3' size={80} />
				<LineSkeleton width='30%' />
				<LineSkeleton width='60%' />
			</Card>
		</div>
	);
};

export default StatsCardSkeleton;
