import { DcOrderMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import OrderStatusBadge from '../../../components/OrderStatusBadge';
import AvatarsGroup from '../../../components/ui/AvatarsGroup';
import Badge from '../../../components/ui/Badge';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import { deliveryCompanyPages } from '../../../config/pages.config';
import { LIMIT_PER_PAGE } from '../../../constants/datagrid.constants';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../../utils/ui.util';
import DeliveryCompanyOrderAction from './DeliveryCompanyOrderAction';

type Props = {
	customerId: string;
};

const cols: DatagridColumnDef[] = [
	{
		field: 'createdAt',
		label: 'Commande du',
		children: ['code', 'status'],
	},
	{
		field: 'code',
		label: 'Code',
	},
	{
		field: 'products',
		label: 'Produits',
	},
	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'deliveryDate',
		label: 'Date de livraison',
		hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const DeliveryComapnyCustomerOrderDetails = ({ customerId }: Props) => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();
	const [orders, setOrders] = useState<DcOrderMinimal[]>([]);
	const [offset, setOffset] = useState(0);
	const [showLoadMore, setShowLoadMore] = useState(true);

	const loadMore = useCallback(() => {
		setOffset((prev) => prev + LIMIT_PER_PAGE);
	}, [setOffset]);

	const getOrders = useCallback(async () => {
		try {
			const response = await axiosFetch<{ orders: DcOrderMinimal[] }>({
				method: 'GET',
				url: `/api/delivery-company/customers/${customerId}/orders`,
				queryParams: {
					offset,
					limit: LIMIT_PER_PAGE,
				},
			});

			const newData = response?.orders ?? [];
			if (offset === 0) {
				setOrders(newData);
			} else {
				setOrders((prev) => [...prev, ...newData]);
			}

			setShowLoadMore(Boolean(newData.length));
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la récupération des commandes");
		}
	}, [axiosFetch, customerId, customToast]);

	useEffect(() => {
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset]);

	const rows = useMemo(() => {
		return orders.map((order) => ({
			createdAt: formatDate(order.createdAt),
			code: order.code,
			products: <AvatarsGroup avatars={order.items} />,
			status: <OrderStatusBadge status={order.status} />,
			deliveryDate: order.deliveryDate,
			actions: (
				<DeliveryCompanyOrderAction
					orderId={order.orderId}
					returnUrl={generatePath(deliveryCompanyPages.customer.to, { customerId })}
				/>
			),
		}));
	}, [orders, customerId]);

	const content =
		orders.length === 0 ? (
			<EmptyContent message="Ce client n'a pas effectué de commande" />
		) : (
			<DataGrid
				keyPrefix='orders'
				rows={rows}
				columns={cols}
				loadMore={showLoadMore ? loadMore : undefined}
				isLoadingMore={offset > 0 && loading}
			/>
		);

	return (
		<Card
			header={
				<>
					Commandes
					{orders.length ? (
						<Badge className='ml-3 border-transparent text-sm' variant='outline'>
							{orders.length}
						</Badge>
					) : null}
				</>
			}>
			{loading ? <OpLoader /> : content}
		</Card>
	);
};

export default DeliveryComapnyCustomerOrderDetails;
