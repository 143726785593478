import Icon from '../icon/Icon';

const ITEMS = [
	{
		key: 'mail',
		label: 'Courrier électronique',
		link: 'mailto:support@globalorderpoint.com',
		icon: 'HeroEnvelope',
	},
	{
		key: 'whatsApp',
		label: 'WhatsApp',
		link: 'https://wa.me/22940536466',
		icon: 'HeroChatBubbleOvalLeftEllipsis',
	},
	{
		key: 'call',
		label: '(+229) 40 53 64 66',
		link: 'tel:+22940536466',
		icon: 'HeroPhoneArrowUpRight',
	},
];

const ContactServiceSupport = () => {
	return (
		<>
			<p>Vous avez des questions ou des préoccupations ? Nous sommes là pour vous aider !</p>
			<p>Par quel moyen souhaiterez-vous nous contacter ?</p>

			<div className='flex flex-col space-x-0'>
				{ITEMS.map((item) => (
					<a
						key={item.key}
						href={item.link}
						target='_blank'
						rel='noreferrer'
						className='p-5 border flex flex-col items-center space-y-7 text-center'>
						<Icon icon={item.icon} size='text-4xl' />
						<span>{item.label}</span>
					</a>
				))}
			</div>
		</>
	);
};

export default ContactServiceSupport;
