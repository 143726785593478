import { Link } from 'react-router-dom';
import Icon from '../icon/Icon';

type Props = {
	url: string;
	text?: string;
};

const ExternalLink = ({ url, text }: Props) => {
	return (
		<div>
			<Link
				to={url}
				target='_blank'
				referrerPolicy='no-referrer'
				className='flex items-center space-x-1 text-sm text-sky-500'>
				<span>{text ?? 'Voir le produit'}</span>
				<Icon icon='HeroArrowTopRightOnSquare' className='flex-shrink-0' />
			</Link>
		</div>
	);
};

ExternalLink.defaultProps = {
	text: undefined,
};

export default ExternalLink;
