import { EditorContent, EditorOptions, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classNames from 'classnames';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import HtmlViewer from '../../HtmlViewer';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import './RichTextEditor.css';
import EditorMenu from './components/EditorMenu';

type Props = {
	value: string | null;
	onChange(value: string | null): void;
	fullHeight?: boolean;
};

const extensions = [StarterKit];

const RichTextEditor = ({ value, fullHeight, onChange }: Props) => {
	const [mode, setMode] = useState<'edit' | 'preview'>('edit');

	const handleUpdate: EditorOptions['onUpdate'] = ({ editor }) => {
		if (editor.isEmpty) onChange(null);
		else onChange(editor.getHTML());
	};
	const isEditing = mode === 'edit';
	const isPreview = mode === 'preview';

	const debouncedHandleUpdate = useDebouncedCallback(handleUpdate, 500);

	const editor = useEditor({
		extensions,
		content: value,
		onUpdate: debouncedHandleUpdate,
	});

	return (
		<div
			className={classNames('relative h-[94%] border rounded-lg op-rich-text-editor', {
				'full-height': fullHeight,
			})}>
			<div className='border-b p-4'>
				<ButtonGroup variant='solid' className='mb-2'>
					<Button isActive={isEditing} onClick={() => setMode('edit')}>
						Editer
					</Button>
					<Button isActive={isPreview} onClick={() => setMode('preview')}>
						Voir
					</Button>
				</ButtonGroup>

				{isEditing ? (
					<div className='w-full flex flex-wrap justify-start'>
						<EditorMenu editor={editor} />
					</div>
				) : null}
			</div>
			{isEditing ? (
				<EditorContent editor={editor} />
			) : (
				<HtmlViewer html={editor?.getHTML() || ''} />
			)}
		</div>
	);
};

RichTextEditor.defaultProps = {
	fullHeight: true,
};

export default RichTextEditor;
