import { RouteProps } from 'react-router-dom';
import { authPages } from '../config/pages.config';

const authRoutes: RouteProps[] = [
	{ path: authPages.loginPage.to, element: null },
	{ path: authPages.signupPage.to, element: null },
	{ path: authPages.resetPasswordPage.to, element: null },
	{ path: authPages.forgotPasswordPage.to, element: null },
	// { path: '*', element: null },
	// {
	// 	path: authPages.onboardingPage.to,
	// 	element: null,
	// },
];

export default authRoutes;
