import { CoAddress } from '@devschap/order-point-types';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import CountrySelector from '../components/CountrySelector';
import AddressForm from '../pages/onbaording/components/AddressForm';

const customerSchema = Yup.object().shape({
	city: Yup.string().required('La ville est obligatoire'),
	district: Yup.string().required('Le quartier est obligatoire'),
	postalCode: Yup.string()
		.required('Veuillez entrer votre code postal')
		.matches(/^\d+$/, 'Le code postal doit contenir uniquement des chiffres'),
});

type Props = {
	address?: CoAddress | null;
	countryPlaceholder?: string;
};

const useAddressForm = ({ address, countryPlaceholder }: Props = {}) => {
	const [country, setCountry] = useState(address?.countryIso ?? '');

	const formik = useFormik({
		initialValues: {
			id: address?.id,
			city: address?.city ?? '',
			district: address?.district ?? '',
			postalCode: address?.postalCode ?? '',
		},
		validationSchema: customerSchema,
		onSubmit: () => {},
	});

	const city = {
		value: formik.values.city,
		isTouched: formik.touched.city,
		invalidFeedback: formik.errors.city,
		onChange: formik.handleChange,
		onBlur: formik.handleBlur,
	};

	const district = {
		value: formik.values.district,
		isTouched: formik.touched.district,
		invalidFeedback: formik.errors.district,
		onChange: formik.handleChange,
		onBlur: formik.handleBlur,
	};

	const postalCode = {
		value: formik.values.postalCode,
		isTouched: formik.touched.postalCode,
		invalidFeedback: formik.errors.postalCode,
		onChange: formik.handleChange,
		onBlur: formik.handleBlur,
	};

	const validateAddressForm = useCallback(() => {
		formik.handleSubmit();
		const touched = Object.keys(formik.touched).length !== 0;

		return touched && formik.isValid;
	}, [formik]);

	const form = (
		<div className='AdressForm flex flex-col space-y-4'>
			<div>
				<CountrySelector
					countryIso={country}
					onChanged={setCountry}
					placeholder={countryPlaceholder ?? 'Pays de résidence'}
				/>
			</div>
			<AddressForm
				isValid={formik.isValid}
				city={city}
				district={district}
				postalCode={postalCode}
			/>
		</div>
	);

	return {
		addressFormik: formik,
		AddressForm: form,
		country,
		validateAddressForm,
	};
};

export default useAddressForm;
