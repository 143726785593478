import { useState } from 'react';
import Collapse from '../utils/Collapse';
import Button from './Button';

type Props = {
	text: string;
	limit?: number;
};

const ReadMore = ({ text, limit = 200 }: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const hasMore = text && text.length > limit;

	if (!hasMore) {
		return text;
	}

	return (
		<>
			{isOpen ? null : <span className='block break-all'>{text.substring(0, limit)}...</span>}
			<div className='flex flex-col items-end'>
				<Collapse isOpen={isOpen} className='break-all'>
					{text}
				</Collapse>
				<Button className='!p-0 !text-sky-500 w-fit' onClick={() => setIsOpen(!isOpen)}>
					Voir {isOpen ? 'moins' : 'plus'}
				</Button>
			</div>
		</>
	);
};

ReadMore.defaultProps = {
	limit: undefined,
};

export default ReadMore;
