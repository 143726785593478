import { Dispatch, FC, SetStateAction } from 'react';
import Accordion from '../../../components/ui/Accordion';
import Button from '../../../components/ui/Button';
import ExternalLink from '../../../components/ui/ExternalLink';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasFooter,
	OffCanvasFooterChild,
	OffCanvasHeader,
} from '../../../components/ui/OffCanvas';
import constants from '../constants';

type Props = {
	state: boolean;
	setState: Dispatch<SetStateAction<boolean>>;
	title: string;
};

const CartHelpDrawer: FC<Props> = ({ state, setState, title }) => {
	return (
		<OffCanvas isOpen={state} setIsOpen={setState}>
			<OffCanvasHeader className='cursor-pointer'>{title}</OffCanvasHeader>
			<OffCanvasBody className='space-y-4'>
				{constants.CART_HELPS.map((c) => (
					<Accordion key={c.title} title={c.title}>
						{c.description}
						{c.links ? (
							<ul className='mt-4'>
								{c.links?.map((link) => (
									<li className='mt-2' key={link}>
										<ExternalLink url={link} text={link} />
									</li>
								))}
							</ul>
						) : null}
						{c.listItems ? (
							<ul className='mt-4'>
								{c.listItems?.map((item, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<li className='text-start' key={`item-${index}`}>
										{index + 1} - {item}
									</li>
								))}
							</ul>
						) : null}
					</Accordion>
				))}
			</OffCanvasBody>
			<OffCanvasFooter className='flex !justify-end'>
				<OffCanvasFooterChild>
					<Button onClick={() => setState(false)} variant='solid'>
						Fermer
					</Button>
				</OffCanvasFooterChild>
			</OffCanvasFooter>
		</OffCanvas>
	);
};

export default CartHelpDrawer;
