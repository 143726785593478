import { Stats } from '@devschap/order-point-types';
import { useEffect, useState } from 'react';
import useAxiosFunction from './useAxiosFunction';

type Props = {
	isUserActive?: boolean;
};

const useFetchStats = ({ isUserActive }: Props | undefined = {}) => {
	const [stats, setStats] = useState<Stats[]>([]);
	const { loading, axiosFetch } = useAxiosFunction();

	useEffect(() => {
		const fetch = async () => {
			if (!isUserActive || loading) return;
			try {
				const result = await axiosFetch<{ stats: Stats[] }>({
					method: 'get',
					url: '/api/user/stats',
				});

				if (result === undefined) return;
				setStats(result.stats);
			} catch (error) {
				//
			}
		};
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, stats };
};

export default useFetchStats;
