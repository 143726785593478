import PublicDataLayout from './PublicDataLayout';

type Props = {
	className?: string;
};

const PrivcayPolicy = ({ className }: Props) => {
	return (
		<PublicDataLayout
			apiUrl='/api/privacy-policy'
			emptyStateMessage='Pas de politique de confidentialité'
			className={className}
		/>
	);
};

PrivcayPolicy.defaultProps = {
	className: undefined,
};

export default PrivcayPolicy;
