import { DeliveryMan5WithDog } from '../assets/images';
import Container from '../components/layouts/Container/Container';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import ButtonLink from '../components/ui/ButtonLink';

const NotFoundPage = () => {
	return (
		<PageWrapper isProtectedRoute={false} name='404 Not Found'>
			<Container className='flex flex-col space-y-4 h-full items-center justify-center'>
				<span className='text-5xl font-semibold'>404</span>
				<span className='text-zinc-500'>La page recherchée est introuvable.</span>
				<img
					src={DeliveryMan5WithDog as string}
					alt='contenu-vide'
					className='max-h-[32rem]'
				/>
				<ButtonLink text="Revenir à la page d'accueil" showBorder path='/' />
			</Container>
		</PageWrapper>
	);
};

export default NotFoundPage;
