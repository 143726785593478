import PrivcayPolicy from '../components/common/PrivcayPolicy';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import ButtonLink from '../components/ui/ButtonLink';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';

const PrivacyPolicyPage = () => {
	return (
		<PageWrapper
			isProtectedRoute={false}
			className='bg-white dark:bg-inherit'
			name='Politique de confidentialité'>
			<div className='container mx-auto flex h-full  justify-center p-5'>
				<div className='flex max-w-3xl flex-col gap-8'>
					<div>
						<LogoTemplate className='h-12' />
					</div>

					<ButtonLink />

					<span className='text-2xl font-semibold'>
						Politique de Confidentialité de Global OrderPoint
					</span>
					<PrivcayPolicy className='h-[70vh] overflow-y-auto' />
				</div>
			</div>
		</PageWrapper>
	);
};

export default PrivacyPolicyPage;
