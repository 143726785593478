import { OrOrderItem } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';

type Props = {
	orderId: string | undefined;
};

const useDeliveryCompanyFetchOrderItems = ({ orderId }: Props) => {
	const [products, setProducts] = useState<OrOrderItem[]>([]);
	const customToast = useCustomToast();

	const { axiosFetch, loading } = useAxiosFunction();

	const getOrderItems = useCallback(async () => {
		if (!orderId || loading) return;

		try {
			const response = await axiosFetch<{ products: OrOrderItem[] }>({
				method: 'GET',
				url: `/api/delivery-company/orders/${orderId}/products`,
			});
			setProducts(response?.products ?? []);
		} catch (error) {
			customToast.error('Impossible de charger les produits. Veuillez rafraichir la page.');
		}
	}, [orderId, setProducts, customToast, axiosFetch, loading]);

	useEffect(() => {
		getOrderItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, orderItems: products, refreshItems: getOrderItems };
};

export default useDeliveryCompanyFetchOrderItems;
