import { Dispatch, FC, SetStateAction } from 'react';
import Button, { IButtonProps } from '../../../components/ui/Button';
import { AccountRole } from '../../../types/shared-enums';
import { getAccountRoleLabel } from '../../../utils/ui.util';

interface IPeriodButtonsPartialProps {
	activeTab: AccountRole;
	setActiveTab: Dispatch<SetStateAction<AccountRole>>;
}

const userTypes: AccountRole[] = [
	AccountRole.customer,
	AccountRole.deliveryCompany,
	// AccountRole.merchant,
];

const UserTypeSelector: FC<IPeriodButtonsPartialProps> = (props) => {
	const { activeTab, setActiveTab } = props;

	const defaultProps: IButtonProps = {
		size: 'sm',
		color: 'zinc',
	};
	const activeProps: IButtonProps = {
		...defaultProps,
		isActive: true,
		color: 'blue',
		colorIntensity: '500',
		variant: 'solid',
		rounded: 'rounded-full',
	};

	return (
		<div className='flex justify-between rounded-full border-2 border-zinc-500/20 p-1 drop-shadow-xl dark:border-zinc-800'>
			{userTypes.map((i) => (
				<Button
					key={i}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(activeTab === i ? { ...activeProps } : { ...defaultProps })}
					onClick={() => {
						setActiveTab(i);
					}}
					className='text-xs md:text-base w-1/2'>
					{getAccountRoleLabel(i)}
				</Button>
			))}
		</div>
	);
};

export default UserTypeSelector;
