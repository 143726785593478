import { DeliveryCompanyRawData } from '@devschap/order-point-types';
import { Dispatch, SetStateAction } from 'react';
import Radio from '../../../components/form/Radio';

type Props = {
	deliveryCompany: DeliveryCompanyRawData;
	selectedDeliveryCompanyId: number | undefined;
	setDeliveryCompany: Dispatch<SetStateAction<DeliveryCompanyRawData | null>>;
};
const AdminDeliveryCompaniesWithPricingRules = ({
	deliveryCompany,
	selectedDeliveryCompanyId,
	setDeliveryCompany,
}: Props) => {
	return (
		<div className='p-3 mt-4 border rounded-lg'>
			<Radio
				name='deliveryAddress'
				color='blue'
				selectedValue={selectedDeliveryCompanyId}
				value={deliveryCompany.id}
				onChange={() => setDeliveryCompany(deliveryCompany)}
				label={deliveryCompany.label}
			/>
		</div>
	);
};

export default AdminDeliveryCompaniesWithPricingRules;
