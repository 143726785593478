import { CuCustomerMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	FETCH_DATA_COMMON_HOOKS_DEFAULT_PROPS,
	LIMIT_PER_PAGE,
} from '../../../constants/datagrid.constants';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import { FetchDataCommonHooksProps } from '../../../types/api';

const useFetchAdminCustomers = ({
	useQueryParams,
}: FetchDataCommonHooksProps = FETCH_DATA_COMMON_HOOKS_DEFAULT_PROPS) => {
	const [customers, setCustomers] = useState<CuCustomerMinimal[]>([]);
	const [offset, setOffset] = useState(0);
	const [showLoadMore, setShowLoadMore] = useState(true);
	const [filters, setFilters] = useState<Record<string, any>>({});
	const [searchParams, _] = useSearchParams();

	const customToast = useCustomToast();
	const { axiosFetch, loading } = useAxiosFunction();

	const loadMore = useCallback(() => {
		setOffset((prev) => prev + LIMIT_PER_PAGE);
	}, [setOffset]);

	const getCustomers = async () => {
		if (loading || (!useQueryParams && customers.length)) return;
		try {
			const response = await axiosFetch<{ customers: CuCustomerMinimal[] }>({
				method: 'GET',
				url: '/api/admin/customers',
				queryParams: useQueryParams
					? {
							offset,
							limit: LIMIT_PER_PAGE,
							...filters,
					  }
					: undefined,
			});
			const newData = response?.customers ?? [];
			if (!useQueryParams) {
				setCustomers(newData);
				return;
			}

			if (offset === 0) {
				setCustomers(newData);
			} else {
				setCustomers((prev) => [...prev, ...newData]);
			}
			setShowLoadMore(Boolean(newData.length));
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la récupération des clients");
		}
	};

	useEffect(() => {
		getCustomers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset, filters]);

	useEffect(() => {
		setFilters({
			delivery_service_ids: searchParams.getAll('delivery_service_ids'),
			country_ids: searchParams.getAll('country_ids'),
			date: searchParams.getAll('date'),
		});
		setOffset(0);
	}, [searchParams]);

	return {
		loading,
		customers,

		showLoadMore,
		loadMore: showLoadMore ? loadMore : undefined,
		isLoadingMore: offset > 0 && loading,
	};
};

export default useFetchAdminCustomers;
