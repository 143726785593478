import { Dispatch, PropsWithChildren, ReactNode, SetStateAction } from 'react';
import Button from './Button';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasFooter,
	OffCanvasFooterChild,
	OffCanvasHeader,
} from './OffCanvas';

type Props = {
	title: string;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	footer?: ReactNode;
};

const Drawer = ({ title, isOpen, setIsOpen, children, footer }: Props & PropsWithChildren) => {
	return (
		<OffCanvas isOpen={isOpen} setIsOpen={setIsOpen}>
			<OffCanvasHeader className='cursor-pointer'> {title} </OffCanvasHeader>
			<OffCanvasBody className='space-y-4'>{children}</OffCanvasBody>

			<OffCanvasFooter className='flex !justify-end mt-4'>
				<OffCanvasFooterChild>{footer}</OffCanvasFooterChild>
				<OffCanvasFooterChild>
					<Button onClick={() => setIsOpen(false)} variant='solid'>
						Fermer
					</Button>
				</OffCanvasFooterChild>
			</OffCanvasFooter>
		</OffCanvas>
	);
};

Drawer.defaultProps = {
	footer: undefined,
};

export default Drawer;
