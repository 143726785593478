import { OrderStatus } from '../../types/shared-enums';
import { formatDate } from '../../utils/ui.util';
import OrderStatusBadge from '../OrderStatusBadge';
import Card from '../ui/Card';
import CopyButton from '../ui/CopyButton';
import ListTile from '../ui/ListTile';

type Props = {
	code: string;
	status: OrderStatus;
	createdAt: string;
	deliveryDate: string | null;
};

const OrderDetails = ({ code, status, createdAt, deliveryDate }: Props) => {
	return (
		<Card header='Détails de commande'>
			<ListTile label='Code' value={<CopyButton text={code} />} />
			<ListTile label='Commande du' value={formatDate(createdAt)} />
			<ListTile label='Statut' value={<OrderStatusBadge status={status} />} />
			{deliveryDate ? (
				<ListTile label='Date de livraison' value={formatDate(deliveryDate)} />
			) : null}
		</Card>
	);
};

export default OrderDetails;
