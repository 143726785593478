import { Stats } from '@devschap/order-point-types';
import classNames from 'classnames';
import { adminPages, customerPages, deliveryCompanyPages } from '../config/pages.config';
import { priceFormat } from '../utils/priceFormat.util';
import Icon from './icon/Icon';
import ButtonLink from './ui/ButtonLink';
import Card from './ui/Card';
import Tooltip from './ui/Tooltip';

type Props = {
	stat: Stats;
	icon: string;
	bgColor: string;
	label: string;
	info?: string;
};

const ROUTES: { [key in Stats['key']]: string | null } = {
	// CUSTOMER
	cuOrders: customerPages.orders.to,
	cuProducts: null,
	cuDeliveryAddresses: null,
	// DELIVERY COMPANY
	dcDeliveredOrders: deliveryCompanyPages.orders.to,
	dcOrdersInProgress: deliveryCompanyPages.orders.to,
	dcCustomers: deliveryCompanyPages.customers.to,
	dcProfit: null,

	// SYSTEM STAFF
	totalCustomers: adminPages.customers.to,
	totalOrders: adminPages.orders.to,
	totalDeliveryCompanies: adminPages.deliveryCompanies.to,
	profit: null,
};

const StatsCard = ({ stat, icon, bgColor, label, info }: Props) => {
	const route = ROUTES[stat.key];

	return (
		<Card className='h-full p-4'>
			<div className='w-full flex flex-col space-y-2'>
				<div className='w-full flex flex-wrap justify-between items-center'>
					<div
						className={classNames([
							'flex h-16 w-16 items-center justify-center rounded-full flex-shrink-0',
							bgColor,
						])}>
						<Icon icon={icon} size='text-3xl' className='text-white' />
					</div>
					<div className='text-2xl font-semibold'>
						{stat.isPrice ? priceFormat(stat.value) : stat.value}
					</div>
				</div>

				<div className='space-x-1 text-zinc-500 rtl:space-x-reverse'>
					<span className='font-semibold'>{label}</span>
					{info ? <Tooltip text={info} /> : null}
				</div>
			</div>
			{route && stat.value ? (
				<ButtonLink
					text='Voir tout'
					rightIcon
					path={route}
					showBorder
					className='w-full !py-0'
				/>
			) : null}
		</Card>
	);
};

StatsCard.defaultProps = {
	info: undefined,
};

export default StatsCard;
