import { PricingRule } from '@devschap/order-point-types/types/common';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import SectionHeader from '../../../components/SectionHeader';
import Input from '../../../components/form/Input';
import Button from '../../../components/ui/Button';
import EmptyContent from '../../../components/ui/EmptyContent';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';

const DeliverCompanyPrincingRules = () => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();
	const [loadPricingRules, setLoadingPricingRules] = useState<boolean>(false);
	const [pricingRules, setPricingRules] = useState<PricingRule[]>([]);

	useEffect(() => {
		const getPricingRules = async () => {
			if (loadPricingRules) return;
			try {
				setLoadingPricingRules(true);
				const response = await axiosFetch<{ pricingRules: PricingRule[] }>({
					method: 'GET',
					url: 'api/delivery-company/pricing-rules',
				});
				setPricingRules(response?.pricingRules ?? []);
			} catch (error) {
				customToast.error("Une erreur s'est produite lors de la récupération des tarifs");
			} finally {
				setLoadingPricingRules(false);
			}
		};
		getPricingRules();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const savePricingRules = useCallback(async () => {
		try {
			const response = await axiosFetch<{ succeeded: boolean }>({
				method: 'PUT',
				url: '/api/delivery-company/pricing-rules',
				requestBody: {
					pricingRules,
				},
			});
			if (response?.succeeded) {
				customToast.success('Grille tarifaire mise à jour avec succès');
			}
		} catch (error) {
			customToast.error('Une erreur est survenue');
		}
	}, [customToast, pricingRules, axiosFetch]);

	const handleValueChange = useCallback(
		(value: number, index: number, key: keyof PricingRule) => {
			setPricingRules((prev) =>
				prev.map((item, i) => (i === index ? { ...item, [key]: value } : item)),
			);
		},
		[setPricingRules],
	);
	const addNewRule = useCallback(() => {
		setPricingRules((prev) => [...prev, { minAmount: 0, maxAmount: 0, fees: 0 }]);
	}, [setPricingRules]);

	const displayContent = useMemo(() => {
		return !loadPricingRules && pricingRules.length ? (
			<div className='mt-8 grid grid-cols-12 gap-4'>
				<div className='col-span-4 font-semibold border-b'>Montant Min</div>
				<div className='col-span-4 font-semibold border-b'>Montant Max</div>
				<div className='col-span-4 font-semibold border-b'>Frais</div>

				{pricingRules.map((rule, index) => (
					<Fragment key={`rule-${index}`}>
						<div className='col-span-4'>
							<Input
								name='minAmount'
								type='number'
								value={rule.minAmount}
								onChange={(event) =>
									handleValueChange(
										Number(event.target.value),
										index,
										'minAmount',
									)
								}
							/>
						</div>
						<div className='col-span-4'>
							<Input
								name='minAmount'
								type='number'
								value={rule.maxAmount}
								onChange={(event) =>
									handleValueChange(
										Number(event.target.value),
										index,
										'maxAmount',
									)
								}
							/>
						</div>
						<div className='col-span-4'>
							<Input
								name='minAmount'
								type='number'
								value={rule.fees}
								onChange={(event) =>
									handleValueChange(Number(event.target.value), index, 'fees')
								}
							/>
						</div>
					</Fragment>
				))}
			</div>
		) : (
			<EmptyContent message="Vous n'avez pas encore définir votre grille tarrifaire" />
		);
	}, [pricingRules, loadPricingRules, handleValueChange]);

	return (
		<div className='w-full'>
			<SectionHeader
				title='Grille tarifaire'
				action={
					<div className='flex space-x-4'>
						{pricingRules.length ? (
							<Button
								variant='outline'
								onClick={savePricingRules}
								color='zinc'
								icon='HeroServer'
								isLoading={loading}>
								Enregistrer
							</Button>
						) : null}

						<Button variant='solid' icon='HeroPlus' onClick={addNewRule}>
							Ajouter
						</Button>
					</div>
				}
			/>

			{loading ? <OpLoader /> : displayContent}
		</div>
	);
};

export default DeliverCompanyPrincingRules;
