import { RouteProps } from 'react-router-dom';
import pagesConfig from '../config/pages.config';
import DefaultAsideTemplate from '../templates/layouts/Asides/DefaultAside.template';
import authRoutes from './_authRoutes';

const asideRoutes: RouteProps[] = [
	...authRoutes,
	{ path: pagesConfig.privacyPolicyPage.to, element: null },
	{ path: pagesConfig.termsOfUsePage.to, element: null },
	{ path: '*', element: <DefaultAsideTemplate /> },
];

export default asideRoutes;
