import { FC, ReactNode, useCallback, useState } from 'react';
import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/ui/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../../components/ui/Dropdown';

import Icon from '../../../../components/icon/Icon';
import Modal from '../../../../components/ui/Modal';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import useCustomToast from '../../../../hooks/useToast';
import { TIcons } from '../../../../types/icons.type';

interface INotificationItemProps {
	image?: string;
	name: string;
	icon?: TIcons;
	firstLine: ReactNode;
	secondLine: ReactNode;
	isUnread: boolean;
	time: string;
}
const NotificationItem: FC<INotificationItemProps> = ({
	image,
	name,
	icon,
	firstLine,
	secondLine,
	isUnread,
	time,
}) => {
	return (
		<div className='flex min-w-[24rem] gap-2'>
			<div className='relative flex-shrink-0'>
				<Avatar src={image} name={name} />
				{icon && (
					<span className='absolute start-3/4 top-3/4 flex rounded-full bg-blue-500/75 outline outline-2 outline-blue-500/75'>
						<Icon icon={icon} />
					</span>
				)}
			</div>
			<div className='grow'>
				<div className='flex gap-2'>{firstLine}</div>
				<div className='flex gap-2'>{secondLine}</div>
			</div>
			<div className='relative flex flex-shrink-0 items-center'>
				{isUnread && (
					<span className='absolute end-0 top-0 flex h-2 w-2'>
						<span className='absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75' />
						<span className='relative inline-flex h-2 w-2 rounded-full bg-red-500' />
					</span>
				)}
				<div className='text-zinc-500'>{time}</div>
			</div>
		</div>
	);
};
NotificationItem.defaultProps = {
	image: undefined,
	icon: undefined,
};

function urlBase64ToUint8Array(base64String: string | undefined): Uint8Array | null {
	if (!base64String) return null;

	// Replace non-URL-safe characters and pad with "="
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	// Decode base64 string to a binary string
	const rawData = window.atob(base64);

	// Create a Uint8Array from the binary string
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; i++) {
		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
}

const NotificationPartial = () => {
	const { axiosFetch } = useAxiosFunction();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const notificationAccessGranted = Notification.permission === 'granted';

	const customToast = useCustomToast();

	const saveSubscription = useCallback(async () => {
		try {
			const registration = await navigator.serviceWorker.ready;

			if (!registration.pushManager) {
				customToast.error('Votre navigateur ne supporte pas les notifications.');
				return;
			}

			const subscription = await registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(
					process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY,
				),
			});

			await axiosFetch<{ succeeded: boolean }>({
				method: 'POST',
				url: '/api/user/web-push-subscription',
				requestBody: {
					subscription: JSON.stringify(subscription),
				},
			});
		} catch (e: any) {
			customToast.error('Une erreur est survenue.');
		}
	}, [axiosFetch, customToast]);

	const askPermission = useCallback(async () => {
		setIsModalOpen(false);
		const permissionResult = await Notification.requestPermission();
		if (permissionResult === 'granted') {
			customToast.success('Notifications autorisées avec succès');
			// reload the page to apply notifications settings
			// window.location.reload();
			saveSubscription();
		} else {
			customToast.error('Veuillez autoriser les notifications');
		}
	}, [setIsModalOpen, customToast, saveSubscription]);

	if (!notificationAccessGranted) {
		return (
			<>
				<Icon
					icon='HeroBell'
					size='text-3xl'
					color='red'
					className='cursor-pointer'
					onClick={() => setIsModalOpen(true)}
				/>

				<Modal
					isOpen={isModalOpen}
					setIsOpen={setIsModalOpen}
					title='Notifications'
					footer={
						<Button variant='solid' className='w-full' onClick={askPermission}>
							Autoriser
						</Button>
					}>
					Pour recevoir en temps réel les notifcations sur vos commandes, veuillez
					autoriser Global OrderPoint à vous envoyer les notifcations.
				</Modal>
			</>
		);
	}

	return (
		<div className='relative'>
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<Button icon='HeroBell' aria-label='Notification' />
				</DropdownToggle>
				<DropdownMenu
					placement='bottom-end'
					className='flex flex-col flex-wrap divide-y divide-dashed divide-zinc-500/50 p-4 [&>*]:py-4'>
					hello
				</DropdownMenu>
			</Dropdown>
			<span className='absolute end-0 top-0 flex h-3 w-3'>
				<span className='absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75' />
				<span className='relative inline-flex h-3 w-3 rounded-full bg-red-500' />
			</span>
		</div>
	);
};

export default NotificationPartial;
