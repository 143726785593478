import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import ButtonLink from '../../../components/ui/ButtonLink';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import Summary from '../components/Summary';

export const CheckoutPage = () => {
	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb
						path='Pages / Panier / Vérification'
						currentPage='Récapitualtif du panier'
					/>
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<PageWrapper name='Vérification'>
				<Container className='max-w-3xl m-auto mt-0 space-y-8'>
					<ButtonLink text='Voir le panier' />
					<Summary />
				</Container>
			</PageWrapper>
		</>
	);
};

export default CheckoutPage;
