import { useFormik } from 'formik';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CuCart } from '@devschap/order-point-types';
import FieldWrap from '../../../components/form/FieldWrap';
import Input from '../../../components/form/Input';
import Validation from '../../../components/form/Validation';
import Icon from '../../../components/icon/Icon';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import ListTile from '../../../components/ui/ListTile';
import { customerPages } from '../../../config/pages.config';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useOrderSore from '../../../hooks/useOrderStore';
import useCustomToast from '../../../hooks/useToast';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import CartHelpDrawer from '../components/CartHelpDrawer';
import EditableCartItemCard from '../components/EditableCartItemCard';

interface CartResponse {
	cart: CuCart | null;
}

type TValues = {
	link: string;
};

const valideDomain =
	/^(https?:\/\/)?(www\.|fr\.)?(amazon\.com|amazon\.fr|ebay\.com|ebay\.fr|zara\.com|shein\.com)\/.*$/;

const productSchema = Yup.object().shape({
	link: Yup.string()
		.matches(
			valideDomain,
			"Lien invalide, veuillez consulter la rubrique d'aide pour voir les fournisseurs autorisés",
		)
		.required('Veuillez entrer le lien vers le produit'),
});

const CustomerCartPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { setCart, cart, cartLength, cartItems } = useOrderSore();

	const customToast = useCustomToast();
	const { axiosFetch, loading } = useAxiosFunction();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const updateCart = useCallback(
		(newCart: CuCart | null) => {
			dispatch(setCart(newCart));
		},
		[dispatch, setCart],
	);

	useEffect(() => {
		const loadCart = async () => {
			if (loading) return;

			const response = await axiosFetch<CartResponse>({
				method: 'GET',
				url: '/api/customer/cart',
			});

			updateCart(response?.cart ?? null);
		};

		loadCart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeQuantity = useCallback(
		async (link: string, newQuantity: number) => {
			const newProducts = cartItems
				.map((p) => {
					if (p.link === link) {
						return { ...p, quantity: newQuantity };
					}
					return p;
				})
				.filter((p) => p.quantity > 0);

			const response = await axiosFetch<CartResponse>({
				method: 'PUT',
				url: '/api/customer/cart',
				requestBody: {
					items: newProducts,
				},
			});

			if (response === undefined) {
				customToast.error('Une erreur est survenue');
			} else {
				customToast.success('Panier mis à jour');
				updateCart(response.cart);
			}
		},
		[cartItems, updateCart, axiosFetch, customToast],
	);

	const onUpdateInstructions = useCallback(
		async (link: string, instructions: string) => {
			const newProducts = cartItems.map((p) => {
				if (p.link === link) {
					return { ...p, instructions };
				}
				return p;
			});

			const response = await axiosFetch<CartResponse>({
				method: 'PUT',
				url: '/api/customer/cart',
				requestBody: {
					items: newProducts,
				},
			});

			if (response === undefined) {
				customToast.error('Une erreur est survenue');
			} else {
				customToast.success('Panier mis à jour');
				updateCart(response.cart);
			}
		},
		[cartItems, axiosFetch, customToast, updateCart],
	);

	const formik = useFormik({
		initialValues: {
			link: '',
		},
		validationSchema: productSchema,
		onSubmit: async ({ link }: TValues, { setSubmitting }) => {
			try {
				if (cart?.items.find((p) => p.link === link)) {
					customToast.error('Ce produit est déjà dans votre panier.');
					return;
				}

				const response = await axiosFetch<CartResponse>({
					method: 'POST',
					url: '/api/customer/cart/add-product',
					requestBody: {
						url: link,
					},
				});

				if (response === undefined) return;

				customToast.success('Produit ajouté au panier');

				formik.setFieldValue('link', '');
				formik.setFieldTouched('link', false);

				updateCart(response.cart);
			} catch (e: any) {
				customToast.error('Une erreur est survenue');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const clearLinkInput = () => {
		if (formik.isSubmitting) return;
		formik.setFieldValue('link', '');
		formik.setFieldTouched('link', false);
	};

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				formik.handleSubmit();
			}
		},
		[formik],
	);

	const openCanvas = () => {
		setIsOpen(true);
	};

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Nouvelle commande' currentPage='Nouvelle commande' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Nouvelle commande'>
				<Container>
					<div className=' p-0 m-0 flex justify-end'>
						<Button className='underline cursor-pointer' onClick={openCanvas}>
							Besoin d'aide ?
						</Button>
						<CartHelpDrawer state={isOpen} setState={setIsOpen} title='Aide' />
					</div>
					<Card className='my-3 px-4 py-8 w-full md:mb-12'>
						<form className='flex gap-4 flex-col md:flex-row' noValidate>
							<div className='w-full'>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.link}
									invalidFeedback={formik.errors.link}>
									<FieldWrap
										firstSuffix={<Icon icon='HeroLink' className='mx-2' />}
										lastSuffix={
											formik.values.link ? (
												<Icon
													icon='HeroXMark'
													className='mx-2'
													onClick={clearLinkInput}
												/>
											) : null
										}>
										<Input
											id='link'
											name='link'
											placeholder='Lien du produit'
											value={formik.values.link}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											onKeyDown={onKeyDown}
											disabled={formik.isSubmitting}
										/>
									</FieldWrap>
								</Validation>
							</div>

							<div className='flex-shrink-0'>
								<Button
									size='lg'
									variant='solid'
									isLoading={formik.isSubmitting}
									isDisable={!formik.isValid && formik.isSubmitting}
									className='w-full font-semibold'
									onClick={() => formik.handleSubmit()}>
									Ajouter
								</Button>
							</div>
						</form>
					</Card>

					{cartItems.length === 0 ? (
						<div className='flex flex-col justify-center items-center space-y-2 h-96'>
							<Icon icon='HeroShoppingBag' size='text-9xl' color='blue' />
							<p className='text-xl'>
								Ajoutez vos produits et commandez facilement !!!
							</p>
						</div>
					) : (
						<div className='grid grid-cols-12 gap-4'>
							<div className='col-span-12 md:col-span-8 '>
								<Card header='Panier'>
									{cartItems.map((p) => (
										<EditableCartItemCard
											item={p}
											key={p.link}
											onUpdateQt={onChangeQuantity}
											onUpdateInstructions={onUpdateInstructions}
										/>
									))}
								</Card>
							</div>
							<div className='col-span-12 md:col-span-4'>
								<Card header='Sommaire' bodyClassName='flex flex-col'>
									<ListTile label='Nombre de produits' value={cartLength} />

									<Button
										variant='solid'
										color='blue'
										onClick={() => navigate(customerPages.checkout.to)}>
										Continuer
									</Button>
								</Card>
							</div>
						</div>
					)}
				</Container>
			</PageWrapper>
		</>
	);
};

export default CustomerCartPage;
