import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import DeliveryInviteCustomerBanner from '../../../components/delivery-company/DeliveryInviteCustomerBanner';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Badge from '../../../components/ui/Badge';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import DataGridFilters from '../../../components/ui/DataGrid/DataGridFilters';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import EmptyContent from '../../../components/ui/EmptyContent';
import { deliveryCompanyPages } from '../../../config/pages.config';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { FilterOption } from '../../../types/common/filters.type';
import { formatDate, getCountryName } from '../../../utils/ui.util';
import constants from '../../customer/constants';
import useDeliveryCompanyCustomers from '../hooks/useDeliveryCompanyCustomers';

const cols: DatagridColumnDef[] = [
	{
		field: 'id',
		label: 'ID',
	},
	{
		field: 'fullName',
		label: 'Nom complet',
		children: ['country', 'phoneNumber'],
	},
	{
		field: 'country',
		label: 'Pays',
	},
	{
		field: 'phoneNumber',
		label: 'Numéro',
	},
	{
		field: 'createdAt',
		label: 'Inscrit le',
		// hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const filters: FilterOption[] = [
	{
		filterKey: 'country_ids',
		label: 'Pays',
		options: constants.COUNTRY_OPTIONS,
		type: 'multiple',
	},
	{
		filterKey: 'statuses',
		label: 'Statut',
		options: constants.FORMATTED_STATUS,
		type: 'multiple',
	},
	{
		filterKey: 'date',
		label: 'Date de commandes',
		type: 'dateRange',
	},
];
const DeliveryCompanyCustomersPage = () => {
	const { customers, loading, loadMore, isLoadingMore } = useDeliveryCompanyCustomers();

	const navigate = useNavigate();

	const openDeliveryCompanyCustomerDetailsPage = useCallback(
		(customerId: number) => {
			navigate(generatePath(deliveryCompanyPages.customer.to, { customerId }));
		},
		[navigate],
	);

	const rows = useMemo(() => {
		return customers.map((customer) => ({
			id: customer.id,
			fullName: `${customer.lastName} ${customer.firstName}`,
			country: getCountryName(customer.address.countryIso),
			phoneNumber: customer.telephone1,
			createdAt: formatDate(customer.createdAt),
			actions: (
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
					</DropdownToggle>
					<DropdownMenu placement='bottom-end'>
						<DropdownItem
							onClick={() => openDeliveryCompanyCustomerDetailsPage(customer.id)}
							icon='HeroEye'>
							voir
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			),
		}));
	}, [customers, openDeliveryCompanyCustomerDetailsPage]);

	const content = useMemo(() => {
		if (loading) {
			return <OpLoader />;
		}

		if (customers.length) {
			return (
				<DataGrid
					keyPrefix='customers'
					columns={cols}
					rows={rows}
					loadMore={loadMore}
					isLoadingMore={isLoadingMore}
				/>
			);
		}

		return (
			<EmptyContent
				message={
					<div className='flex flex-col space-y-2 justify-center items-center'>
						<span>Pas de clients</span>
					</div>
				}
			/>
		);
	}, [loading, customers, rows, loadMore, isLoadingMore]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Clients' currentPage='Clients' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Clients'>
				<Container>
					<DeliveryInviteCustomerBanner />

					<DataGridFilters filters={filters} />
					<Card
						header={
							<>
								Clients
								{customers.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{customers.length}
									</Badge>
								) : null}
							</>
						}>
						{content}
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default DeliveryCompanyCustomersPage;
