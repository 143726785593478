import constants from '../pages/customer/constants';
import { OrderStatus } from '../types/shared-enums';
import Badge from './ui/Badge';

type Props = {
	status: OrderStatus;
};

const OrderStatusBadge = ({ status }: Props) => {
	const { text, color } = constants.ORDER_STATUS_MAP[status] ?? { text: status, color: 'blue' };

	return (
		<Badge className='whitespace-nowrap border-transparent' variant='solid' color={color}>
			{text}
		</Badge>
	);
};

export default OrderStatusBadge;
