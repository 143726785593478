import { AdminStatsKeys } from '@devschap/order-point-types/types/common';
import StatsCard from '../../../components/StatsCard';
import StatsCardSkeleton from '../../../components/ui/skeletons/StatsCardSkeleton';
import useFetchStats from '../../../hooks/useFetchStats';
import { StatsMeta } from '../../../types/api';

const STATS_META: { [key in AdminStatsKeys]: StatsMeta } = {
	totalOrders: {
		icon: 'HeroShoppingCart',
		bgColor: 'bg-blue-500',
		label: 'Commandes',
		info: 'Le nombre total de commandes enregistrées',
	},
	totalCustomers: {
		icon: 'HeroUserGroup',
		bgColor: 'bg-amber-500',
		label: 'Clients',
		info: 'Le nombre total de clients inscrit',
	},
	totalDeliveryCompanies: {
		icon: 'HeroTruck',
		bgColor: 'bg-violet-500',
		label: 'Services de livraison',
		info: 'Le nombre total de services de livraison inscrit',
	},
	// totalOrders: {
	// 	icon: 'HeroBanknotes',
	// 	bgColor: 'bg-emerald-500',
	// 	label: 'Total des commandes',
	// 	info: 'Le cummul de vos achats',
	// },
	// totalDeliveryCompanies: {
	// 	icon: 'HeroBanknotes',
	// 	bgColor: 'bg-emerald-500',
	// 	label: 'Total des services de livraison',
	// 	info: 'Le cummul de vos achats',
	// },
	profit: {
		icon: 'HeroBanknotes',
		bgColor: 'bg-emerald-500',
		label: 'Profit',
		info: 'Le cummul des frais de service',
	},
};

const AdminDashboard = () => {
	const { loading: loadingStats, stats } = useFetchStats({ isUserActive: true });

	return (
		<div className='grid grid-cols-12 gap-4'>
			{loadingStats ? (
				<>
					<StatsCardSkeleton />
					<StatsCardSkeleton />
					<StatsCardSkeleton />
					<StatsCardSkeleton />
				</>
			) : (
				<>
					{stats.map((stat) => (
						<div className='col-span-12 sm:col-span-6 lg:col-span-3' key={stat.key}>
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<StatsCard stat={stat} {...STATS_META[stat.key as AdminStatsKeys]} />
						</div>
					))}
				</>
			)}

			{/* <div className='col-span-12 2xl:col-span-8'>area 1</div>
			<div className='col-span-12 2xl:col-span-4'>area 2</div>
			<div className='col-span-12 2xl:col-span-8'>area 3</div>
			<div className='col-span-12 2xl:col-span-4'>area 4</div> */}
		</div>
	);
};

export default AdminDashboard;
