import { FC, ReactNode } from 'react';

type TListTile = {
	label: string;
	description?: string;
	value?: ReactNode;
};

const ListTile: FC<TListTile> = ({ label, description, value }) => {
	return (
		<div className='flex justify-between rounded-lg p-3 bg-zinc-100 items-center space-x-2'>
			<div className='flex flex-col'>
				<span className='text-gray-600'>{label}</span>
				{description ? <span className='text-xs text-gray-400'>{description}</span> : null}
			</div>
			<div className='font-medium'>{value}</div>
		</div>
	);
};

ListTile.defaultProps = {
	value: '-',
	description: undefined,
};

export default ListTile;
