import PublicDataLayout from './PublicDataLayout';

type Props = {
	className?: string;
};

const TermsOfUse = ({ className }: Props) => {
	return (
		<PublicDataLayout
			apiUrl='/api/gcu'
			emptyStateMessage="Pas de conditions d'utilisation"
			className={className}
		/>
	);
};

TermsOfUse.defaultProps = {
	className: undefined,
};

export default TermsOfUse;
