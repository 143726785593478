import { useState } from 'react';
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
import { authPages } from '../../config/pages.config';
import useUserStore from '../../hooks/useUserStore';
import Alert from '../ui/Alert';
import Button from '../ui/Button';
import CopyButton from '../ui/CopyButton';
import Modal from '../ui/Modal';

const DeliveryInviteCustomerBanner = () => {
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const { state: userState } = useUserStore();

	if (!userState.deliveryService?.referralCode) return null;

	const title =
		"Bonjour [Cher/Chère] Client(e), J'espère que vous allez bien.\n\n" +
		"J'ai le plaisir de vous inviter à me rejoindre sur Global OrderPoint pour vos prochaines commandes.\n" +
		'Inscrivez-vous dès maintenant en utilisant le lien ci-dessous et profitez de la livraison gratuite sur votre première commande\n';

	const shareUrl = `${window.location.origin}${authPages.signupPage.to}?referralCode=${userState.deliveryService.referralCode} `;

	const shareUrlLink = (
		<a
			href={shareUrl}
			target='_blank'
			rel='noopener noreferrer'
			className='text-blue-500 underline'>
			{shareUrl}
		</a>
	);

	return (
		<>
			<Modal
				isOpen={modalStatus}
				size='sm'
				setIsOpen={setModalStatus}
				title='Inviter un client'
				footer={
					<Button
						color='zinc'
						variant='outline'
						className='w-full'
						onClick={() => setModalStatus(false)}>
						Fermer
					</Button>
				}>
				<p>
					Bonjour Cher/Chère Client.e, J'espère que vous allez bien. J'ai le plaisir de
					vous inviter à me rejoindre sur{' '}
					<span className='font-bold'>Global OrderPoint</span> pour vos prochaines
					commandes. Inscrivez-vous dès maintenant en utilisant le lien ci-dessous et
					profitez de la livraison gratuite sur votre première commande.
					{shareUrlLink}
				</p>
				<div className='flex space-x-2 justify-center items-center'>
					<div>
						<WhatsappShareButton url={shareUrl} title={title}>
							<WhatsappIcon size={32} round />
						</WhatsappShareButton>
					</div>

					<div>
						<FacebookShareButton url={shareUrl} title={title}>
							<FacebookIcon size={32} round />
						</FacebookShareButton>
					</div>

					<div>
						<LinkedinShareButton url={shareUrl} title={title}>
							<LinkedinIcon size={32} round />
						</LinkedinShareButton>
					</div>
					<div>
						<TwitterShareButton url={shareUrl} title={title}>
							<TwitterIcon size={32} round />
						</TwitterShareButton>
					</div>
				</div>
			</Modal>
			<Alert
				className='border-transparent my-4'
				title='Invitez vos clients!'
				variant='outline'>
				<span className='block mb-2 text-black'>
					Afin que les commandes de vos clients vous soient directement attribuées,
					veuillez leur transmettre votre code de parrainage. Ils devront l'indiquer lors
					de leur inscription sur la plateforme.
				</span>
				<div className='flex flex-col space-y-2 space-x-0 md:space-y-0 md:space-x-2 md:flex-row'>
					<Button variant='solid' onClick={() => setModalStatus(true)}>
						Inviter un client
					</Button>
					<CopyButton
						text={`Copier mon code: ${userState.deliveryService.referralCode}`}
						value={userState.deliveryService.referralCode}
					/>
				</div>
			</Alert>
		</>
	);
};

export default DeliveryInviteCustomerBanner;
