import { useMemo, useState } from 'react';
import Icon from '../../../components/icon/Icon';
import { NavItem, NavSeparator } from '../../../components/layouts/Navigation/Nav';
import User from '../../../components/layouts/User/User';
import Button from '../../../components/ui/Button';
import Modal from '../../../components/ui/Modal';
import { adminPages, customerPages, deliveryCompanyPages } from '../../../config/pages.config';
import { useAuth } from '../../../context/authContext';
import useUserStore from '../../../hooks/useUserStore';

const UserTemplate = () => {
	const { isLoading, onLogout } = useAuth();

	const { displayName, state, isCustomer, isAdmin, isDeliveryCompany } = useUserStore();
	const [modalStatus, setModalStatus] = useState<boolean>(false);

	const profileRoute = useMemo(() => {
		if (isCustomer) return customerPages.profile;
		if (isAdmin) return adminPages.profile;
		if (isDeliveryCompany) return deliveryCompanyPages.profile;

		return null;
	}, [isAdmin, isCustomer, isDeliveryCompany]);

	if (!state.email) return null;

	return (
		<div>
			<Modal
				isOpen={modalStatus}
				size='sm'
				setIsOpen={setModalStatus}
				title='Déconnexion'
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={() => setModalStatus(false)}>
							Non
						</Button>
						<Button variant='solid' className='w-full' onClick={onLogout}>
							oui
						</Button>
					</>
				}>
				Souhaitez-vous vraiment vous déconnecter ?
			</Modal>
			<User
				isLoading={isLoading}
				email={state.email}
				name={displayName}
				nameSuffix={
					state.verified ? <Icon icon='HeroCheckBadge' color='blue' /> : undefined
				}
				src={state.profileImgUrl ?? undefined}>
				<NavSeparator />
				{profileRoute && <NavItem {...profileRoute} />}

				<NavItem
					text='Déconnexion'
					icon='HeroArrowRightOnRectangle'
					onClick={() => setModalStatus(true)}
				/>
			</User>
		</div>
	);
};

export default UserTemplate;
