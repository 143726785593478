export const priceFormat = (price: number, currency?: string): string => {
	return new Intl.NumberFormat('fr-FR', {
		style: 'currency',
		currency: currency ?? 'XOF',
	}).format(price);
};

export const numberFormat = (value: number): string => {
	return new Intl.NumberFormat('fr-FR').format(value);
};
