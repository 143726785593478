import { useMemo } from 'react';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import FeedbackTypeBadge from '../../../components/FeedbackTypeBadge';
import Rating from '../../../components/common/Rating';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Badge from '../../../components/ui/Badge';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import ReadMore from '../../../components/ui/ReadMore';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';

import { FeedbackType } from '../../../types/shared-enums';
import { formatDate } from '../../../utils/ui.util';
import useAdminFeedbacks from '../hooks/useAdminFeedbacks';

export interface Feedback {
	id: number;
	user: string;
	content: string | null;
	rating: number | null;
	feedbackType: FeedbackType;
	accountId: number;
	createdAt: string;
	updatedAt: string;
}

const cols: DatagridColumnDef[] = [
	{
		field: 'createdAt',
		label: 'Commande du',
		children: ['user', 'feedbackType'],
	},

	{
		field: 'id',
		label: 'ID',
	},
	{
		field: 'user',
		label: 'Email',
	},

	{
		field: 'feedbackType',
		label: 'Type',
	},
	{
		field: 'content',
		label: 'Message',
		// hideOnMobile: true,
	},
	{
		field: 'rating',
		label: 'Notation',
		// hideOnMobile: true,
	},
];

const AdminFeedbacks = () => {
	const { loading, feedbacks, showLoadMore, loadMore, isLoadingMore } = useAdminFeedbacks();

	const rows = useMemo(() => {
		return feedbacks.map((feedback) => ({
			createdAt: formatDate(feedback.createdAt),
			id: feedback.id,
			user: feedback.user,
			feedbackType: <FeedbackTypeBadge feedback={feedback.feedbackType} />,
			content: <ReadMore text={feedback.content ?? ''} />,
			rating: feedback.rating !== null ? <Rating value={feedback.rating} /> : null,
		}));
	}, [feedbacks]);

	const displayContent = useMemo(() => {
		return feedbacks.length === 0 ? (
			<EmptyContent message='Pas de commentaires enregistré' />
		) : (
			<DataGrid
				keyPrefix='feedbacks'
				rows={rows}
				columns={cols}
				loadMore={loadMore}
				isLoadingMore={isLoadingMore}
			/>
		);
	}, [feedbacks, rows, loadMore, loading, showLoadMore]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Commentaires' currentPage='Commentaires' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Commentaires'>
				<Container>
					<Card
						className='h-full'
						bodyClassName='overflow-x-scroll'
						header={
							<>
								Commentaires
								{feedbacks.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{feedbacks.length}
									</Badge>
								) : null}
							</>
						}>
						{loading ? <OpLoader /> : displayContent}
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminFeedbacks;
