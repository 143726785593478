import { Suspense, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import contentRoutes from '../../routes/contentRoutes';

import useUserStore from '../../hooks/useUserStore';
import adminRoutes from '../../routes/users/adminRoutes';
import customerRoutes from '../../routes/users/customerRoutes';
import deliveryCompanyRoutes from '../../routes/users/deliveryCompanyRoutes';
import OpLoader from '../../templates/layouts/Loader/OpLoader.template';
import PageWrapper from '../layouts/PageWrapper/PageWrapper';

const ContentRouter = () => {
	const { isCustomer, isAdmin, isDeliveryCompany } = useUserStore();

	const userRoutes = useMemo(() => {
		if (isCustomer) return customerRoutes;
		if (isAdmin) return adminRoutes;
		if (isDeliveryCompany) return deliveryCompanyRoutes;

		return [];
	}, [isCustomer, isAdmin, isDeliveryCompany]);

	return (
		<Suspense
			fallback={
				<PageWrapper>
					<OpLoader />
				</PageWrapper>
			}>
			<Routes>
				{contentRoutes.map((routeProps) => (
					<Route key={routeProps.path} {...routeProps} />
				))}

				{userRoutes.map((routeProps) => (
					<Route key={routeProps.path} {...routeProps} />
				))}
			</Routes>
		</Suspense>
	);
};

export default ContentRouter;
