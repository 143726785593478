import { RouteProps } from 'react-router-dom';
import pagesConfig from '../config/pages.config';
import DefaultFooterTemplate from '../templates/layouts/Footers/DefaultFooter.template';
import authRoutes from './_authRoutes';

const footerRoutes: RouteProps[] = [
	...authRoutes,
	{ path: pagesConfig.privacyPolicyPage.to, element: null },
	{ path: pagesConfig.termsOfUsePage.to, element: null },
	{
		path: '/c/*',
		element: null,
	},
	{
		path: '/a/*',
		element: null,
	},
	{
		path: '/dc/*',
		element: null,
	},
	{ path: '*', element: <DefaultFooterTemplate /> },
];

export default footerRoutes;
