import { OpCurrency } from '../../types/shared-enums';
import { priceFormat } from '../../utils/priceFormat.util';

type Props = {
	value: number | null;
	currency?: OpCurrency | null;
};

const CurrencyCell = ({ value, currency }: Props) => {
	return <div>{value ? priceFormat(value, currency ?? undefined) : value}</div>;
};

CurrencyCell.defaultProps = {
	currency: undefined,
};

export default CurrencyCell;
