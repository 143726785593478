import { OrOrderCompleteFeesData } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';

type Props = {
	orderId: string | undefined;
};

const useDeliveryCompanyFetchOrderFees = ({ orderId }: Props) => {
	const [fees, setFees] = useState<OrOrderCompleteFeesData | null>(null);
	const customToast = useCustomToast();
	const { axiosFetch, loading } = useAxiosFunction();

	const loadFees = useCallback(async () => {
		if (!orderId || loading) return;

		try {
			const response = await axiosFetch<{ fees: OrOrderCompleteFeesData | null }>({
				method: 'GET',
				url: `/api/delivery-company/orders/${orderId}/fees`,
			});
			setFees(response?.fees ?? null);
		} catch (error) {
			customToast.error('Une erreur est survenue');
		}
	}, [orderId, setFees, customToast, axiosFetch, loading]);

	useEffect(() => {
		loadFees();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, fees, refreshFees: loadFees };
};

export default useDeliveryCompanyFetchOrderFees;
