import { CustomerStatsKeys } from '@devschap/order-point-types';
import StatsCard from '../../../components/StatsCard';
import StatsCardSkeleton from '../../../components/ui/skeletons/StatsCardSkeleton';
import useFetchStats from '../../../hooks/useFetchStats';
import { StatsMeta } from '../../../types/api';

const STATS_META: { [key in CustomerStatsKeys]: StatsMeta } = {
	cuOrders: {
		icon: 'HeroShoppingCart',
		bgColor: 'bg-blue-500',
		label: 'Commandes',
		info: 'Toutes vos commandes peu importe le statut (livré, en cours, annulé etc...)',
	},
	cuProducts: {
		icon: 'HeroRectangleStack',
		bgColor: 'bg-amber-500',
		label: 'Produits',
		info: 'Le nombre total de produits commandés',
	},
	cuDeliveryAddresses: {
		icon: 'HeroHome',
		bgColor: 'bg-violet-500',
		label: 'Adresses de livraison',
		info: "Le nombre d'adresses de livraison que vous avez enregistré",
	},
};

const CustomerDashboard = () => {
	const { loading: loadingStats, stats } = useFetchStats({ isUserActive: true });

	return (
		<div className='grid grid-cols-12 gap-4'>
			{loadingStats ? (
				<>
					<StatsCardSkeleton />
					<StatsCardSkeleton />
					<StatsCardSkeleton />
					<StatsCardSkeleton />
				</>
			) : (
				<>
					{stats.map((stat) => (
						<div className='col-span-12 sm:col-span-6 lg:col-span-3' key={stat.key}>
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<StatsCard stat={stat} {...STATS_META[stat.key as CustomerStatsKeys]} />
						</div>
					))}
				</>
			)}

			{/* <div className='col-span-12 2xl:col-span-8'>area 1</div>
			<div className='col-span-12 2xl:col-span-4'>area 2</div>
			<div className='col-span-12 2xl:col-span-8'>area 3</div>
			<div className='col-span-12 2xl:col-span-4'>area 4</div> */}
		</div>
	);
};

export default CustomerDashboard;
