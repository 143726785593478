import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { orderActions } from '../store/orderSlice';

const useOrderStore = () => {
	const state = useSelector((st: RootState) => st.order);

	return {
		state,
		cart: state.cart,
		orders: state.orders,
		cartLength: state.cart?.items.length ?? 0,
		cartItems: state.cart?.items ?? [],
		...orderActions,
	};
};

export default useOrderStore;
