import Nav, { NavItem } from '../../../../components/layouts/Navigation/Nav';
import { appPages, customerPages } from '../../../../config/pages.config';

import Badge from '../../../../components/ui/Badge';
import useOrderSore from '../../../../hooks/useOrderStore';

const CustomerSidebar = () => {
	const { cartLength } = useOrderSore();

	return (
		<Nav>
			<NavItem {...appPages.dashboard} />
			<NavItem {...customerPages.orders} />
			<NavItem {...customerPages.newOrder}>
				{!!cartLength && (
					<Badge
						variant='solid'
						color='blue'
						className='!border-transparent leading-none'>
						{cartLength}
					</Badge>
				)}
			</NavItem>
		</Nav>
	);
};

export default CustomerSidebar;
