import Nav, { NavItem } from '../../../../components/layouts/Navigation/Nav';
import { appPages, deliveryCompanyPages } from '../../../../config/pages.config';
import useUserStore from '../../../../hooks/useUserStore';

const DeliveryCompanySidebar = () => {
	const { state: user } = useUserStore();

	return (
		<Nav>
			<NavItem {...appPages.dashboard} />
			{user.deliveryService?.actived ? (
				<>
					<NavItem {...deliveryCompanyPages.orders} />
					<NavItem {...deliveryCompanyPages.customers} />
				</>
			) : null}
			<NavItem {...deliveryCompanyPages.settings} />
		</Nav>
	);
};

export default DeliveryCompanySidebar;
