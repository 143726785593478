import { OrderHistoryAction } from '@devschap/order-point-types/types/order';
import { useMemo } from 'react';
import { TColors } from '../types/colors.type';
import { OrderStatus } from '../types/shared-enums';

type ItemProps = {
	icon: string | undefined;
	color: TColors | undefined;
};

const useGetTimelineIcon = (action: OrderHistoryAction) => {
	const { icon, color }: ItemProps = useMemo(() => {
		switch (action) {
			case 'create':
				return {
					icon: 'HeroPlusCircle',
					color: 'emerald',
				};
			case 'update-product':
				return {
					icon: 'HeroPencilSquare',
					color: 'zinc',
				};
			case 'validate':
				return {
					icon: 'HeroCheckCircle',
					color: 'sky',
				};
			case 'pay':
				return {
					icon: 'HeroBanknotes',
					color: 'emerald',
				};
			case OrderStatus.waitingForPayment:
				return {
					icon: 'HeroCheckCircle',
					color: 'amber',
				};
			case OrderStatus.inprogress:
				return {
					icon: 'HeroShoppingCart',
					color: 'violet',
				};
			case OrderStatus.shipped:
				return {
					icon: 'HeroTruck',
					color: 'emerald',
				};

			default:
				return {
					icon: undefined,
					color: undefined,
				};
		}
	}, [action]);

	return { icon, color };
};

export default useGetTimelineIcon;
