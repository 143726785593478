import { countries } from 'countries-list';
import { TSelectOptions } from '../../components/form/SelectReact';
import { TColors } from '../../types/colors.type';

const CART_HELPS = [
	{
		title: "Quels sont les fournisseurs d'article disponible ?",
		description:
			"Vous pouvez commander sur  les sites internet ci-dessous. Si vous ne retrouvez pas le site de votre choix, veuillez contacter le service supoort et nous l'ajouterons. Merci",
		links: [
			'https://www.amazon.com',
			'https://www.amazon.fr',
			'https://www.ebay.com',
			'https://www.ebay.fr',
			'https://www.zara.com',
			'https://www.shein.com',
			'https://fr.shein.com',
		],
	},
	{
		title: 'Comment ajouter un produit ?',
		description: 'Pour ajouter un produit :',
		listItems: [
			'Ouvrez un article sur le site de votre fournisseur',
			'Assurez-vous que le produit soit bien disponible',
			'Ajoutez votre configuration si possible (couleur, taille, poids etc...)',
			"Copiez le lien de l'article depuis la barre de navigation",
			"Collez le lien dans le champs 'Lien du produit' sur notre platforme",
			"Cliquer sur le button 'Ajouter' pour renregistrer le produit dans votre panier",
		],
	},
	{
		title: 'Comment retirer un produit de mon panier ?',
		description:
			"Pour retirer un produit de votre panier, il vous suffit de diminuer sa quantité jusqu'à 1, ensuite cliquez sur l'icon de suppression rouge",
	},
];

const ORDER_STATUS_MAP: {
	[key: string]: {
		text: string;
		color: TColors;
	};
} = {
	processing: {
		text: 'En cours de traitement',
		color: 'blue',
	},
	waitingForPayment: {
		text: 'En attente de paiement',
		color: 'amber',
	},
	rejected: {
		text: 'Rejetée',
		color: 'red',
	},
	inprogress: {
		text: 'En cours',
		color: 'violet',
	},
	cancelled: {
		text: 'Annulée',
		color: 'red',
	},
	delivered: {
		text: 'Délivrée',
		color: 'emerald',
	},
	shipped: {
		text: 'Expediée',
		color: 'emerald',
	},
};

const FEEDBACKTYPE_MAPS: {
	[key: string]: {
		label: string;
		color: TColors;
	};
} = {
	testimonial: {
		color: 'emerald',
		label: 'Temoignage',
	},
	issue: {
		color: 'red',
		label: 'Bug',
	},
	suggestion: {
		color: 'violet',
		label: 'Suggestion',
	},
};

const ORDER_STATUSES = Object.entries(ORDER_STATUS_MAP).map((entry) => ({
	status: entry[0],
	...entry[1],
}));

const COUNTRY_OPTIONS: TSelectOptions = Object.entries(countries)
	.map(([code, country]) => ({
		value: code,
		label: country.name,
	}))
	.sort((a, b) => a.label.localeCompare(b.label));

// const CC = COUNTRY_OPTIONS.filter(Boolean);

const FORMATTED_STATUS = ORDER_STATUSES.map((i) => ({
	value: i.status,
	label: i.text,
}));

export default {
	CART_HELPS,
	ORDER_STATUS_MAP,
	FEEDBACKTYPE_MAPS,
	ORDER_STATUSES,
	COUNTRY_OPTIONS,
	FORMATTED_STATUS,
};
