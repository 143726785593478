import Badge from '../ui/Badge';

type Props = {
	actived: boolean;
};

const DeliveryCompanyStatus = ({ actived }: Props) => {
	return (
		<Badge
			className='whitespace-nowrap border-transparent text-sm'
			variant='solid'
			color={actived ? 'emerald' : 'red'}>
			{actived ? 'Actif' : 'Inactif'}
		</Badge>
	);
};

export default DeliveryCompanyStatus;
