import { OrOrderItem } from '@devschap/order-point-types';
import { useMemo, useState } from 'react';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import HtmlViewer from '../../../components/HtmlViewer';
import OpenInstruction from '../../../components/OpenInstruction';
import ProductImg from '../../../components/ProductImg';
import Badge from '../../../components/ui/Badge';
import BadgeCell from '../../../components/ui/BadgeCell';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import CurrencyCell from '../../../components/ui/CurrencyCell';
import Modal from '../../../components/ui/Modal';
import { OrderStatus } from '../../../types/shared-enums';
import { limitText } from '../../../utils/ui.util';

type Props = {
	products: OrOrderItem[];
	orderStatus: OrderStatus;
};

const cols: DatagridColumnDef[] = [
	{
		field: 'image',
		label: 'Image',
	},
	{
		field: 'name',
		label: 'Nom',
		children: ['status', 'infos'],
	},

	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'quantity',
		label: 'Qt',
		children: ['unitPrice', 'amount'],
	},
	{
		field: 'unitPrice',
		label: 'PU',
	},
	{
		field: 'amount',
		label: 'Montant',
	},
	{
		field: 'infos',
		label: 'Infos',
	},
];

const EmptyCell = () => <span className='hidden lg:inline'>-</span>;

const OrderItemsTable = ({ products, orderStatus }: Props) => {
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [selectedProductInfo, setSelectedProductInfo] = useState<string | null>(null);
	const processing = orderStatus === OrderStatus.processing;

	const rows = useMemo(() => {
		return products.map((p) => ({
			image: (
				<ProductImg pictureUrl={p.pictureUrl} productName={p.productName ?? p.pictureUrl} />
			),

			name: (
				<div>
					{limitText(p.productName ?? p.productUrl, 100)}
					{p.instructions ? <OpenInstruction instructions={p.instructions} /> : null}
				</div>
			),

			status: processing ? (
				<EmptyCell />
			) : (
				<span className='flex flex-col space-y-2'>
					<BadgeCell value={p.accepted} label={p.accepted ? 'Accepté' : 'Rejeté'} />
					{p.accepted ? (
						<BadgeCell
							value={p.inStock}
							label={p.inStock ? 'En stock' : 'Rupture de stock'}
						/>
					) : null}
				</span>
			),
			quantity: <span>x{p.quantity}</span>,
			unitPrice: processing ? (
				<EmptyCell />
			) : (
				<CurrencyCell value={p.originalPrice} currency={p.originalCurrency} />
			),
			amount: processing ? (
				<EmptyCell />
			) : (
				<div className='border-t pt-1 lg:border-0 lg:pt-0 font-semibold'>
					<CurrencyCell value={p.convertedPrice} />
				</div>
			),
			infos: p.additionalInfo ? (
				<Button
					className='whitespace-nowrap !p-0 text-blue-400 underline'
					variant='default'
					onClick={() => {
						setModalStatus(true);
						setSelectedProductInfo(p.additionalInfo);
					}}>
					Lire
				</Button>
			) : null,
		}));
	}, [products, processing]);

	return (
		<>
			<Card
				header={
					<>
						Produits
						{products.length ? (
							<Badge className='ml-2 border-transparent text-sm' variant='outline'>
								{products.length}
							</Badge>
						) : null}
					</>
				}>
				<DataGrid rows={rows} columns={cols} keyPrefix='product' />
			</Card>

			{selectedProductInfo ? (
				<Modal
					isOpen={modalStatus}
					size='sm'
					setIsOpen={setModalStatus}
					title='Informations'
					footer={
						<Button
							variant='solid'
							className='w-full'
							onClick={() => setModalStatus(false)}>
							Fermer
						</Button>
					}>
					<HtmlViewer html={selectedProductInfo} />
				</Modal>
			) : null}
		</>
	);
};

export default OrderItemsTable;
