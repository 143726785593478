import { FirebaseError } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAxiosFunction from '../../hooks/useAxiosFunction';

import { authPages } from '../../config/pages.config';
import useFetchUser from '../../hooks/useFetchUser';
import useCustomToast from '../../hooks/useToast';
import useUserStore from '../../hooks/useUserStore';
import { SigninResponse } from '../../types/api';

import { AccountRole } from '../../types/shared-enums';
import Button from '../ui/Button';

const GoogleSignIn = () => {
	const [isGoogleSignInInProgress, setGoogleSignInInProgress] = useState(false);
	const { setAccountData } = useUserStore();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();
	const { fetchUser } = useFetchUser();

	const handleClick = async () => {
		setGoogleSignInInProgress(true);
		const auth = getAuth();
		const provider = new GoogleAuthProvider();

		// Configure the Google Sign-In to prompt the user for account selection
		provider.setCustomParameters({
			prompt: 'select_account',
		});
		try {
			await auth.signOut();
			const result = await signInWithPopup(auth, provider);
			const credential = GoogleAuthProvider.credentialFromResult(result);

			if (!credential?.accessToken) {
				// DO SOMETHING HERE
				customToast.error("Nous n'avons pas pu vous authentifier chez Google.");
				return;
			}

			const { email, emailVerified } = result.user;

			if (!email || !emailVerified) {
				customToast.error('Une erreur est survenue. Veuillez réssayer');
				return;
			}

			const response = await axiosFetch<SigninResponse>({
				method: 'POST',
				url: '/api/auth/sso',
				requestBody: {
					email,
					provider: 'google',
					isVerified: emailVerified,
				},
			});

			if (response === undefined) return;

			const { jwt, account } = response;

			localStorage.setItem('token', jwt);

			if (account.role === AccountRole.anonymous) {
				dispatch(
					setAccountData({
						accountId: account.id,
						email: account.email,
						provider: account.provider,
						verified: emailVerified,
						actived: account.actived,
						userType: account.role,
					}),
				);
				navigate(authPages.onboardingPage.to, { replace: true });
			} else {
				await fetchUser();
			}
		} catch (error: any) {
			let msg = 'Une erreur est survenue';
			if (error instanceof FirebaseError) {
				msg = 'Veuillez vérifier votre connexion ou réessayer plus tard.';
			}
			if (error instanceof Error) {
				msg = error.message;
			}
			customToast.error(msg);
		} finally {
			setGoogleSignInInProgress(false);
		}
	};

	return (
		<div className='col-span-6'>
			<Button
				icon='CustomGoogle'
				variant='outline'
				color='zinc'
				size='lg'
				className='w-full'
				isDisable={isGoogleSignInInProgress}
				onClick={handleClick}
				isLoading={isGoogleSignInInProgress}>
				Google
			</Button>
		</div>
	);
};

export default GoogleSignIn;
