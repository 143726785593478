import { countries } from 'countries-list';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { SingleValue } from 'react-select';
import Input from './form/Input';
import SelectReact, { TSelectOption, TSelectOptions } from './form/SelectReact';

const phoneOptions: TSelectOptions = Object.values(countries).map((country) => {
	const v = `+${country.phone[0].toString()}`;
	return {
		label: v,
		value: v,
	};
});

const DEFAULT_COUNTRY = '+229';

// /^[0-9+\s]+$/

export interface IPhoneNumber {
	indicatif: string;
	number: string;
}
interface Props {
	placeholder?: string;
	phoneNumber: IPhoneNumber;
	setPhoneNumber: Dispatch<SetStateAction<IPhoneNumber>>;
}

const PhoneNumberInput: FC<Props> = ({ placeholder, phoneNumber, setPhoneNumber }) => {
	// {phoneNumberData, setPhoneNumberData} = props
	const [selectedIndicatif, setSelectedIndicatif] = useState<TSelectOption>({
		value: phoneNumber.indicatif || DEFAULT_COUNTRY,
		label: phoneNumber.indicatif || DEFAULT_COUNTRY,
	});

	const onIndicatifChange = useCallback(
		(option: SingleValue<TSelectOption>) => {
			if (!option) return;

			// onChanged(value?.value);
			setSelectedIndicatif(option);
			setPhoneNumber({
				indicatif: option.value,
				number: phoneNumber.number ?? '',
			});
		},
		[setPhoneNumber, phoneNumber],
	);

	const onPhoneChange = useCallback(
		(val: string) => {
			if (!selectedIndicatif) return;

			setPhoneNumber({
				indicatif: selectedIndicatif.value,
				number: val,
			});
		},
		[setPhoneNumber, selectedIndicatif],
	);

	return (
		<div className='flex gap-3'>
			<div className='flex-shrink-0 w-32'>
				<SelectReact
					options={phoneOptions}
					name='options'
					value={selectedIndicatif}
					onChange={(value) => {
						onIndicatifChange(value as SingleValue<TSelectOption>);
					}}
					className='text-center'
				/>
			</div>
			<div className='w-full'>
				<Input
					id='phone'
					name='phone'
					type='tel'
					onChange={(e) => onPhoneChange(e.target.value)}
					value={phoneNumber.number}
					placeholder={placeholder}
				/>
			</div>
		</div>
	);
};

PhoneNumberInput.defaultProps = {
	placeholder: 'Téléphone',
};

export default PhoneNumberInput;
