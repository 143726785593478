import { RouteProps } from 'react-router-dom';
import pagesConfig, { appPages, authPages } from '../config/pages.config';
import DashboardPage from '../pages/Dashboard.page';
import ForgotPassword from '../pages/ForgotPassword.page';
import LoginPage from '../pages/Login.page';
import NotFoundPage from '../pages/NotFound.page';
import PrivacyPolicyPage from '../pages/PrivacyPolicy.page';
import ResetPasswordPage from '../pages/ResetPassword.page';
import SignupPage from '../pages/Signup.page';
import TermsOfUsePage from '../pages/TermsOfUse.page';
import OnboardingPage from '../pages/onbaording/Onboarding.page';

const contentRoutes: RouteProps[] = [
	/**
	 * AUTH::BEGIN
	 */
	{ path: authPages.signupPage.to, element: <SignupPage /> },
	{ path: authPages.loginPage.to, element: <LoginPage /> },

	{ path: authPages.resetPasswordPage.to, element: <ResetPasswordPage /> },
	{ path: authPages.forgotPasswordPage.to, element: <ForgotPassword /> },
	{
		path: authPages.onboardingPage.to,
		element: <OnboardingPage />,
	},
	{
		path: appPages.dashboard.to,
		element: <DashboardPage />,
	},
	{ path: pagesConfig.privacyPolicyPage.to, element: <PrivacyPolicyPage /> },
	{ path: pagesConfig.termsOfUsePage.to, element: <TermsOfUsePage /> },
	{ path: '*', element: <NotFoundPage /> },
];

export default contentRoutes;
