import GoogleSignIn from './GoogleSignin';

const SocialAuthButtons = () => {
	return (
		<GoogleSignIn />
		// <div className='grid grid-cols-12 gap-4'>
		// 	<div className='col-span-6'>
		// 	</div>
		// 	<div className='col-span-6'>
		// 		<Button
		// 			icon='CustomApple'
		// 			variant='outline'
		// 			color='zinc'
		// 			size='lg'
		// 			className='w-full'>
		// 			Apple
		// 		</Button>
		// 	</div>
		// </div>
	);
};

export default SocialAuthButtons;
