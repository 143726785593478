import { useLocation } from 'react-router-dom';

const useIsIndeApp = () => {
	const { pathname } = useLocation();

	return {
		isInsideApp:
			pathname.startsWith('/c/') || pathname.startsWith('/a/') || pathname.startsWith('/dc/'),
		currentLocation: pathname,
	};
};

export default useIsIndeApp;
