import { DcDeliveryCompany } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import AddressDetails from '../../../components/AddressDetails';
import DeliveryCompanyStatus from '../../../components/delivery-company/DeliveryCompanyStatus';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import CopyButton from '../../../components/ui/CopyButton';
import EmptyContent from '../../../components/ui/EmptyContent';
import ExternalLink from '../../../components/ui/ExternalLink';
import ListTile from '../../../components/ui/ListTile';
import Modal from '../../../components/ui/Modal';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../../utils/ui.util';

type Props = {
	companyId: string;
};

const AdminDeliveryCompanyDetails = ({ companyId }: Props) => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();
	const [deliveryCompany, setDeliveryCompany] = useState<DcDeliveryCompany | null>(null);
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [updatingStatus, setUpdatingStatus] = useState(false);

	const actived = Boolean(deliveryCompany?.actived);

	const getDeliveryCompany = useCallback(async () => {
		try {
			const response = await axiosFetch<{ deliveryCompany: DcDeliveryCompany }>({
				method: 'GET',
				url: `/api/admin/delivery-companies/${companyId}/details`,
			});

			if (response?.deliveryCompany) {
				setDeliveryCompany(response.deliveryCompany);
			}
		} catch (error) {
			customToast.error(
				"Une erreur s'est produite lors de la récupération du service de livraison",
			);
		}
	}, [axiosFetch, companyId, customToast]);

	useEffect(() => {
		getDeliveryCompany();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateCompanyStatus = useCallback(async () => {
		if (updatingStatus) return;
		try {
			setUpdatingStatus(true);
			const response = await axiosFetch<{ succeeded: boolean }>({
				method: 'POST',
				url: `/api/admin/delivery-companies/${companyId}/status`,
				requestBody: {
					actived: !actived,
				},
			});

			if (response?.succeeded) {
				customToast.success(
					`Le service de livraison est à présent ${actived ? 'désactivé' : 'activé'}`,
				);
				await getDeliveryCompany();
			}
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la mise à jour du statut");
		} finally {
			setUpdatingStatus(false);
			setModalStatus(false);
		}
	}, [axiosFetch, companyId, customToast, getDeliveryCompany, actived, updatingStatus]);

	if (loading) return <OpLoader />;

	if (!deliveryCompany) return <EmptyContent message='Service de livraison non trouvé' />;

	return (
		<>
			<Card
				header={
					<div className='flex justify-between w-full'>
						<span>Détails</span>
						<Button
							variant='solid'
							size='sm'
							color={actived ? 'red' : 'blue'}
							onClick={() => setModalStatus(true)}>
							{actived ? 'Désactiver' : 'Activer'}
						</Button>
					</div>
				}>
				<div className='flex flex-col space-y-2'>
					<ListTile label='ID' value={deliveryCompany.id} />
					<ListTile label='Nom' value={deliveryCompany.label} />
					<ListTile
						label='Statut'
						value={<DeliveryCompanyStatus actived={deliveryCompany.actived} />}
					/>
					<ListTile
						label='IFU'
						description='Identifiant Fiscal Unique'
						value={deliveryCompany.ifu}
					/>
					<ListTile label='Téléphone 1' value={deliveryCompany.telephone1} />
					{deliveryCompany.telephone2 ? (
						<ListTile label='Téléphone 2' value={deliveryCompany.telephone2} />
					) : null}
					<ListTile label='Inscrit le' value={formatDate(deliveryCompany.createdAt)} />
					{deliveryCompany.referralCode ? (
						<ListTile
							label='Code de parainage'
							value={<CopyButton text={deliveryCompany.referralCode} />}
						/>
					) : null}

					{deliveryCompany.pageFacebook ? (
						<ListTile
							label='Page Facebook'
							value={
								<ExternalLink text='Consulter' url={deliveryCompany.pageFacebook} />
							}
						/>
					) : null}
					{deliveryCompany.pageTiktok ? (
						<ListTile
							label='Page TikTok'
							value={
								<ExternalLink text='Consulter' url={deliveryCompany.pageTiktok} />
							}
						/>
					) : null}

					{deliveryCompany.pageInstagram ? (
						<ListTile
							label='Page Instagram'
							value={
								<ExternalLink
									text='Consulter'
									url={deliveryCompany.pageInstagram}
								/>
							}
						/>
					) : null}
					{deliveryCompany.webSiteUrl ? (
						<ListTile
							label='Site internet'
							value={
								<ExternalLink text='Consulter' url={deliveryCompany.webSiteUrl} />
							}
						/>
					) : null}
				</div>
			</Card>

			<AddressDetails address={deliveryCompany.address} />

			<Modal
				isOpen={modalStatus}
				size='sm'
				setIsOpen={setModalStatus}
				title={actived ? 'Désactivation du service' : 'Activation du service'}
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={() => setModalStatus(false)}>
							Non
						</Button>
						<Button
							variant='solid'
							className='w-full'
							isLoading={updatingStatus}
							onClick={updateCompanyStatus}>
							oui
						</Button>
					</>
				}>
				Souhaitez-vous vraiment {actived ? 'désactiver' : 'activer'} le service de livraison
				<span className='ml-1 font-bold'>{deliveryCompany.label}</span> ?
			</Modal>
		</>
	);
};

export default AdminDeliveryCompanyDetails;
