import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Badge from '../../../components/ui/Badge';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import DataGridFilters from '../../../components/ui/DataGrid/DataGridFilters';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import { adminPages } from '../../../config/pages.config';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { FilterOption } from '../../../types/common/filters.type';
import { formatDate, getCountryName } from '../../../utils/ui.util';
import constants from '../../customer/constants';
import useFetchAdminCustomers from '../hooks/useFetchAdminCustomers';

const cols: DatagridColumnDef[] = [
	{
		field: 'id',
		label: 'ID',
	},
	{
		field: 'fullName',
		label: 'Nom complet',
		children: ['country', 'phoneNumber'],
	},
	{
		field: 'country',
		label: 'Pays',
	},
	{
		field: 'phoneNumber',
		label: 'Numéro',
	},
	{
		field: 'createdAt',
		label: 'Inscrit le',
		// hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const filters: FilterOption[] = [
	{
		filterKey: 'country_ids',
		label: 'Pays',
		options: constants.COUNTRY_OPTIONS,
		type: 'multiple',
	},
	{
		filterKey: 'date',
		label: 'Date de commandes',
		type: 'dateRange',
	},
];

const AdminCustomersPage = () => {
	const { customers, loadMore, isLoadingMore, loading } = useFetchAdminCustomers();

	const navigate = useNavigate();

	const openCustomersDetailsPage = useCallback(
		(customerId: number) => {
			navigate(generatePath(adminPages.customer.to, { customerId }));
		},
		[navigate],
	);

	const rows = useMemo(() => {
		return customers.map((customer) => ({
			id: customer.id,
			fullName: `${customer.lastName} ${customer.firstName}`,
			country: getCountryName(customer.address.countryIso),
			phoneNumber: customer.telephone1,
			createdAt: formatDate(customer.createdAt),
			actions: (
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
					</DropdownToggle>
					<DropdownMenu placement='bottom-end'>
						<DropdownItem
							onClick={() => openCustomersDetailsPage(customer.id)}
							icon='HeroEye'>
							voir
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			),
		}));
	}, [customers, openCustomersDetailsPage]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Clients' currentPage='Clients' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Clients'>
				<Container>
					<DataGridFilters filters={filters} />
					<Card
						header={
							<>
								Clients
								{customers.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{customers.length}
									</Badge>
								) : null}
							</>
						}>
						{loading ? (
							<OpLoader />
						) : (
							<DataGrid
								keyPrefix='customers'
								columns={cols}
								rows={rows}
								loadMore={loadMore}
								isLoadingMore={isLoadingMore}
							/>
						)}
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminCustomersPage;
