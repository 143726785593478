import { useMemo } from 'react';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Card from '../../../components/ui/Card';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';

import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import OrderStatusBadge from '../../../components/OrderStatusBadge';
import AvatarsGroup from '../../../components/ui/AvatarsGroup';
import Badge from '../../../components/ui/Badge';
import DataGridFilters from '../../../components/ui/DataGrid/DataGridFilters';
import EmptyContent from '../../../components/ui/EmptyContent';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { FilterOption } from '../../../types/common/filters.type';
import { formatDate } from '../../../utils/ui.util';
import constants from '../../customer/constants';
import DeliveryCompanyOrderAction from '../components/DeliveryCompanyOrderAction';
import useDeliveryCompanyFetchOrders from '../hooks/useDeliveryCompanyFetchOrders';

const cols: DatagridColumnDef[] = [
	{
		field: 'id',
		label: 'ID',
	},
	{
		field: 'createdAt',
		label: 'Commande du',
		children: ['code', 'status'],
	},
	{
		field: 'code',
		label: 'Code',
	},
	{
		field: 'products',
		label: 'Produits',
	},
	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'deliveryDate',
		label: 'Date de livraison',
		hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const filters: FilterOption[] = [
	{
		filterKey: 'statuses',
		label: 'Statut',
		options: constants.FORMATTED_STATUS,
		type: 'multiple',
	},
	{
		filterKey: 'date',
		label: 'Date de commandes',
		type: 'dateRange',
	},
];

const DeliveryCompanyOrdersPage = () => {
	const { loading, orders, loadMore, isLoadingMore } = useDeliveryCompanyFetchOrders();

	const rows = useMemo(() => {
		return orders.map((order) => ({
			id: order.orderId,
			createdAt: formatDate(order.createdAt),
			code: order.code,
			products: <AvatarsGroup avatars={order.items} />,
			status: <OrderStatusBadge status={order.status} />,
			deliveryDate: order.deliveryDate ?? '-',
			actions: <DeliveryCompanyOrderAction orderId={order.orderId} />,
		}));
	}, [orders]);

	const displayContent = useMemo(() => {
		return rows.length === 0 ? (
			<EmptyContent message='Pas de commande' />
		) : (
			<DataGrid
				rows={rows}
				columns={cols}
				keyPrefix='orders'
				loadMore={loadMore}
				isLoadingMore={isLoadingMore}
			/>
		);
	}, [rows, isLoadingMore, loadMore]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Commandes' currentPage='Commandes' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Commandes'>
				<Container>
					<DataGridFilters filters={filters} />
					<Card
						className='h-full'
						header={
							<>
								Commandes
								{orders.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{orders.length}
									</Badge>
								) : null}
							</>
						}
						bodyClassName='overflow-x-scroll'>
						{loading ? <OpLoader /> : displayContent}
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default DeliveryCompanyOrdersPage;
