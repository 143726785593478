import { CSSProperties } from 'react';
import toast from 'react-hot-toast';

const toastStyle: CSSProperties = {
	background: '#333',
	color: '#fff',
};

const useCustomToast = () => {
	const error = (text: string) => {
		toast.error(text, {
			position: 'bottom-center',
			duration: 3000,
			style: toastStyle,
		});
	};

	const success = (text: string) => {
		toast.success(text, {
			position: 'bottom-center',
			duration: 3000,
			style: toastStyle,
		});
	};

	return {
		error,
		success,
	};
};

export default useCustomToast;
