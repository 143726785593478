import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAxiosFunction from '../../hooks/useAxiosFunction';
import useCustomToast from '../../hooks/useToast';
import FieldWrap from '../form/FieldWrap';
import Textarea from '../form/Textarea';
import Validation from '../form/Validation';
import Button from '../ui/Button';

type TValues = {
	description: string | null;
};

const ReportIssue = () => {
	const { axiosFetch } = useAxiosFunction();
	const customToast = useCustomToast();

	const feedbackSchema = Yup.object().shape({
		description: Yup.string(),
	});

	const formik = useFormik({
		initialValues: {
			description: '',
		},
		validationSchema: feedbackSchema,
		onSubmit: async (values: TValues, { setSubmitting }) => {
			try {
				const response = await axiosFetch<{ succeeded: boolean }>({
					method: 'POST',
					url: '/api/user/feedback/issue',
					requestBody: {
						rating: null,
						content: values.description,
					},
				});

				if (response?.succeeded) {
					customToast.success('Votre message a bien été envoyé');
					formik.resetForm();
				}
			} catch (e: any) {
				customToast.error('Une erreur est survenue');
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<div>
			<Validation
				isValid={formik.isValid}
				isTouched={formik.touched.description}
				invalidFeedback={formik.errors.description}>
				<FieldWrap>
					<Textarea
						id='description'
						autoComplete='description'
						className='bg-zinc-500/10 p-6 text-base'
						name='description'
						placeholder='Veuillez décrire le problème que vous avez rencontré'
						value={formik.values.description ?? ''}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						rows={6}
					/>
				</FieldWrap>
			</Validation>
			<Button
				className='mt-4 w-full'
				size='lg'
				variant='solid'
				isLoading={formik.isSubmitting}
				isDisable={formik.isSubmitting}
				onClick={() => formik.handleSubmit()}>
				Soumettre
			</Button>
		</div>
	);
};

export default ReportIssue;
