import Container from '../../../components/layouts/Container/Container';

const OpLoader = () => {
	return (
		<Container className='flex justify-center items-center h-full'>
			{/* eslint-disable-next-line react/self-closing-comp */}
			<div className='border-t-4 border-blue-500 border-solid rounded-full animate-spin h-16 w-16'></div>
		</Container>
	);
};

export default OpLoader;
