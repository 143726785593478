import { RouteProps } from 'react-router-dom';

import { customerPages } from '../../config/pages.config';
import { CheckoutPage } from '../../pages/customer/pages/Checkout.page';
import CustomerCartPage from '../../pages/customer/pages/CustomerCart.page';
import CustomerOrderDetailsPage from '../../pages/customer/pages/CustomerOrderDetails.page';
import CustomerOrdersPage from '../../pages/customer/pages/CustomerOrders.page';
import CustomerProfilePage from '../../pages/customer/pages/CustomerProfile.page';

const customerRoutes: RouteProps[] = [
	{
		path: customerPages.newOrder.to,
		element: <CustomerCartPage />,
	},
	{
		path: customerPages.orders.to,
		element: <CustomerOrdersPage />,
	},
	{
		path: customerPages.order.to,
		element: <CustomerOrderDetailsPage />,
	},
	{ path: customerPages.profile.to, element: <CustomerProfilePage /> },

	{ path: customerPages.checkout.to, element: <CheckoutPage /> },
];

export default customerRoutes;
