import { DeliveryCompanyRawData, OrOrderCompleteFeesData } from '@devschap/order-point-types';
import { useCallback, useEffect, useState } from 'react';
import Icon from '../../../components/icon/Icon';
import OrderBilling from '../../../components/order/OrderBilling';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import ListTile from '../../../components/ui/ListTile';
import Modal from '../../../components/ui/Modal';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import AdminDeliveryCompaniesWithPricingRules from './AdminDeliveryCompaniesWithPricingRules';

type TProps = {
	orderId: number;
	canBeUpdated: boolean;
};

const AdminOrderBilling = ({ orderId, canBeUpdated }: TProps) => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();

	const [deliveryCompany, setDeliveryCompany] = useState<DeliveryCompanyRawData | null>(null);
	const [fees, setFees] = useState<OrOrderCompleteFeesData | null>(null);
	const [deliveryCompanies, setDeliveryCompanies] = useState<DeliveryCompanyRawData[]>([]);
	const [modalStatus, setModalStatus] = useState<boolean>(false);

	const getDeliveryCompanyAndFees = useCallback(async () => {
		try {
			const response = await axiosFetch<{
				deliveryCompany: DeliveryCompanyRawData;
				fees: OrOrderCompleteFeesData;
			}>({
				method: 'GET',
				url: `/api/admin/orders/${orderId}/fees`,
			});

			if (response?.deliveryCompany) {
				setDeliveryCompany(response.deliveryCompany);
			}
			if (response?.fees) {
				setFees(response.fees);
			}
		} catch (error) {
			customToast.error(
				"Une erreur s'est produite lors de la récupération des service de livraison",
			);
		}
	}, [axiosFetch, orderId, customToast]);

	const getDeliveryCompaniesWithPricingRules = useCallback(async () => {
		if (!canBeUpdated) return;
		try {
			const response = await axiosFetch<{
				data: DeliveryCompanyRawData[];
			}>({
				method: 'GET',
				url: '/api/admin/delivery-companies-with-pricing-rules',
			});

			if (response?.data) {
				setDeliveryCompanies(response.data);
			}
		} catch (error) {
			customToast.error(
				"Une erreur s'est produite lors de la récupération des service de livraison",
			);
		}
	}, [axiosFetch, customToast, canBeUpdated]);

	const associateOrderToDeliveryCompany = useCallback(async () => {
		if (!canBeUpdated) return;
		try {
			const response = await axiosFetch<{
				data: OrOrderCompleteFeesData;
			}>({
				method: 'POST',
				url: 'api/admin/order/link-to-delivery-company',
				requestBody: {
					orderId,
					deliveryCompanyId: deliveryCompany?.id,
				},
			});

			if (response?.data) {
				customToast.success('Service de liaison associé avec succès');
				setModalStatus(false);
				setFees(response.data);
			}
		} catch (error) {
			customToast.error('Une erreur est survenue. veuillez rafraichir la page');
		}
	}, [axiosFetch, customToast, orderId, deliveryCompany?.id, canBeUpdated]);

	useEffect(() => {
		getDeliveryCompanyAndFees();
		getDeliveryCompaniesWithPricingRules();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hasDeliveryCompanies = Boolean(deliveryCompanies.length);

	return (
		<>
			<Card header='Service de livraison'>
				<ListTile
					label='Traitée par'
					value={
						<div className='flex justify-center items-center'>
							<span className='mr-2'> {deliveryCompany?.label}</span>
							{canBeUpdated ? (
								<Icon
									icon='HeroPencil'
									size='text-lg'
									className='cursor-pointer'
									onClick={() => setModalStatus(true)}
								/>
							) : null}
						</div>
					}
				/>
			</Card>
			<OrderBilling fees={fees} loading={loading} />

			<Modal
				isOpen={modalStatus}
				size='sm'
				setIsOpen={setModalStatus}
				title='Services de livraison'
				footer={
					<>
						<Button
							variant='outline'
							color='zinc'
							className='w-full'
							onClick={() => setModalStatus(false)}>
							Fermer
						</Button>

						{hasDeliveryCompanies ? (
							<Button
								variant='solid'
								className='w-full'
								onClick={associateOrderToDeliveryCompany}>
								Associer
							</Button>
						) : null}
					</>
				}>
				{hasDeliveryCompanies ? (
					deliveryCompanies.map((d) => (
						<AdminDeliveryCompaniesWithPricingRules
							deliveryCompany={d}
							key={d.id}
							selectedDeliveryCompanyId={deliveryCompany?.id}
							setDeliveryCompany={setDeliveryCompany}
						/>
					))
				) : (
					<EmptyContent message='Pas de services de livraison' />
				)}
			</Modal>
		</>
	);
};

export default AdminOrderBilling;
