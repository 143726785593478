import { CustomerOrderProgression } from '@devschap/order-point-types/types/order';
import { useMemo } from 'react';
import { TimelineItem } from '../../../components/Timeline';
import useGetTimelineIcon from '../../../hooks/useGetTimelineIcon';
import { OrderStatus } from '../../../types/shared-enums';
import { formatDate } from '../../../utils/ui.util';

interface ActionDetails {
	item: CustomerOrderProgression;
}

const OrderTimelimeItem = ({ item }: ActionDetails) => {
	const formatedDate = formatDate(item.addedAt, true);

	const { icon, color } = useGetTimelineIcon(item.action);

	const children = useMemo(() => {
		switch (item.action) {
			case 'create':
				return `Commande créée le ${formatedDate}`;
			case 'update-product':
				return `Un produit de cette commande a été traité le ${formatedDate}`;

			case 'validate':
				return `Commande validée par notre service le ${formatedDate}`;

			case 'pay':
				return `Félicitation! Vous avez effectué avec succès le paiement de votre commande le ${formatedDate}`;

			case OrderStatus.waitingForPayment:
				return `Votre commande a été traitée et est en attente de paiement.`;

			case OrderStatus.inprogress:
				return `Votre commande est en cours.`;

			case OrderStatus.shipped:
				return `Youpi !!! Votre commande a été expédiée le ${formatedDate}.`;

			default:
				return undefined;
		}
	}, [item.action, formatedDate]);

	return (
		<TimelineItem icon={icon} color={color}>
			<span className='text-sm'>{children}</span>
		</TimelineItem>
	);
};

export default OrderTimelimeItem;
