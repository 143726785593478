import { CuCustomer } from '@devschap/order-point-types';
import 'dayjs/locale/fr';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import PhoneNumberInput, { IPhoneNumber } from '../../../components/PhoneNumberInput';
import FieldWrap from '../../../components/form/FieldWrap';
import Input from '../../../components/form/Input';
import Validation from '../../../components/form/Validation';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/ui/Button';
import { appPages } from '../../../config/pages.config';
import useAddressForm from '../../../hooks/useAddressForm';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import useUserStore from '../../../hooks/useUserStore';
import { AccountRole } from '../../../types/shared-enums';
import { formatOptionalString, formatPhoneNumber, validPhoneNumber } from '../../../utils/ui.util';
import OnboardingSubtitle from './Subtitle';

export type TValues = {
	firstName: string;
	lastName: string;
	dcReferralCode: string;
};

const customerSchema = Yup.object().shape({
	firstName: Yup.string().required('Le prenom est obligatoire'),
	lastName: Yup.string().required('Le nom est obligatoire'),
});

const CustomerOnboarding = () => {
	const { setCustomerData } = useUserStore();
	const customToast = useCustomToast();
	const { axiosFetch } = useAxiosFunction();
	const { addressFormik, AddressForm, validateAddressForm, country } = useAddressForm();
	const dispatch = useDispatch();

	const [telephone1, setTelephone1] = useState<IPhoneNumber>({
		indicatif: '',
		number: '',
	});

	const navigate = useNavigate();
	const referralCode = localStorage.getItem('referralCode') ?? '';

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			dcReferralCode: referralCode,
		},
		validationSchema: customerSchema,
		onSubmit: async (values: TValues) => {
			try {
				if (!validateAddressForm()) return;

				if (!validPhoneNumber(telephone1)) {
					customToast.error('Numéro de téléphone incorrect');
					return;
				}

				const response = await axiosFetch<{ customer: CuCustomer; jwt: string }>({
					method: 'POST',
					url: '/api/user',
					requestBody: {
						userType: AccountRole.customer,
						firstName: values.firstName,
						lastName: values.lastName,
						telephone1: formatPhoneNumber(telephone1),
						dcReferralCode: formatOptionalString(values.dcReferralCode),
						address: {
							countryIso: country,
							city: addressFormik.values.city,
							district: addressFormik.values.district,
							postalCode: addressFormik.values.postalCode,
						},
					},
				});

				if (response === undefined) return;

				const { customer, jwt } = response;
				localStorage.setItem('token', jwt);

				dispatch(
					setCustomerData({
						address: customer.address,
						customer: {
							id: customer.id,
							firstName: customer.firstName,
							lastName: customer.firstName,
							dcReferralCode: customer.dcReferralCode,
							updatedAt: customer.updatedAt,
						},
						telephone1: customer.telephone1,
						telephone2: customer.telephone2,
					}),
				);
				addressFormik.resetForm();
				localStorage.removeItem('referralCode');
				navigate(appPages.dashboard.to);
			} catch (e: any) {
				customToast.error('Erreur lors de la sauvegarde des données du client.');
			} finally {
				formik.setSubmitting(false);
			}
		},
	});

	return (
		<div className='container'>
			<OnboardingSubtitle text='Veuillez remplir les champs ci dessous afin de nous permettre de mieux gérer vos commandes.' />
			<form className='flex flex-col gap-4' noValidate>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.firstName}
						invalidFeedback={formik.errors.firstName}>
						<FieldWrap firstSuffix={<Icon icon='HeroUser' className='mx-2' />}>
							<Input
								id='name'
								autoComplete='name'
								name='firstName'
								placeholder='Prenom'
								value={formik.values.firstName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.lastName}
						invalidFeedback={formik.errors.lastName}>
						<FieldWrap firstSuffix={<Icon icon='HeroUser' className='mx-2' />}>
							<Input
								id='lastName'
								autoComplete='lastName'
								name='lastName'
								placeholder='Nom de famille'
								value={formik.values.lastName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>

				<PhoneNumberInput
					placeholder='Téléphone'
					phoneNumber={telephone1}
					setPhoneNumber={setTelephone1}
				/>

				<div>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.dcReferralCode}
						invalidFeedback={formik.errors.dcReferralCode}>
						<FieldWrap firstSuffix={<Icon icon='HeroHashtag' className='mx-2' />}>
							<Input
								id='dcReferralCode'
								autoComplete='dcReferralCode'
								name='dcReferralCode'
								placeholder="Code d'inscription"
								value={formik.values.dcReferralCode ?? referralCode}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</FieldWrap>
					</Validation>
				</div>
				{AddressForm}
				<Button
					size='lg'
					isLoading={formik.isSubmitting}
					isDisable={formik.isSubmitting}
					variant='solid'
					className='w-full font-semibold mt-4'
					onClick={() => formik.handleSubmit()}>
					Envoyer
				</Button>
			</form>
		</div>
	);
};

export default CustomerOnboarding;
