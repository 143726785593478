import { useCallback, useEffect, useState } from 'react';
import useCustomToast from '../../../hooks/useToast';

import { LIMIT_PER_PAGE } from '../../../constants/datagrid.constants';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import { Feedback } from '../pages/AdminFeedbacks';

const useAdminFeedbacks = () => {
	const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
	const [offset, setOffset] = useState(0);
	const [showLoadMore, setShowLoadMore] = useState(true);

	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();

	const loadMore = useCallback(() => {
		setOffset((prev) => prev + LIMIT_PER_PAGE);
	}, [setOffset]);

	const getAllFeedbaks = useCallback(async () => {
		try {
			const response = await axiosFetch<{ feedbacks: Feedback[] }>({
				method: 'GET',
				url: '/api/admin/feedbacks',
				queryParams: {
					offset,
					limit: LIMIT_PER_PAGE,
				},
			});

			const newData = response?.feedbacks ?? [];

			if (offset === 0) {
				setFeedbacks(newData);
			} else {
				setFeedbacks((prev) => [...prev, ...newData]);
			}
			setShowLoadMore(Boolean(newData.length));
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la récupération des commentaires");
		}
	}, [axiosFetch, customToast, offset]);

	useEffect(() => {
		getAllFeedbaks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offset]);

	return {
		loading,
		feedbacks,
		showLoadMore,
		loadMore: showLoadMore ? loadMore : undefined,
		isLoadingMore: offset > 0 && loading,
	};
};

export default useAdminFeedbacks;
