import { CuCart, OrOrderMinimal } from '@devschap/order-point-types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrderStatus } from '../types/shared-enums';

export interface IOrderState {
	cart: CuCart | null;
	orders: OrOrderMinimal[];
}

const initialState: IOrderState = {
	cart: null,
	orders: [],
};

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		setCart: (state, action: PayloadAction<CuCart | null>) => {
			state.cart = action.payload;
		},
		setCustomerOrder: (state, action: PayloadAction<OrOrderMinimal[]>) => {
			state.orders = action.payload;
		},
		deteteCustomerOrder: (state, action: PayloadAction<number>) => {
			if (state.orders) {
				state.orders = state.orders.filter((ord) => ord.id !== action.payload);
			}
		},
		cancelCustomerOrder: (state, action: PayloadAction<number>) => {
			const orderId = action.payload;
			const orderToCancel = state.orders.find((ord) => ord.id === orderId);
			if (orderToCancel) {
				orderToCancel.status = OrderStatus.cancelled;
			}
		},

		resetCart: (state) => {
			state.cart = null;
		},

		resetOrder: () => {
			return initialState;
		},
	},
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
