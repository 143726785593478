import { fr } from 'date-fns/locale';
import { useMemo, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import colors from 'tailwindcss/colors';
import themeConfig from '../../../config/theme.config';
import { FilterOption } from '../../../types/common/filters.type';
import { formatDate } from '../../../utils/ui.util';
import Button from '../Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../Dropdown';
import FilterActionButtons from './FilterActionButtons';

type Props = {
	filter: FilterOption;
};

const DateFilter = ({ filter }: Props) => {
	const [openDropdown, setOpenDropdown] = useState<boolean>(false);
	const [dateRange, setDateRange] = useState<Range | null>(null);

	const startDate = dateRange?.startDate;
	const endDate = dateRange?.endDate;

	const displayedLabel = useMemo(() => {
		if (!startDate) return filter.label;

		if (startDate && startDate.getTime() === endDate?.getTime()) {
			return formatDate(startDate, false, 'DD MMM, YYYY');
		}

		return `${formatDate(startDate, false, 'DD MMM, YYYY')} - ${formatDate(
			endDate,
			false,
			'DD MMM, YYYY',
		)}`;
	}, [startDate, endDate, filter.label]);

	const dateMap = [startDate, endDate].map((date) => formatDate(date, false, 'DD-MM-YYYY'));

	return (
		<Dropdown setIsOpen={setOpenDropdown} isOpen={openDropdown}>
			<DropdownToggle>
				<Button className='text-base text-nowrap py-3' variant='outline' color='zinc'>
					{displayedLabel}
				</Button>
			</DropdownToggle>
			<DropdownMenu className='p-5' isCloseAfterLeave={false}>
				<DateRange
					showDateDisplay={false}
					onChange={(item) => setDateRange(item.selection)}
					moveRangeOnFirstSelection={false}
					ranges={[
						dateRange ?? {
							startDate: new Date(),
							endDate: new Date(),
							key: 'selection',
						},
					]}
					locale={fr}
					maxDate={new Date()}
					color={colors[themeConfig.themeColor][themeConfig.themeColorShade]}
				/>
				<FilterActionButtons
					filterKey={filter.filterKey}
					value={startDate && endDate ? dateMap : []}
					onCancel={() => setOpenDropdown(false)}
					onFilter={() => setOpenDropdown(false)}
				/>
			</DropdownMenu>
		</Dropdown>
	);
};

export default DateFilter;
