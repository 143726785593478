import classNames from 'classnames';
import { Fragment, ReactNode, useMemo } from 'react';
import OpLoader from '../templates/layouts/Loader/OpLoader.template';
import Button from './ui/Button';

export type DatagridColumnDef = {
	field: string;
	label: string;

	hideOnMobile?: boolean;
	children?: string[];
};

type Props = {
	keyPrefix: string;
	columns: DatagridColumnDef[];
	rows: { [key: DatagridColumnDef['field']]: ReactNode }[];
	loadMore?(): void;
	isLoadingMore?: boolean;
};

const DataGrid = ({ keyPrefix, columns, rows, loadMore, isLoadingMore }: Props) => {
	const childrenColumns = useMemo(() => {
		return columns.reduce((acc: string[], col) => acc.concat(col.children || []), []);
	}, [columns]);

	return (
		<>
			<table className='min-w-full divide-y divide-gray-300'>
				<thead>
					<tr>
						{columns.map((column) => (
							<th
								key={`th-${keyPrefix}-${column.field}`}
								scope='col'
								className={classNames(
									'whitespace-nowrap py-3.5 px-3 text-left text-sm md:text-md font-semibold text-gray-900 sm:pl-0',
									{
										'hidden lg:table-cell':
											childrenColumns.includes(column.field) ||
											column.hideOnMobile,
									},
								)}>
								{column.label}
							</th>
						))}
					</tr>
				</thead>
				<tbody className='divide-y divide-gray-200 bg-white'>
					{rows.map((row: any, rowIndex) => (
						// eslint-disable-next-line react/no-array-index-key
						<tr key={`tr-${keyPrefix}-${rowIndex}`}>
							{columns.map((column) => (
								<td
									key={`td-${keyPrefix}-${column.field}`}
									className={classNames(
										'py-4 px-3 text-sm md:text-md font-medium text-gray-500 sm:pl-0',
										{
											'hidden lg:table-cell':
												childrenColumns.includes(column.field) ||
												column.hideOnMobile,
											'sm:w-auto text-gray-900': Boolean(column.children),
										},
									)}>
									{/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
									{row[column.field]}
									{column.children ? (
										<dl className='font-normal lg:hidden'>
											{column.children.map((child) => (
												<Fragment key={`stacked-${child}`}>
													<dt className='sr-only'>{child}</dt>
													<dd className='mt-1 truncate text-gray-700'>
														{/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
														{row[child]}
													</dd>
												</Fragment>
											))}
										</dl>
									) : null}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			<div className='flex flex-col justify-center'>
				{isLoadingMore ? <OpLoader /> : null}
				{!isLoadingMore && loadMore ? (
					<Button className='uppercase !text-blue-500 font-bold mt-4' onClick={loadMore}>
						charger plus de données
					</Button>
				) : null}
			</div>
		</>
	);
};

DataGrid.defaultProps = {
	loadMore: undefined,
	isLoadingMore: undefined,
};

export default DataGrid;
