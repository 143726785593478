import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';
import Button from '../../Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../Dropdown';

const HEADINGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

interface EditorMenuButtonProps {
	onClick: () => void;
	isActive?: boolean;
	icon?: string;
	label: string;
	disabled?: boolean;
}

const EditorMenuButton = ({ onClick, icon, isActive, label, disabled }: EditorMenuButtonProps) => {
	return (
		<Button
			className={icon ? '!px-0' : '!px-2'}
			icon={icon}
			onClick={onClick}
			isActive={isActive}
			isDisable={disabled}>
			{icon ? (
				<span className='sr-only'>{label}</span>
			) : (
				<span className='text-base'>{label}</span>
			)}
		</Button>
	);
};

EditorMenuButton.defaultProps = {
	icon: undefined,
	disabled: false,
	isActive: false,
};

type Props = {
	editor: Editor | null;
};

const EditorMenu = ({ editor }: Props) => {
	if (!editor) return null;

	return (
		<>
			<EditorMenuButton
				onClick={() => editor.chain().focus().toggleBold().run()}
				isActive={editor.isActive('bold')}
				icon='DuoBold'
				label='Bold'
			/>
			<EditorMenuButton
				onClick={() => editor.chain().focus().toggleItalic().run()}
				isActive={editor.isActive('italic')}
				icon='DuoItalic'
				label='Italic'
			/>

			<EditorMenuButton
				onClick={() => editor.chain().focus().toggleStrike().run()}
				icon='DuoStrikethrough'
				isActive={editor.isActive('strike')}
				label='Strike'
			/>
			<EditorMenuButton
				onClick={() => editor.chain().focus().setParagraph().run()}
				isActive={editor.isActive('paragraph')}
				icon='DuoParagraph'
				label='Paragraph'
			/>
			{HEADINGS.map((heading, index) => (
				<EditorMenuButton
					onClick={() =>
						editor
							.chain()
							.focus()
							.toggleHeading({ level: (index + 1) as Level })
							.run()
					}
					isActive={editor.isActive('heading', { level: (index + 1) as Level })}
					label={heading}
					key={`h-${index + 1}`}
				/>
			))}
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<Button icon='HeroListBullet' />
				</DropdownToggle>
				<DropdownMenu placement='bottom-end'>
					<DropdownItem
						onClick={() => editor.chain().focus().toggleOrderedList().run()}
						isActive={editor.isActive('orderedList')}>
						Liste ordonée
					</DropdownItem>
					<DropdownItem
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						isActive={editor.isActive('bulletList')}>
						Liste non ordonée
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>

			<EditorMenuButton
				onClick={() => editor.chain().focus().toggleBlockquote().run()}
				icon='DuoQuote1'
				isActive={editor.isActive('blockquote')}
				label='Blockquote'
			/>
			<EditorMenuButton
				onClick={() => editor.chain().focus().setHardBreak().run()}
				icon='HeroMinus'
				label='Line break'
			/>
			<EditorMenuButton
				onClick={() => editor.chain().focus().undo().run()}
				icon='DuoUndo'
				isActive={editor.isActive('strike')}
				label='Undo'
				disabled={!editor.can().chain().focus().undo().run()}
			/>
			<EditorMenuButton
				onClick={() => editor.chain().focus().redo().run()}
				icon='DuoRedo'
				isActive={editor.isActive('strike')}
				label='Redo'
				disabled={!editor.can().chain().focus().redo().run()}
			/>
		</>
	);
};

export default EditorMenu;
