import Skeleton, { SkeletonStyleProps } from 'react-loading-skeleton';

type Props = {
	width?: SkeletonStyleProps['width'];
	height?: SkeletonStyleProps['height'];
};

const LineSkeleton = ({ width, height }: Props) => {
	return <Skeleton height={height} width={width} />;
};

LineSkeleton.defaultProps = {
	width: 140,
	height: 20,
};

export default LineSkeleton;
