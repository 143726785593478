import { OrOrderHistory } from '@devschap/order-point-types/types/order';
import { useCallback, useEffect, useState } from 'react';
import Timeline from '../../../components/Timeline';
import Card from '../../../components/ui/Card';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import DeliveryCompanyOrderTimelineItem from './DeliveryCompanyOrderTimelineItem';

type Props = {
	orderId: number;
};

const DeliveryCompanyOrderTimeline = ({ orderId }: Props) => {
	const customToast = useCustomToast();
	const { axiosFetch } = useAxiosFunction();
	const [progression, setProgression] = useState<OrOrderHistory[]>([]);

	const getDcOrdersProgressions = useCallback(async () => {
		try {
			const response = await axiosFetch<{ orderProgression: OrOrderHistory[] }>({
				method: 'GET',
				url: `/api/delivery-company/order/${orderId}/progression`,
			});
			setProgression(response?.orderProgression ?? []);
		} catch (error) {
			customToast.error('Une erreur est survenue. Veuillez rafraichir la page');
		}
	}, [customToast, axiosFetch, orderId]);

	useEffect(() => {
		getDcOrdersProgressions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card header='Progression'>
			{progression.map((item) => (
				<Timeline key={item.addedAt}>
					<DeliveryCompanyOrderTimelineItem item={item} />
				</Timeline>
			))}
		</Card>
	);
};

export default DeliveryCompanyOrderTimeline;
