import { useMemo } from 'react';
import OrderStatusBadge from '../../../components/OrderStatusBadge';

import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import AvatarsGroup from '../../../components/ui/AvatarsGroup';
import Badge from '../../../components/ui/Badge';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../../utils/ui.util';
import useFetchOrders from '../hooks/useFetchOrders';
import AdminOrderAction from './AdminOrderAction';

const cols: DatagridColumnDef[] = [
	{
		field: 'orderId',
		label: 'ID',
	},
	{
		field: 'createdAt',
		label: 'Commande du',
		children: ['code', 'status'],
	},
	{
		field: 'code',
		label: 'Code',
	},
	{
		field: 'products',
		label: 'Produits',
	},
	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const AdminOrdersTable = () => {
	const { loading, orders, loadMore, isLoadingMore } = useFetchOrders();

	const rows = useMemo(() => {
		return orders.map((order) => ({
			orderId: <div className='flex-shrink-0 whitespace-nowrap'>{order.id}</div>,
			createdAt: formatDate(order.createdAt),
			code: order.code,
			products: <AvatarsGroup avatars={order.items} />,
			status: <OrderStatusBadge status={order.status} />,
			actions: <AdminOrderAction orderId={order.id} />,
		}));
	}, [orders]);

	const displayContent = useMemo(() => {
		return rows.length === 0 ? (
			<EmptyContent message='Pas de commande' />
		) : (
			<DataGrid
				rows={rows}
				columns={cols}
				keyPrefix='order'
				loadMore={loadMore}
				isLoadingMore={isLoadingMore}
			/>
		);
	}, [rows, isLoadingMore, loadMore]);

	return (
		<div>
			<Card
				className='h-full'
				header={
					<>
						Commandes
						{orders.length ? (
							<Badge className='ml-2 border-transparent text-sm' variant='outline'>
								{orders ? orders.length : null}
							</Badge>
						) : null}
					</>
				}>
				{loading ? <OpLoader /> : displayContent}
			</Card>
		</div>
	);
};

export default AdminOrdersTable;
