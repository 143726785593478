import { ReactElement, useState } from 'react';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Button, { IButtonProps } from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import { TColors } from '../../../types/colors.type';
import { TIcons } from '../../../types/icons.type';

import AdminGCUSettings from '../components/Settings/AdminGCUSettings';
import AdminPrivacyPolicySettings from '../components/Settings/AdminPrivacyPolicySettings';

type TABKEY = 'GENERAL' | 'STAFF' | 'PRIVACY_POLICY' | 'GCU';

type TTab = {
	text: string;
	icon: TIcons;
	color?: TColors;
	tab: TABKEY;
};
type TTabs = {
	[key in TABKEY]: TTab;
};

const TAB: TTabs = {
	GENERAL: {
		text: 'Géneral',
		icon: 'HeroHome',
		tab: 'GENERAL',
	},
	STAFF: {
		text: 'Staff',
		icon: 'HeroUsers',
		tab: 'STAFF',
	},
	PRIVACY_POLICY: {
		text: 'Politique de conf.',
		icon: 'HeroLockClosed',
		tab: 'PRIVACY_POLICY',
	},
	GCU: {
		text: 'Conditions G.U',
		icon: 'HeroDocumentText',
		tab: 'GCU',
	},
};

const tabComponentsMap: Record<TABKEY, ReactElement | null> = {
	GENERAL: null,
	STAFF: null,
	PRIVACY_POLICY: <AdminPrivacyPolicySettings />,
	GCU: <AdminGCUSettings />,
};

const tabInfoMap: Record<TABKEY, string> = {
	GENERAL: 'Modifiez les informations générales',
	STAFF: 'Gérez les utilisateurs du système',
	PRIVACY_POLICY: 'Politique de confidentialité',
	GCU: "Conditions générales d'utilisation",
};

const defaultProps: IButtonProps = {
	color: 'zinc',
};
const activeProps: IButtonProps = {
	...defaultProps,
	isActive: true,
	color: 'blue',
	colorIntensity: '500',
};

const AdminSettingsPage = () => {
	const [activeTab, setActiveTab] = useState<TTab>(TAB.GENERAL);

	const nav = Object.values(TAB).map((tab) => (
		<div key={tab.text} className='flex-shrink-0'>
			<Button
				className='truncate'
				icon={tab.icon}
				color={
					activeTab.text === tab.text
						? tab.color ?? activeProps.color
						: tab.color ?? defaultProps.color
				}
				isActive={activeTab.text === tab.text}
				onClick={() => {
					setActiveTab(tab);
				}}>
				{tab.text}
			</Button>
		</div>
	));

	return (
		<div className='h-screen overflow-hidden'>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Paramètres' currentPage='Paramètres Admin' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Paramètres'>
				<Subheader>
					<SubheaderLeft>{tabInfoMap[activeTab.tab]}</SubheaderLeft>
				</Subheader>
				<Container className='h-[calc(100vh-146px)]'>
					{/* mobile nav */}
					<div className='flex flex-nowrap overflow-x-scroll nav-active md:hidden'>
						{nav}
					</div>

					<Card className='h-full mt-4' bodyClassName='flex'>
						<div className='hidden md:flex md:flex-col md:flex-nowrap flex-shrink-0 gap-4'>
							{nav}
						</div>
						<div className='px-4 w-full'>
							{activeTab ? tabComponentsMap[activeTab.tab] : null}
						</div>
					</Card>
				</Container>
			</PageWrapper>
		</div>
	);
};

export default AdminSettingsPage;
