// ACCOUNT

export enum AccountRole {
	admin = 'admin',
	staff = 'staff',
	customer = 'customer',
	deliveryCompany = 'deliveryCompany',
	deliveryCompanyStaff = 'deliveryCompanyStaff',
	merchant = 'merchant',
	anonymous = 'anonymous',
}

export enum AccountProvider {
	local = 'local',
	google = 'google',
	apple = 'apple',
}

// ORDERS

export enum OrderStatus {
	processing = 'processing',
	inprogress = 'inprogress',
	cancelled = 'cancelled',
	delivered = 'delivered',
	waitingForPayment = 'waitingForPayment',
	rejected = 'rejected',
	shipped = 'shipped',
}

export enum OpCurrency {
	usd = 'USD',
	eur = 'EUR',
	xof = 'XOF',
}

export enum FeedbackType {
	testimonial = 'testimonial',
	issue = 'issue',
	suggestion = 'suggestion',
}
