import { OrOrderHistory } from '@devschap/order-point-types/types/order';

import { useMemo } from 'react';
import { TimelineItem } from '../../../components/Timeline';
import useGetTimelineIcon from '../../../hooks/useGetTimelineIcon';
import { OrderStatus } from '../../../types/shared-enums';
import { formatDate } from '../../../utils/ui.util';

interface ActionDetails {
	item: OrOrderHistory;
}

const DeliveryCompanyOrderTimelineItem = ({ item }: ActionDetails) => {
	const formatedDate = formatDate(item.addedAt, true);

	const { icon, color } = useGetTimelineIcon(item.action);

	const children = useMemo(() => {
		switch (item.action) {
			case 'create':
				return `${item.user} a crée sa commande le ${formatedDate}`;
			case 'update-product':
				return `${item.user} a traité un produit de cette commande le ${formatedDate}`;

			case 'validate':
				return `${item.user} a validé la commande et envoyé le devis au client le ${formatedDate}`;

			case 'pay':
				return `Le client a effectué le payement de sa commande le ${formatedDate}`;

			case OrderStatus.waitingForPayment:
				return `La commande est en attente de paiement depuis le ${formatedDate}.`;

			case OrderStatus.inprogress:
				return `La commande est en cours.`;

			case OrderStatus.shipped:
				return `Merci d'avoir bien traité et expédiée la commande votre client le ${formatedDate}.`;

			default:
				return undefined;
		}
	}, [item.action, formatedDate, item.user]);

	return (
		<TimelineItem icon={icon} color={color}>
			<span className='text-sm'>{children}</span>
		</TimelineItem>
	);
};

export default DeliveryCompanyOrderTimelineItem;
