import { OrCompleteOrderDetails } from '@devschap/order-point-types/types/order';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';

const useFetchOrder = () => {
	const [order, setOrder] = useState<OrCompleteOrderDetails | null>(null);
	const customToast = useCustomToast();
	const { axiosFetch, loading } = useAxiosFunction();

	const { orderId } = useParams();

	const getOrder = useCallback(async () => {
		if (!orderId || loading) return;

		try {
			const response = await axiosFetch<OrCompleteOrderDetails>({
				method: 'GET',
				url: `/api/admin/orders/${orderId}/details`,
			});
			setOrder(response ?? null);
		} catch (error) {
			customToast.error("Vous n'avez aucune commande");
		}
	}, [orderId, setOrder, customToast, axiosFetch, loading]);

	useEffect(() => {
		getOrder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, order };
};

export default useFetchOrder;
